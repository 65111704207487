import { useEffect, useMemo, useState } from 'react';
import { format, getUnixTime } from 'date-fns';
import { useAlert } from './useAlert';

export const useServerTime = () => {
  const [timestamp, setTimestamp] = useState(null);
  const { setAlert } = useAlert();

  /* server date, 自定義格式 */
  const getToday = (formatStr = 'yyyyMMdd', nowTimestamp = timestamp) => {
    if(!nowTimestamp) return;
    const date = new Date(nowTimestamp);
    return format(date, formatStr);
  };

  /* 今日開始時間 */
  const getTodayStartAt = (nowTimestamp = timestamp) => {
    const year = new Date(nowTimestamp).getUTCFullYear();
    const month = (new Date(nowTimestamp).getUTCMonth() + 1).toString().padStart(2, 0);
    const date = new Date(nowTimestamp).getUTCDate().toString().padStart(2, 0);
    const hour = new Date(nowTimestamp).getUTCHours();
    const todayStartAt = new Date(`${year}/${month}/${date} ${hour}:00:00 +${hour}`).getTime();
    return todayStartAt;
  };

  // 今日日期 2022/05/04
  const today = getToday('yyyy/MM/dd');
  // 今日完整最晚時間 Wed May 04 2022 23:59:59 GMT+0800 (台北標準時間)
  const todayLastTime = new Date(new Date(today).getTime() + 24 * 60 * 60 * 1000 - 1);
  // 今日開始時間 00:00:00
  const startAtTimeStamp = getUnixTime(new Date(today)) * 1000;
  // 今日結束時間 23:59:59
  const endAtTimeStamp = getUnixTime(new Date(todayLastTime)) * 1000; 
  
  /* 所有時間 */
  const allInterval = {
    startAt: 0,
    endAt: 0
  };

  /* 未來時間 */
  const futureInterval = {
    startAt: endAtTimeStamp + 2000,
    endAt: 0
  };

  /* 過去時間 */
  const pastInterval = {
    startAt: 0,
    endAt: (getUnixTime(new Date(today)) - 1) * 1000
  };

  /* 當前時間 */
  const processingInterval = useMemo(() => {
    return {
      startAt: timestamp,
      endAt: timestamp
    };
  }, [timestamp]);

  /* 今日時間 */
  const todayInterval = {
    startAt: startAtTimeStamp,
    endAt: endAtTimeStamp
  };

  /* 取得 server time */
  const fetchServerTime = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_ONEBOARD_API_URL}/oneboard/timestamp`);
      const result = await response.json();
      return result;
    } catch (error) {
      setAlert(error, 'error');
    }
  };

  /* 設定 timestamp */
  const setServerTime = async () => {
    const response = await fetchServerTime();
    const { data, status } = response;
    status === 'success'
      ? setTimestamp(data.timestamp)
      : setAlert('無法取得 server time', 'error');
  };

  useEffect(() => {
    setServerTime();
  }, []);

  return [
    { allInterval, futureInterval, pastInterval, processingInterval, timestamp, todayInterval },
    { fetchServerTime, getToday, getTodayStartAt, setServerTime }
  ];
};