/* eslint-disable max-len */
import React, { useCallback,useMemo } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useParams,useLocation } from 'react-router-dom';
import { Icon } from 'components';
import { useClass } from 'store/class';
import { getPageName } from 'utils/pagePathSlicer';
import {
  UiSideBar,
  UiSiderBarItem,
  UiSideBarSecond,
  UiSideBarSecondBox,
  UiSideBarSecondItem,
  UiSideBarSecondItemIcon,
  UiNew
} from './SideBar.style';


/**
 * 左側欄
 */

export const SideBar = ({ type = 'home',sideBarData=[] }) => {
  const { classId } = useParams();
  const { pathname } = useLocation();
  const targetPage = pathname.split('/').pop();
  const [{ myClasses: { dataInfo: { isOwner, isHidden,navigationFunctions } } }] = useClass();

  const disableLink = e => {
    e.preventDefault();
  };

  const getBasePath = useCallback(() =>'/home'
    , []);

  let data = type === 'home' ?
    [
      {
        isPublic: true,
        link: '/home',
        label: '回上一層',
        icon: 'BackToList',
        disabled: false,
      },
      {
        isPublic: true,
        link: `${getBasePath()}/${classId}/info`,
        label: '簡介',
        icon: 'Introduction',
        code:'brief',
        active:'info',
        disabled: false,
      },
      {
        isPublic: true,
        link: `${getBasePath()}/${classId}/announcement`,
        label: '公告',
        icon: 'Bullhorn',
        code:'announcement',
        active:'announcement',
        disabled: false,
      },
      {
        isPublic: true,
        link: `${getBasePath()}/${classId}/sessions`,
        label: '課程',
        icon: 'BookReader',
        code:'session',
        active:'session',
        disabled: false,
      },
      {
        isPublic: true,
        link: `${getBasePath()}/${classId}/opencourse`,
        label: '會員公開課',
        icon: 'ChildSolid',
        code:'freeCourse',
        active:'opencourse',
        disabled: false,
      },
      {
        isPublic: true,
        link: `${getBasePath()}/${classId}/homeworks`,
        label: '作業',
        icon: 'Homework',
        code:'mission',
        active:'homeworks',
        disabled: false,
      },
      {
        isPublic: true,
        link: '',
        label: '學習資源',
        icon: 'Lightbulb',
        // new: {
        //   startDate: '2021/06/21 00:00:00',
        //   endDate: '2021/7/21 00:00:00',
        // },
        menu: [
          {
            isPublic: true,
            link: `${getBasePath()}/${classId}/exam`,
            label: '線上測驗',
            icon: 'Exam',
            active:'exam',
            code:'oneExam',
            disabled: false,
          },
          {
            isPublic: true,
            link: `${getBasePath()}/${classId}/video`,
            label: '影音學習',
            icon: 'Video',
            active:'video',
            code:'multimediaLearning',
            disabled: false,
          },
          {
            isPublic: true,
            link: `${getBasePath()}/${classId}/supplementary`,
            label: '教學資源',
            icon: 'Shapes',
            active:'supplementary',
            disabled: false,
            code:'supplementaryResource',
            new: {
              startDate: '2021/06/21 00:00:00',
              endDate: '2021/7/21 00:00:00',
            }
          },
        ]
      },
      {
        isPublic: true,
        link: '',
        label: '社群',
        icon: 'Comments',
        menu: [
          {
            isPublic: true,
            link: `${getBasePath()}/${classId}/paste`,
            label: '貼文',
            icon: 'CommentAlt',
            code:'post',
            active:'paste',
            disabled: false,
          },
          {
            isPublic: true,
            link: `${getBasePath()}/${classId}/album`,
            label: '相簿',
            icon: 'Album',
            code:'album',
            active:'album',
            disabled: false,
          },
        ],
      },
      {
        isPublic: true,
        link: isHidden ? '' : `${getBasePath()}/${classId}/secretdiscuss`,
        label: '導師商談室',
        icon: 'Community',
        onClick: e => {
          isHidden &&
            disableLink(e);
        },
        code:'counseling',
        active:'secretdiscuss',
        disabled: isHidden ? true : false,
      },
      {
        isPublic: false,
        link: `${getBasePath()}/${classId}/summary`,
        label: '學習歷程',
        icon: 'Stopwatch',
        active:'summary',
        disabled: false,
      },
      {
        isPublic: true,
        link: `${getBasePath()}/${classId}/member`,
        label: '成員',
        icon: 'Users',
        code:'member',
        active:'member',
        disabled: false,
      },
      {
        isPublic: true,
        link: isOwner ? `${getBasePath()}/${classId}/setting` : '',
        label: '設定',
        icon: 'Cog',
        onClick: e => {
          !isOwner &&
            disableLink(e);
        },
        code:'setting',
        active:'setting',
        disabled: !isOwner ? true : false,
      }
    ] :
    [
      {
        isPublic: true,
        link: '/home/memberCenter/profile',
        label: '基本資料',
        icon: 'AddressCard',
        active:'profile',
        disabled: false,
      },
      {
        isPublic: true,
        link: '/home/memberCenter/changePassword',
        label: '修改密碼',
        icon: 'UserLock',
        active:'changePassword',
        disabled: false,
      },
    ];

    if(sideBarData.length > 0) data = sideBarData;

    const nextData = useMemo(()=>{
      if(!navigationFunctions) return data;
      const settingSet = new Set(navigationFunctions);
      const newData = data.filter(item => !item.code || settingSet.has(item.code));
      const new2ndData = newData.filter( item => {
        // 判斷『二級選單』是否有顯示權限
        if(item.menu){
          item.menu = item.menu.filter(list => !list.code || settingSet.has(list.code));
          if(item.menu.length > 0) return item;
        } else {
          return item;
        }
      });

      return new2ndData;
    },[navigationFunctions]);

  return (
    <UiSideBar>
      {
        data && nextData.filter(item => {
          return isOwner ? item : item.isPublic === true;
        }).map((item, index) => {
          const isActive = item.menu ? item.menu.some(list => list.active === targetPage)  : (targetPage.indexOf(item.active) !== -1);
          const today = Date.parse(new Date().toDateString());
          const startDate = Date.parse(item?.new?.startDate).valueOf();
          const endDate = Date.parse(item?.new?.endDate).valueOf();

          return (
            <UiSiderBarItem key={index} active={ isActive ? true : false} disabled={item.disabled ? true : false}>
              {
                !item.menu && <NavLink
                  to={item.link}
                  onClick={item.onClick}
                  data-ga-category={getPageName(pathname)}
                  data-ga-action={item.label}
                >
                  <Icon.Svg name={item.icon} color={isActive ? '#ec7963' : '#e4e7ec'}/>
                  {item.label}
                </NavLink>
              }
              {
                item.menu && <UiSideBarSecond active={ isActive ? true : false}  to={item.link}>
                  <Icon.Svg name={item.icon} color={isActive ? '#ec7963' : '#e4e7ec'}/>
                  <p>{item.label}</p>
                  {
                    today >= startDate && today < endDate && <UiNew>NEW</UiNew>
                  }
                  <UiSideBarSecondItemIcon>
                    <Icon.Svg name='ChevronRight' color="#e4e7ec"/>
                  </UiSideBarSecondItemIcon>
                  <UiSideBarSecondBox>
                    {
                      item.menu.map((item2, index) => {
                        const isActive2nd = targetPage.indexOf(item2.active) !== -1;
                        const startDate = Date.parse(item2.new?.startDate).valueOf();
                        const endDate = Date.parse(item2.new?.endDate).valueOf();

                        return (
                          <UiSideBarSecondItem 
                            active={ isActive2nd ? true : false} 
                            key={index} 
                            data-ga-category={getPageName(pathname)} 
                            data-ga-action={item2.label}
                          >
                            <NavLink to={item2.link} onClick={item2.onClick}>
                              <Icon.Svg name={item2.icon} color={isActive ? '#ec7963' : '#e4e7ec'}/>
                              <p>{item2.label}</p>
                              {
                                today >= startDate && today < endDate && <UiNew>NEW</UiNew>
                              }
                            </NavLink>
                          </UiSideBarSecondItem>
                        );
                      })
                    }
                  </UiSideBarSecondBox>
                </UiSideBarSecond>
              }
            </UiSiderBarItem>
          );
        })
      }
    </UiSideBar>
  );
};

SideBar.propTypes = {
  type: PropTypes.string,
  sideBarData:PropTypes.array
};
