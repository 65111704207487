import styled from 'styled-components';
import { TextareaAutosize as MatTextareaAutosize, Avatar as MatAvatar } from '@material-ui/core';


export const UiIconTextAreaMain = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: wrap;
`;

export const UiIconTextArea = styled.label`
	position: relative;
	display: flex;
	align-items: center;
	overflow: hidden;
	padding: 0.25rem;
	width: 100%;
	background-color: #f2f3f5;
	border: 1px solid rgba(42, 72, 101, 0.3);
	border-radius: 16px;
	flex: 1;
	letter-spacing: 1px;
`;

export const UiIconTextAreaInput = styled(MatTextareaAutosize)`
	position: relative;
	overflow-x: hidden;
	padding: 4px 12px;
	width: 95%;
	font-size: 1rem;
	white-space: pre-wrap;
	background-color: transparent;
	border: none;
	outline: none;
	resize: none;
`;

export const UiIconTextAreaBox = styled.div`
	position: relative;
	display: flex;
	align-items: center;
`;

export const UiIconFileBox = styled.label`
	position: absolute;
	top: 65%;
	right: 0;
	transform: translateY(-60%);

	& > input {
		display: none;
	}

	& > svg {
		margin: 0 0.5rem;
		font-size: 1.7rem;
		color: #999;
		transition: 0.35s;
		cursor: pointer;

		&:hover {
			color: #ccc;
		}
	}
`;

export const UiFiles = styled.div`
	display: flex;
	justify-content: space-between;
	overflow: hidden;
	margin: 0.5rem 0;
	padding: 0.5rem;
	width: 100%;
	background-color: ${({ theme }) => theme.palette.background.main};
	border-radius: 5px;

	& > svg {
		font-size: 1rem;
		cursor: pointer;
	}
`;

export const UiAvatar = styled(MatAvatar)`
	margin: ${({ avatar }) => avatar ? '0.5rem 0.5rem 0 0 ' : '0'};
	width: 3rem;
	height: 3rem;
`;