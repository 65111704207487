import React, { useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { Table, IconInput, Select, TransferListModal, Button, BsModal, Icon, OutlineButton } from 'components';
import { useSetState } from 'utils/hooks/useSetState';
import { useAlert } from 'utils/hooks/useAlert';
import { makeStyles } from '@material-ui/core/styles';
import {
  UiTable,
  UiActionBox,
  UiflexBox,
  UiTableButtonBox,
} from 'styles';
import { UiButtonWrapper, UiButtonSvg, UiHomeworkLink, UiHiddenInput, UiInviteClassCode, UiInviteClassCodeDate, UiSvg } from './MemberTable.style';
import { useCustomer } from 'store/customer';
import { useUser } from 'store/user';
import { useClass } from 'store/class';
import { ORGANIZATION_SETTINGS_VALUE } from 'constants/index';

/**
 * 學生列表Table
 */


const selectOptions = [
  {
    name: '手機',
    value: 'mobileNumber'
  },
  {
    name: '暱稱',
    value: 'nickname'
  }
];
export const MemberTable = ({
  tabIndex = 0,
}) => {
  const history = useHistory();
  const organizationId = process.env.REACT_APP_LIVE_ORGANIZATION_ID;
  const { classId } = useParams();
  const [
    { customers, subtractionCustomers },
    {
      getCustomers,
      getSubtractionCustomers,
      addUserToGroup,
      removeUserToGroup
    }
  ] = useCustomer();

  // class store
  const [{ myOrganization, myOrganization: { isLoaded: isLoadedMyOrg }, profile: { id: myUserId } }] = useUser();
  const [{ myClasses, isFreeGroup }, { getClassInviteCode }] = useClass();
  const isOwner = myClasses.dataInfo.isOwner;
  const ownerId = myClasses.dataInfo.ownerId;
  const isHidden = myClasses.dataInfo.isHidden;
  const isHost = myClasses.dataInfo.hosts?.includes(myUserId) || false;
  const { groupOwnerInviteCustomerSetting } = myOrganization?.organization || {};

  const [{
    nowPage,
    rowsPage,
    searchType,
    searchValue,
    nickname,
    mobileNumber,
    isLoading,
    isOpen,
    inviteClassCode, // 代碼
    inviteClassDate, // 代碼到期時間
    isInvitationCodeExpired, // 代碼是否過期
    cancelDisplay,
  }, setState] = useSetState({
    nowPage: 0,
    rowsPage: 10,
    nickname: '',
    mobileNumber: '',
    searchType: '',
    searchValue: '',
    isLoading: false,
    isOpen: false,
    inviteClassCode: null,
    inviteClassDate: null,
    isInvitationCodeExpired: null,
    cancelDisplay: true,
  });

  // user store
  const [{ profile }] = useUser();
  const profileId = profile.id;

  const { setAlert } = useAlert();

  const schema = {
    nickname: {
      name: '暱稱',
      defaultValue: '無'
    }
  };

  const schemaAttendeeNumber = {
    attendeeNumber: {
      name: '座號',
      defaultValue: ''
    },
    nickname: {
      name: '暱稱',
      defaultValue: '無'
    }
  };

  const nextSchema = useCallback(() => {
    let sendSchema = {};
    if (Object.keys(customers.sendData).length <= 0) {
      sendSchema = schema;
    } else {
      sendSchema = schemaAttendeeNumber;
    }

    sendSchema = {
      ...sendSchema, homeworkLink: {
        name: '作業總覽',
        defaultValue: '-'
      }
    };

    return sendSchema;
  }, [customers.sendData]);

  const goToStudentSubmittedHomeWorkInfo = id => {
    history.push(`/home/${classId}/homeworks/submitted/${id}
?ownerId=${isHost ? myUserId : ''}`);
  };

  const checkHomeworkUrl = (targetId) => myUserId === targetId || isOwner || isHost;

  const HomeworkUrl = ({ id }) => checkHomeworkUrl(id) ? (
    <UiHomeworkLink onClick={(e) => {
      e.preventDefault();
      goToStudentSubmittedHomeWorkInfo(id);
    }}>
      開啟
    </UiHomeworkLink>
  ) : null;



  const dataFormat = data => {
    return data.map((item, index) => ({
      ...item,
      homeworkLink: <HomeworkUrl id={item.id} />,
    }));
  };

  // table 操作
  const ActionComponents = ({ params }) => {
    return (
      <UiTableButtonBox>
        <Button
          buttonColor='highlight'
          onClick={() => { doRemoveUserToGroup(params); }}
          isLoading={isLoading}
        >
          移出班級
        </Button>
      </UiTableButtonBox >
    );
  };

  const doRemoveUserToGroup = async (params) => {
    const newParams = { userProfiles: { ...params } };
    await removeUserToGroup(newParams);
    reloadUserList();
  };
  const reloadUserList = () => {
    setState({
      nowPage: 0
    });
    getCustomers({ nowPage, rowsPage });
  };

  const getOperationAuth = () => {
    if (!isLoadedMyOrg) return false;
    return isOwner;
  };
  const getGroupOperationAuth = () => {
    return isOwner && groupOwnerInviteCustomerSetting === ORGANIZATION_SETTINGS_VALUE.ALLOW;
  };

  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };
  const onSubmitHandler = (value, key) => {
    if (key === 'searchValue') {
      if (searchType === '') return;
      setState({
        nickname: '',
        mobileNumber: '',
        [searchType]: value,
        nowPage: 0
      });
    }
    setState({
      [key]: value,
    });
  };
  const transferDataFormat = arr => {
    arr.forEach(item => {
      item.imagePath = '';
      item.title = item.nickname;
      item.content = item.email;
    });
    return arr;
  };
  const getTrnasferListValue = async params => {
    const userIdList = params.map(item => item.id);
    const isSuccess = await addUserToGroup({ userIdList, userProfiles: params });
    if (isSuccess) await getCustomers({ nowPage, rowsPage, nickname, mobileNumber });
  };

  useEffect(() => {
    if (!classId || !organizationId || getGroupOperationAuth()) return;
    getSubtractionCustomers({ organizationId, classId });
  }, []);

  useEffect(() => {
    getCustomers({ nowPage, rowsPage, nickname, mobileNumber });
  }, [nowPage, rowsPage, nickname, mobileNumber]);

  useEffect(() => {
    setState({
      inviteClassCode: myClasses.dataInfo && myClasses.dataInfo.invitationCode,
      inviteClassDate: myClasses.dataInfo &&
        myClasses.dataInfo.invitationCodeExpiredAt &&
        format(new Date(myClasses.dataInfo.invitationCodeExpiredAt), 'MM月dd日 HH:mm'),
      isInvitationCodeExpired: myClasses.dataInfo && myClasses.dataInfo.isInvitationCodeExpired
    });
  }, [myClasses]);

  useEffect(() => {
    if(inviteClassCode) {
      setState({
        cancelDisplay: false,
      });
    } else {
      if(profileId === ownerId) {
        setState({
          cancelDisplay: true,
        });
      } else {
        setState({
          cancelDisplay: false,
        });
      }
    }
  },[inviteClassCode]);

  const goInvitePage = () => history.push('member/invite');

  const goSetSeatsPage = () => history.push('member/seats');

  const onModalOk = () => {
    if(inviteClassCode) {
      // 判斷過期
      if(isInvitationCodeExpired) {
        if(profileId === ownerId){
          // 生成代碼
          getInviteClassCodeHandler();
        } else {
          //  關閉視窗
          modalToggle();
        }
      } else {
        //  關閉視窗
        modalToggle();
      }
    } else {

      if(profileId === ownerId){
        // 生成代碼
        getInviteClassCodeHandler();
      } else {
        //  關閉視窗
        modalToggle();
      }
    }
  };

  const modalToggle = () => {
    setState({
      isOpen : !isOpen,
    });
  };

  const onOkTextHandle = () => {
    let text = null;
    if(inviteClassCode) {
      // 判斷過期
      if(isInvitationCodeExpired) {

        if(profileId === ownerId) {
          if(profileId === ownerId){
            text = '產生';
          } else {
            text = '取消';
          }
        }

      } else {
        text = '關閉';
      }
    } else {

      if(profileId === ownerId){
        text = '產生';
      } else {
        text = '關閉';
      }
    }
    return text;
  };

  // 複製代碼
  const codeRef = useRef();
  const onCopyCode = () => {
    const copyText = codeRef.current;
    copyText.select(); // 選擇物件
    document.execCommand('Copy'); // 執行瀏覽器複製命令
    setAlert('複製成功!', 'success');  // 跳成功訊息
  };

  const getInviteClassCodeHandler = async () => {
    // 需要為班導(owner)才可啟動代碼
    if (profileId !== ownerId) return;
    setState({ isLoading: true });
    await getClassInviteCode();
    setState({ isLoading: false, isInvitationCodeExpired: false });
  };

  return (
    <UiTable>
      <UiActionBox>
        <UiflexBox>
          <Select label="搜尋條件" options={selectOptions} submitHandler={value => onSubmitHandler(value, 'searchType')} />
          <IconInput
            placeholder='搜尋條件'
            value={searchValue}
            onChange={value => onSubmitHandler(value, 'searchValue')}
          />
        </UiflexBox>
        {
          !isFreeGroup && getOperationAuth() && (
            <UiButtonWrapper>
              <UiButtonSvg>
                <OutlineButton
                    onClick={modalToggle}
                    buttonColor="normal"
                  >
                    <Icon.Svg color="#EC7963" name="Invite" />
                    邀請代碼
                </OutlineButton>
              </UiButtonSvg>
              {
                tabIndex === 0 &&
                customers.data.length > 0 &&
                <Button
                  onClick={goSetSeatsPage}
                  type="table"
                  icon='edit'
                  buttonColor="new"
                >
                  設定座號
                </Button>
              }
              <TransferListModal
                title="選擇學生"
                name='ownerId'
                buttonName="新增成員"
                type="multipleChoice"
                data={transferDataFormat(subtractionCustomers.data) || []}
                onChange={getTrnasferListValue}
              />
            </UiButtonWrapper>
          ) || !isFreeGroup && <UiButtonSvg>
                <OutlineButton
                  onClick={modalToggle}
                  buttonColor="normal"
                >
                  <Icon.Svg color="#EC7963" name="Invite" />
                  邀請代碼
                </OutlineButton>
              </UiButtonSvg>
        }
        {
          isFreeGroup && getGroupOperationAuth() &&
          <UiButtonWrapper>
            {
              tabIndex === 0 &&
              customers.data.length > 0 &&
              <Button
                onClick={goSetSeatsPage}
                type="table"
                icon='edit'
                buttonColor="highlight"
              >
                設定座號
            </Button>
            }
            <Button
              onClick={goInvitePage}
              type="table"
              icon='add'
              buttonColor="highlight"
            >
              邀請成員
              </Button>

          </UiButtonWrapper>
        }

      </UiActionBox>
      <Table
        data={dataFormat(customers.data)}
        schema={nextSchema()}
        changePage_Rows={changePage_Rows}
        totalPage={customers.total}
        nowPage={nowPage}
        ActionComponents={getGroupOperationAuth() && ActionComponents}
      />
      <BsModal
       open={isOpen}
       title="邀請代碼"
       onOk={onModalOk}
       onCancel={modalToggle}
       okText={onOkTextHandle()}
       cancelDisplay={cancelDisplay}
       isLoading={isLoading}
      >
        {
          inviteClassCode ?
            isInvitationCodeExpired ?
              profileId === ownerId ?
                <div>邀請代碼已過期，是否要產生邀請代碼?</div>
                : <div>邀請代碼已過期，請聯絡班導師！</div>
              : <>
                <UiInviteClassCode>
                  <div>透過邀請代碼可加入班級：</div>
                  <div>{inviteClassCode}</div>
                  <UiSvg onClick={onCopyCode}>
                    <Icon.Svg name="Copy" />
                  </UiSvg>
                </UiInviteClassCode>
                <UiInviteClassCodeDate>班級代碼過期日期：{inviteClassDate}</UiInviteClassCodeDate>
                <UiHiddenInput>
                  <input type="text" ref={codeRef} value={inviteClassCode} />
                </UiHiddenInput>
              </>
            : profileId === ownerId ?
              <div>目前沒有邀請代碼，是否要產生邀請代碼?</div>
              : <div>查無邀請代碼，請聯絡班導師！</div>
        }
      </BsModal>
    </UiTable>);
};


MemberTable.propTypes = {
  tabIndex: PropTypes.number,
};
