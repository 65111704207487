import {
  MyOrganizationsPage,
  HomeSessionPage,
  HomeExamResultPage,
  HomeWorkPage,
  HomeOrganizationAnnouncementPage,
  HomeOrganizationAnnouncementFormPage,
  HomeSessionFormPage,
  HomeworkSubmittedPage,
  HomePastePage,
  HomePostFormPage,
  HomeAnnouncementPage,
  HomeAnnouncementFormPage,
  HomeMemberPage,
  HomeInvitePage,
  HomeInviteOrganizationHostPage,
  HomeSchoolFormPage,
  ClassSummaryPage,
  HomeAlbumPage,
  HomePicturePage,
  HomeMemberProfilePage,
  HomeMemberChangePasswordPage,
  MemberSeatsSelectPage,
  SecretDiscussPage,
  HomeSettingPage,
  HomeInfoPage,
  MySessionsPage,
  // UpgradeAccountPage,
  HomeworkSubmittedStudentPage,
  HomeExamPage,
  HomeExamResultsPage,
  HomeOpenCoursePage,
  HomeStudentHistoryPage,
  HomeSupplementaryMaterialsPage,
  HomeVideoPage,
  HomeVideoHistoryTablePage,
  SessionReportPage,
} from 'components';
import { EDIT_STATUS } from 'constants/index';

const organization = '/home';
const organizationGroup = '/home/:classId';

const routes = [
  {
    path: '/home',
    component: MyOrganizationsPage,
    exact: true
  },
  {
    path: '/sessions',
    component: MySessionsPage,
    exact: true
  },
  // {
  //   path: '/upgradeAccount',
  //   component: UpgradeAccountPage,
  //   exact: true
  // },
  {
    path: '/home/memberCenter/profile',
    component: HomeMemberProfilePage,
    exact: true
  },
  {
    path: '/home/memberCenter/changePassword',
    component: HomeMemberChangePasswordPage,
    exact: true
  },
  {
    path: `/home/school/${EDIT_STATUS.CREATE}`,
    component: HomeSchoolFormPage,
    exact: true,
    editStatus: EDIT_STATUS.CREATE,
  },
  /**
   * Organization
   */
  /**
   * 組織機構的機構頁
   */
  {
    path: `${organization}/announcement`,
    component: HomeOrganizationAnnouncementPage,
    exact: true,
  },
  {
    path: `${organization}/announcement/${EDIT_STATUS.CREATE}`,
    component: HomeOrganizationAnnouncementFormPage,
    exact: true,
    editStatus: EDIT_STATUS.CREATE
  },
  {
    path: `${organization}/announcement/:announcementId/${EDIT_STATUS.EDIT}`,
    component: HomeOrganizationAnnouncementFormPage,
    exact: true,
    editStatus: EDIT_STATUS.EDIT
  },
  {
    path: `${organizationGroup}/sessions`,
    component: HomeSessionPage,
    exact: true,
  },
  {
    path: `${organizationGroup}/sessions/${EDIT_STATUS.CREATE}`,
    component: HomeSessionFormPage,
    exact: true,
    editStatus: EDIT_STATUS.CREATE
  },
  {
    path: `${organizationGroup}/sessions/:sessionId/exam-result`,
    component: HomeExamResultPage,
    exact: true,
  },
  // 課堂報告
  {
    path: `${organizationGroup}/sessions/:sessionId/timespan/:timeSpanId/report`,
    component: SessionReportPage,
    exact: true,
  },
  {
    path: `${organizationGroup}/summary`,
    component: ClassSummaryPage,
    exact: true,
  },
  {
    path: `${organizationGroup}/homeworks`,
    component: HomeWorkPage,
    exact: true
  },
  {
    path: `${organizationGroup}/homeworks/:homeworkId/submitted`,
    component: HomeworkSubmittedPage,
    exact: true
  },
  {
    path: `${organizationGroup}/homeworks/submitted/:studentId`,
    component: HomeworkSubmittedStudentPage,
    exact: true
  },
  {
    path: `${organizationGroup}/paste`,
    component: HomePastePage,
    exact: true
  },
  {
    path: `${organizationGroup}/paste/${EDIT_STATUS.CREATE}`,
    component: HomePostFormPage,
    exact: true,
    editStatus: EDIT_STATUS.CREATE
  },
  {
    path: `${organizationGroup}/announcement`,
    component: HomeAnnouncementPage,
    exact: true
  },
  {
    path: `${organizationGroup}/announcement/${EDIT_STATUS.CREATE}`,
    component: HomeAnnouncementFormPage,
    exact: true,
    editStatus: EDIT_STATUS.CREATE
  },
  {
    path: `${organizationGroup}/announcement/:announcementId/${EDIT_STATUS.EDIT}`,
    component: HomeAnnouncementFormPage,
    exact: true,
    editStatus: EDIT_STATUS.EDIT
  },
  {
    path: `${organizationGroup}/member`,
    component: HomeMemberPage,
    exact: true
  },
  {
    path: `${organizationGroup}/member/seats`,
    component: MemberSeatsSelectPage,
    exact: true
  },
  {
    path: `${organizationGroup}/invite`,
    component: HomeInvitePage,
    exact: true
  },
  {
    path: `${organizationGroup}/member/invite`,
    component: HomeInviteOrganizationHostPage,
    exact: true
  },
  {
    path: `${organizationGroup}/album`,
    component: HomeAlbumPage,
    exact: true
  },
  {
    path: `${organizationGroup}/album/:albumCategory`,
    component: HomePicturePage,
    exact: true
  },
  {
    path: `${organizationGroup}/setting`,
    component: HomeSettingPage,
    exact: true
  },
  {
    path: `${organizationGroup}/exam`,
    component: HomeExamPage,
    exact: true
  },
  {
    path: `${organizationGroup}/exam/:examId/results`,
    component: HomeExamResultsPage,
    exact: true
  },
  {
    path: `${organizationGroup}/video`,
    component: HomeVideoPage,
    exact: true
  },
  {
    path: `${organizationGroup}/video/history/:userId`,
    component: HomeVideoHistoryTablePage,
    expect: true
  },
  {
    path: `${organizationGroup}/video/recordHistory/:teacherId/:videoId`,
    component: HomeStudentHistoryPage,
    expect: true
  },
  {
    path: `${organizationGroup}/info`,
    component: HomeInfoPage,
    exact: true
  },
  {
    path: `${organizationGroup}/supplementary`,
    component: HomeSupplementaryMaterialsPage,
    exact: true
  },
  {
    path: `${organizationGroup}/secretdiscuss`,
    component: SecretDiscussPage,
    exact: true
  },
  // 會員公開課
  {
    path: `${organizationGroup}/opencourse`,
    component: HomeOpenCoursePage,
    exact: true,
  },
];

export default routes;
