import React, { useState , useEffect } from 'react';
import PropTypes from 'prop-types';
import Book from 'assets/images/defaultBook.png';
import { DashBoardCard, Loading } from 'components';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { getMySessions } from 'services/api/home/sessions';
import { useServerTime } from 'utils/hooks/useServerTime';
import { UiSessionGroup } from './SessionGroup.style';


/**
 * 今日課程
 */

export const SessionGroup = ({ getSessionNum }) => {
  const history = useHistory();
  const [{ timestamp: serverTimestamp }, { getToday }] = useServerTime();
  const [ sessionsData, setSessionsData ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);

  const goSessionReader = item => {
    history.push(`/home/${item.groupId}/sessions`);
  };

  const fetchSessions = async () => {
    setIsLoading(true);
    const todayStartAt = new Date(getToday('yyyy/MM/dd')).getTime();
    const todayEndAt = todayStartAt + (24 * 60 * 60 * 1000) - 1;
    const nowTime = serverTimestamp;
    const payload = {
      startAt: todayStartAt,
      endAt: todayEndAt,
      nowPage:0,
      rowsPage: 100
    };
    const { data } = await getMySessions(payload);
    const sessions = data.sessions?.map(item => {
      let isActive = true;
      if (nowTime > item.endAt) isActive = false;
      const startAtText = format(item.startAt, 'HH:mm');
      const endAtText = format(item.endAt, 'HH:mm');
      const sessionInterval = `${startAtText} - ${endAtText}`;
      return {
        ...item,
        isActive,
        sessionInterval
      };
    }) || [];
    getSessionNum(sessions?.length || 0);
    setSessionsData(sessions);
    setIsLoading(false);
  };

  useEffect(() => {
    if(!serverTimestamp) return;
    fetchSessions();
  }, [serverTimestamp]);

  return (
    isLoading
      ? <Loading />
      : <UiSessionGroup>
          {
            sessionsData.map((item, index) => {
              return (
                <DashBoardCard
                  key={index}
                  title={item.name}
                  groupName={item.groupName}
                  hostName={item.hostName}
                  isActive={item.isActive}
                  sessionInterval={item.sessionInterval}
                  defaultImg={Book}
                  onClick={()=> item.isActive && goSessionReader(item)}
                />
              );
            })
          }
        </UiSessionGroup>
  );
};

SessionGroup.propTypes = {
  getSessionNum: PropTypes.func,
};
