import { useSelector, useDispatch } from 'react-redux';
import { DASHBOARD_SUMMARY_CATEGORY } from 'constants/index';
import {
  getDashboardSummary as getDashboardSummaryApi,
  getOrgInfo as getOrgInfoApi,
  updateOrgInfo as updateOrgInfoApi,
  getPublicOrganizations as getPublicOrganizationsApi,
  getOrganizationInvitations as getOrganizationInvitationsApi,
  getOrganizationSubjects as getOrganizationSubjectsApi
} from 'services/api/organization/organizations';
import { useAlert, ALERT_MESSAGE } from 'utils/hooks/useAlert';
import { convertArrayToMap } from 'utils/array';

const GET_DASHBOARD_SUMMARY = 'GET_DASHBOARD_SUMMARY';
const GET_ORGANIZATION_INFO = 'GET_ORGANIZATION_INFO';
const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
const GET_PUBLIC_ORGANIZATIONS = 'GET_PUBLIC_ORGANIZATIONS';
const GET_ORGANIZATION_INVITATIONS = 'GET_ORGANIZATION_INVITATIONS';
const GET_ORGANIZATION_SUBJECT = 'GET_ORGANIZATION_SUBJECT';

const initState = {
  dashboardSummary: {
    [DASHBOARD_SUMMARY_CATEGORY.GROUP]: null,
    [DASHBOARD_SUMMARY_CATEGORY.STAFF]: null,
    [DASHBOARD_SUMMARY_CATEGORY.CUSTOMER]: null,
    [DASHBOARD_SUMMARY_CATEGORY.RESOURCE]: null,
  },
  orgs: {
    dataInfo: null
  },
  publicOrganizations:{
    data: [],
    total: 0,
    dataMap: {}
  },
  invitations: {
    data: [],
    total: 0
  },
  orgSubjects:{
    data:[]
  }
};

const actions = {
  getDashboardSummary: ({ category, data }) => ({
    type: GET_DASHBOARD_SUMMARY,
    payload: { category, data }
  }),
  getOrgInfo: dataInfo => ({
    type: GET_ORGANIZATION_INFO,
    payload: { dataInfo },
  }),
  updateOrganizationInfo: params => ({
    type: UPDATE_ORGANIZATION,
    payload: params,
  }),
  getPublicOrganizations: params => ({
    type: GET_PUBLIC_ORGANIZATIONS,
    payload: params,
  }),
  getOrganizationInvitations: params => ({
    type: GET_ORGANIZATION_INVITATIONS,
    payload: { data: params.data, total: params.total }
  }),
  getOrganizationSubjects:params => ({
    type:GET_ORGANIZATION_SUBJECT,
    payload: { data: params.data }
  })
};

export const useOrganization = () => {
  const dispatch = useDispatch();
  const { setAlert } = useAlert();
  const organizationId = process.env.REACT_APP_LIVE_ORGANIZATION_ID;
  const {
    dashboardSummary,
    orgs,
    publicOrganizations,
    invitations,
    orgSubjects
  } = useSelector(state => state.organization);

  const getDashboardSummary = async category => {
    const { data } = await getDashboardSummaryApi(organizationId)(category);
    dispatch(actions.getDashboardSummary({ category, data }));
    return data;
  };

  const getDashboardClassSummary = () => getDashboardSummary(DASHBOARD_SUMMARY_CATEGORY.GROUP);
  const getDashboardTeacherSummary = () => getDashboardSummary(DASHBOARD_SUMMARY_CATEGORY.STAFF);
  const getDashboardStudentSummary = () => getDashboardSummary(DASHBOARD_SUMMARY_CATEGORY.CUSTOMER);
  const getDashboardResourceSummary = () => getDashboardSummary(DASHBOARD_SUMMARY_CATEGORY.RESOURCE);

  const getOrgInfo = async () => {
    const { data, isSuccess } = await getOrgInfoApi(organizationId);
    if (!isSuccess) return;
    dispatch(actions.getOrgInfo(data));
  };

  const updateOrganizationInfo = async (id, params) => {
    const { isSuccess, error } = await updateOrgInfoApi(id, params);

    try {
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
    }

    dispatch(actions.updateOrganizationInfo(params));
    return isSuccess;
  };

  const getPublicOrganizations = async params => {
    const { isSuccess, data } = await getPublicOrganizationsApi(params);
    if (!isSuccess) return;
    const { organizations, total } = data;
    const dataMap = convertArrayToMap(organizations, 'id');
    dispatch(actions.getPublicOrganizations({ data: organizations, total, dataMap }));
  };

  const getOrganizationInvitations = async params => {
    const { isSuccess, data } = await getOrganizationInvitationsApi(organizationId)(params);
    if (!isSuccess) return;
    const { invitations, total } = data;
    dispatch(actions.getOrganizationInvitations({ data: invitations, total }));
  };

  const getOrganizationSubjects = async (orgId,classId) => {
    const {isSuccess ,data} = await getOrganizationSubjectsApi(orgId,classId)();
    if(!isSuccess) return;
    dispatch(actions.getOrganizationSubjects({ data }));
  };

  return [
    { dashboardSummary, orgs, publicOrganizations, invitations,orgSubjects }, // state
    {
      getDashboardClassSummary,
      getDashboardTeacherSummary,
      getDashboardStudentSummary,
      getDashboardResourceSummary,
      getOrgInfo,
      updateOrganizationInfo,
      getPublicOrganizations,
      getOrganizationInvitations,
      getOrganizationSubjects,
    }, // eventHanlder
  ];
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_SUMMARY: {
      const { category, data } = action.payload;
      return {
        ...state,
        dashboardSummary: {
          ...state.dashboardSummary,
          [category]: data
        }
      };
    }
    case GET_ORGANIZATION_INFO: {
      const { dataInfo } = action.payload;
      return {
        ...state,
        orgs: { ...state.orgs, dataInfo }
      };
    }
    case GET_PUBLIC_ORGANIZATIONS : {
      const { data, total, dataMap } = action.payload;
      return {
        ...state,
        publicOrganizations: { data, total, dataMap }
      };
    }
    case GET_ORGANIZATION_INVITATIONS: {
      const { data, total } = action.payload;
      return {
        ...state,
        invitations: {
          data,
          total
        }
      };
    }
    case GET_ORGANIZATION_SUBJECT: {
      const { data } = action.payload;
      const nextData = data ? data.map(item=>{
        return {
          name:item.name,
          value:item.code
        };
      }):[];
      return {
        ...state,
        orgSubjects: {
          data:nextData,
        }
      };
    }
    default:
      return state;
  }
};

export default reducer;
