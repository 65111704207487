import styled from 'styled-components';

export const UiSessionPerformance = styled.div`
    margin-bottom: 64px;
`;

export const UiTitleWrapper = styled.div`
    color: #242C3F;
    font-family: Noto Sans CJK TC;
	font-size: 1.6rem;
	font-weight: 400;
    margin-bottom: 18px;
`;

export const UiTableWrapper = styled.div`
    display: flex;
    overflow-x: auto;
    
    ::-webkit-scrollbar{ height: 6px };
    ::-webkit-scrollbar-thumb{
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.3);
    }
`;

/* 以下為學生身份用 */
export const UiPerformanceForUser = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	> div {
		margin: 0 10px;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}
`;

export const UiPerformanceCard = styled.div`
	padding: 1rem 0.75rem;
	width: 25%;
	background-color: #fafafc;
	border-radius: 15px;
`;

export const UiPerformanceCardTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 17px;
	color: #4e5d78;
`;

export const UiPerformanceCardIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	background-color: #000;
	border-radius: 50%;

	&.trophy {
		background-color: #ffab00;
	}

	&.running {
		background-color: #ff5630;
	}

	&.raisehand {
		background-color: #377dff;
	}

	&.messageWaveform {
		background-color: #38cb89;
	}
`;

export const UiPerformanceCardContent = styled.div`
	font-size: 40px;
	font-weight: 700;
`;

export const UiPerformanceCardAverage = styled.div`
	color: #8b90a0;
`;