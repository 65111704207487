import React, { useState } from 'react';
import PropTypes from 'prop-types';
import theme from 'theme';
import {
  UiStack,
  UiToolbar,
  UiMuiToggleButton,
  UiMuiToggleButtonGroup,
  UiMuiTypography,
} from './Toolbar.style';
import { Button, Icon } from 'components';

const btnTodayText = '今天';

export const Toolbar = ({
  onChangeView,
  onClickNext,
  onClickPrev,
  onClickToday,
  title,
  viewOptions,
}) => {
  const [view, setView] = useState(viewOptions[0].value);

  /**
   * change 行事曆顯示方式
   * @param {object} event 事件處理物件
   * @param {string} newView 選到的按鈕的 value
   */
  const changeView = (event, newView) => {
    if (!newView) return;
    onChangeView(newView);
    setView(newView);
  };

  return (
    <UiToolbar>
      <UiStack>
        <UiMuiToggleButtonGroup exclusive value={view} onChange={changeView}>
          {viewOptions.map(({ name, title, value }, index) =>
            <UiMuiToggleButton key={index} value={value}>
              <Icon haveBg={false} name={name} size='24px' title={title} />
            </UiMuiToggleButton>
          )}
        </UiMuiToggleButtonGroup>
      </UiStack>

      <UiStack>
        <Icon
          icolor='#637381'
          haveBg={false}
          name='chevronLeft'
          size='30px'
          onClick={onClickPrev}
        />
        <UiMuiTypography>{title}</UiMuiTypography>
        <Icon
          icolor='#637381'
          haveBg={false}
          name='chevronRight'
          size='30px'
          onClick={onClickNext}
        />
      </UiStack>

      <UiStack>
        <Button
          btnSize='0.94rem'
          buttonColor='standardSecondary'
          buttonPadding='4px 12px'
          minWidth='0'
          textColor={theme.palette.main.new}
          onClick={onClickToday}
        >
          {btnTodayText}
        </Button>
      </UiStack>
    </UiToolbar>
  );
};

Toolbar.propTypes = {
  onChangeView: PropTypes.func,
  onClickNext: PropTypes.func,
  onClickPrev: PropTypes.func,
  onClickToday: PropTypes.func,
  title: PropTypes.string,
  viewOptions: PropTypes.array,
};
