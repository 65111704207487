import React from 'react';
import PropTypes from 'prop-types';
import { Header, Footer, SideBar, HomeBanner } from 'components';
import {
  UiHomeOrganizationLayout,
  UiHomeGroupContent,
  UiChildrenContainer
 } from './HomeOrganizationLayout.style';


/**
 * 非獨立班級組織頁
 */

export const HomeOrganizationLayout = ({children,showSideBar=true}) => {
  const organizationId = process.env.REACT_APP_LIVE_ORGANIZATION_ID;

  const data =
    [
      {
        isPublic: true,
        link: '/home',
        label: '返回列表',
        icon: 'exitToApp',
      },
      {
        isPublic: true,
        link: '/home/announcement',
        label: '機構公告',
        icon: 'exitToApp',
      }
    ];

  return (<UiHomeOrganizationLayout>
    <Header />
    <HomeBanner showSideBar={showSideBar} isGroupsOrganization={true} />
    <UiHomeGroupContent showSideBar>
        <SideBar sideBarData={data} />
        <UiChildrenContainer>{children}</UiChildrenContainer>
      </UiHomeGroupContent>
    <Footer />
  </UiHomeOrganizationLayout>);
};

HomeOrganizationLayout.propTypes = {
  children: PropTypes.node,
};


