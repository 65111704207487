import React from 'react';
import { HomeGroupLayout, HomeOpenCourse } from 'components';

/**
 * 會員公開課 頁面
 */

export const HomeOpenCoursePage = () => {
  return (
    <HomeGroupLayout>
      <HomeOpenCourse />
    </HomeGroupLayout>
  );
};
