import styled from 'styled-components';

export const UiHomeworkList = styled.div`
	display: block;
	width: 100%;

	h4 {
		margin-right: auto;
		font-size: 20px;
		font-weight: 100;
		color: #242c3f;
		line-height: 30px;
	}
`;
export const UiTitle = styled.div`
	margin: 1rem auto 1.5rem;
	min-height: 3.6rem;
	font-size: 2.5rem;
	font-weight: bold;
	text-align: center;
	color: #004b62;
`;
export const UiButtonBox = styled.div`
	display: flex;
	justify-content: flex-end;
	margin: 0.5rem 0;
`;

export const UiCardBox = styled.div`
	margin-bottom: 1.5rem;
	word-break: break-all;
	word-wrap: break-word;
`;
export const UifilterRow = styled.div`
	display: flex;
	justify-content: space-between;
`;
export const UifilterBox = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 1rem 0;

	> div > div {
		width: 160px;
	}

	& + div {
		margin-left: 8px;
	}
`;

export const UiEmptyBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 4rem auto 0;
	width: 40%;
	min-height: 160px;
	font-size: 2rem;
	color: red;
	border-radius: 20px;
	box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.3);
`;
