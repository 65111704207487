import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Loading } from 'components';
import { stringify } from 'query-string';
import { useParams } from 'react-router-dom';
import { useUser } from 'store/user';
import { useAlert } from 'utils/hooks/useAlert';
import { useServerTime } from 'utils/hooks/useServerTime';
import { Modal as MuiModal } from '@material-ui/core';
import {
  UiBody,
  UiBodyCol,
  UiBodyRow,
  UiCategory,
  UiCategoryColor,
  UiCategoryName,
  UiClose,
  UiDetail,
  UiHeader,
  UiModalContent,
  UiSessionModal,
  UiTitle,
  UiFooter,
} from './SessionModal.style';

const gradeTitle = '年級';
const subjectTitle = '科目';
const durationTitle = '時間';

const btn = {
  notStart: {
    text: '尚未開放',
    color: 'disable',
    disabled: true,
  },
  open: {
    text: '進入教室',
    color: 'normal',
    disabled: false,
  },
  close: {
    text: '已結束',
    color: 'disable',
    disabled: true,
  }
};


const categoryColors = { 
  selfStudy: '#EC7963',
  problemSolving: '#FACD61'
};

export const SessionModal = (props) => {
  const { isOpen, onClose, session } = props;
  const [btnCode, setBtnCode] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [{ profile: { id: userId, nickname } }] = useUser();
  const [, { fetchServerTime }] = useServerTime();
  const { setAlert } = useAlert();
  const { classId } = useParams();
  const { classification, courseId, durationText, eduSubjectsText, endAt, gradesText, startAt, title } = session;
  const body = [
    { title: gradeTitle, text: gradesText },
    { title: subjectTitle, text: eduSubjectsText },
    { title: durationTitle, text: durationText },
  ];
  const querystring = stringify({
    classType: 'sync-multiple',
    role: 'student',
    userId: `${userId}${classId.substring(0, 8)}`,
    userName: nickname,
  }, { arrayFormat: 'index' });
  const classroomUrl = `${process.env.REACT_APP_ONEBOARD_DOMAIN}/${courseId}/setup?${querystring}`;
  const categoryCode =  classification === '自習教室'
                          ? 'selfStudy'
                          : classification === '解題教室' && 'problemSolving';
  const categoryBg = categoryColors[categoryCode];

  /**
   * 打 API，取得 server time
   */
  const fetchTimestamp = async () => {
    setIsLoading(true);
    const response = await fetchServerTime();
    const { status } = response;
    if (status === 'success') {
      const { data } = response;
      const { timestamp: nowTimestamp } = data;
      const isNotStart = nowTimestamp < startAt;
      const isOpen = nowTimestamp >= startAt && nowTimestamp <= endAt;
      const isClose = nowTimestamp > endAt;
      if (isNotStart) setBtnCode('notStart'); // 尚未開放
      else if (isOpen) setBtnCode('open');    // 進入教室
      else if (isClose) setBtnCode('close');  // 已結束
    } else {
      setAlert('無法取得 server time', 'error');
      onClose();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTimestamp();
  }, [session]);

  return(
    <UiSessionModal>
      <MuiModal
        open={isOpen}
        onClose={onClose}
        disableAutoFocus
        disableEnforceFocus
      >
        <UiModalContent>
          {
            isLoading
              ? <Loading />
              : <>
                  <UiClose>
                    <Icon
                      haveBg={false}
                      icolor='#A1A4B1'
                      name='close'
                      size='1.6rem'
                      onClick={onClose}
                    />
                  </UiClose>

                  <UiDetail>
                    <UiHeader>
                      <UiTitle>{title}</UiTitle>
                      <UiCategory>
                        <UiCategoryColor background={categoryBg} />
                        <UiCategoryName>{classification}</UiCategoryName>
                      </UiCategory>
                    </UiHeader>

                    <UiBody>
                      {
                        body.map(({title, text}, index) => 
                          <UiBodyRow key={index}>
                            <UiBodyCol fontWeight='500' whiteSpace='nowrap'>{title}</UiBodyCol>
                            <UiBodyCol>{text}</UiBodyCol>
                          </UiBodyRow>
                        )
                      }
                    </UiBody>
                  </UiDetail>

                  <UiFooter>
                    <Button
                      borderRadius='16px'
                      btnSize='16px'
                      buttonColor={btn[btnCode]?.color}
                      buttonPadding='16px'
                      disabled={btn[btnCode]?.disabled}
                      isFullWidth
                      onClick={()=>window.open(classroomUrl, '_blank')}
                    >{btn[btnCode]?.text}</Button>
                  </UiFooter>
                </>
          }
        </UiModalContent>
      </MuiModal>
    </UiSessionModal>
  );
};

SessionModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  session: PropTypes.object,
};
