import styled, { css } from 'styled-components';
import emptyPottedPlant from 'assets/images/empty_potted_plant.png';

export const UiEmptyImgContainer = styled.figure`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0;
`;

export const UiEmptyImg = styled.img.attrs({
	src: emptyPottedPlant,
})`
	margin-bottom: 24px;
`;

export const UiEmptyImgFigcaption = styled.figcaption`
	font-size: 0.934rem;
`;

export const UiTag = styled.div`
	background: #FEF2F0;
	border-radius: 4px;
	color: #EC7963;
	display: inline-block;
	font-size: 0.9rem;
	padding: 4px 8px;
`;

export const UiSessionsWrapper = styled.div`
	display: grid;

	${({ isEmpty }) =>
		isEmpty
			? css`
					align-items: center;
					justify-content: center;
				`
			: css`
					grid-template-columns: repeat(auto-fit, 320px);
					column-gap: 20px;
					row-gap: 28px;
				`
	};
`;

export const UiClassroomWrapper = styled.div`
	background: ${({ background }) => background };
	min-height: 354px;
	padding: 46px 0 40px 40px;
`;

export const UiClassroomTitleWrapper = styled.div`
	margin-bottom: 16px;
`;

export const UiClassroomTitle = styled.span`
	color: #3A4052;
	font-size: 1.34rem;
	margin-right: 10px;
`;

