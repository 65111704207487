import React, { useMemo, } from 'react';
import PropTypes from 'prop-types';

import { Table, } from 'components';
import { format } from 'date-fns';
import { useClass } from 'store/class';
import { Tooltip as MatTooltip } from '@material-ui/core';
import { 
  UiSessionRecordWrapper,
  UiTitleWrapper,
  UiTableWrapper,
  UiTimeLineContainer,
  UiTimeLineRow,
  UiTimeLineBlock,
  UiTimeLineRail,
  UiTimeLineTrack,
  UiTimeText,
  UiRecordUser,
  UiRecordUserIcon,
  UiRecordUserText,
} from './SessionRecord.style';

/**
 * 上課紀錄總覽
 */

/* 假資料 */
// const data = [{ 
//   role: '授課老師', 
//   attendeeNumber: '-',
//   nickname: '貝多芬',
//   totalSessionPeriod: '1000分',
//   joinedSessionAt: '00:00',
//   leftSessionAt: '24:00',
//   attendanceCount: '1000次'
// }];

const schema = {
  role: {
    name: '身份',
    defaultValue: '',
  },
  attendeeNumber: {
    name: '座號',
    defaultValue: '',
  },
  nickname: {
    name: '姓名',
    defaultValue: '',
  },
  totalSessionPeriod: {
    name: '上課時長',
    defaultValue: '',
  },
  joinedSessionAt: {
    name: '進入教室',
    defaultValue: '',
  },
  leftSessionAt: {
    name: '離開教室',
    defaultValue: '',
  },
  attendanceCount: {
    name: '進出教室',
    defaultValue: '',
  },
};

export const SessionRecord = (props) => {
  const [{ myClasses: { dataInfo: { userType } } }] = useClass();
  const isTeacher = userType === 'teacher' ? true : false;
  const { 
    endAt,
    hostNickname,
    hostRecord,
    startAt,
    userRecords,
    users,
  } = props?.data ?? {};

  /* 教師紀錄 - 表格 */
  const teacherRecordTable = () => {
    const {
      attendances,
      joinedSessionAt,
      leftSessionAt,
      status,
      totalSessionPeriod,
    } = hostRecord;

    const obj = status === 'missed'
      ? {
          totalSessionPeriod: <span style={{color: '#F44336'}}>缺席</span>,
          joinedSessionAt: '-',
          leftSessionAt: '-',
          attendanceCount: '-',
        }
      : {
          totalSessionPeriod: `${Math.floor(totalSessionPeriod/60)}分`,              // 上課時長
          joinedSessionAt: joinedSessionAt ? format(joinedSessionAt, 'HH:mm') : '-', // 進入教室
          leftSessionAt: leftSessionAt ? format(leftSessionAt, 'HH:mm') : '-',       // 離開教室
          attendanceCount: attendances ? `${attendances.length}次` : '-',             // 進出教室
        };

    const records = {
      role: '授課老師', 
      attendeeNumber: '-',
      nickname: hostNickname,
      ...obj,
    };

    return records;
  };

  /* 學生紀錄 - 表格 */
  const studentRecordTable = () => {
    const zip = users.map(user => {
      // 學生身份之資料處理用
      let record = [];
      userRecords.forEach(userRecord => {
        if(user.id === userRecord.userId){
          record = userRecord;
        }
      });
      return {...user, ...record};
    });

    const records = zip.map(value => {
      const {
        attendances,
        attendeeNumber,
        joinedSessionAt,
        leftSessionAt,
        nickname,
        status,
        totalSessionPeriod,
      } = value;

      const obj = status === 'missed'
        ? {
            totalSessionPeriod: <span style={{color: '#F44336'}}>缺席</span>,
            joinedSessionAt: '-',
            leftSessionAt: '-',
            attendanceCount: '-',
          }
        : {
            totalSessionPeriod: `${Math.floor(totalSessionPeriod/60)}分`,               // 上課時長
            joinedSessionAt: joinedSessionAt ? format(joinedSessionAt, 'HH:mm') : '-',  // 進入教室
            leftSessionAt: leftSessionAt ? format(leftSessionAt, 'HH:mm') : '-',        // 離開教室
            attendanceCount: attendances ? `${attendances.length}次` : '-'              // 進出教室
          };

      return { 
        role: '學生', 
        attendeeNumber,
        nickname,
        ...obj,
      };
    });

    return records;
  };

  /* 教師紀錄 - 圖表 */
  const teacherRecordTimeLine = () => {
    const { attendances, leftSessionAt, } = hostRecord;

    // const records = {
    //   attendances: {
    //     [hostNickname]: attendances,
    //   }
    // };

    attendances.leftSessionAt = leftSessionAt;   // 加入最後離開時間
    const records = [ attendances ];
    return records;
  };

  /* 學生紀錄 - 圖表 */
  const studentRecordTimeLine = () => {
    // const zip = users.map((user, index) => {
    //   return {
    //     attendances: {
    //       [user.nickname]: userRecords[index].attendances,
    //     }
    //   };
    // });

    // const records = zip.reduce((prev, curr) => {
    //   return {
    //     attendances: {
    //       ...prev.attendances,
    //       ...curr.attendances,
    //     }
    //   };
    // }, {});

    const records = userRecords.map((userRecord) => {
      const attendances = userRecord?.attendances;
      const leftSessionAt = userRecord?.leftSessionAt; // 最後離開時間

      attendances.leftSessionAt = leftSessionAt;
      return attendances;
    });

    return records;
  };

  /* 計算時間於UI上之寬度 */
  const formatTime = (targetTime) => {
    const shortBlockLength = isTeacher ? 100 : 250;
    const largeBlockLength = isTeacher ? 216 : 724;
    const totalLength = isTeacher ? 416 : 1042;
     //上課時間
     const sessionTime = endAt - startAt;
     //預習時間 20分鐘
     const previewTime = 20 * 60 * 1000;
     //預習實際時間
     const previewActualTime = startAt - previewTime;
     //加課時間 90分鐘
     const extendTime = 90 * 60 * 1000;
     //加課實際時間
     const extendActualTime = endAt + extendTime;


     if(previewActualTime > targetTime) {
       //進入/離開時間比預習還早
       return '0';
     }else if(targetTime >= previewActualTime && targetTime <= startAt){
       //進入/離開的時間在預習時間內
       return (targetTime - previewActualTime) / previewTime * shortBlockLength;
     }else if(targetTime > startAt && targetTime <= endAt){
       //進入/離開的時間在上課時間
       return ((targetTime - startAt) / sessionTime) * largeBlockLength + shortBlockLength;
     }else if( targetTime >= endAt && targetTime < extendActualTime){
       //進入/離開的時間在加課時間
       return ((targetTime - endAt) / extendTime) * shortBlockLength + shortBlockLength + largeBlockLength;
     }else if(targetTime >= extendActualTime){
       return totalLength;
     }
  };

  /* 進入教室之開始時間、結束時間 */
  const timeLineFormat = (enterTime,leftTime) => {
    const startPosition = formatTime(enterTime);
    const endPosition = formatTime(leftTime);
    return {
      startPosition,
      endPosition
    };
  };

  /* 表格資料 */
  const tableData = useMemo(() => {
    return [teacherRecordTable(), ...studentRecordTable()];
  }, [hostRecord, userRecords]);

  /* 圖表資料 */
  const timeLineData = useMemo(() => {
    // const records = {...teacherRecordTimeLine(), ...studentRecordTimeLine()};
    return isTeacher
      ? [...teacherRecordTimeLine(), ...studentRecordTimeLine()]
      : [...studentRecordTimeLine()];
  }, [hostRecord, userRecords]);

  
  return (
    <UiSessionRecordWrapper>
      <UiTitleWrapper>
        {
          isTeacher
            ? '上課紀錄總覽'
            : '上課紀錄'
        }
      </UiTitleWrapper>

      {/* 進出教室狀況表格、圖表 */}
      <UiTableWrapper>
          {/* 表格 */}
          {
            isTeacher &&
              <Table
              data={tableData}
              schema={schema}
              isNeedPagination={false}
              whiteSpace='nowrap' />
          }

          {/* 圖表 */}
          <UiTimeLineContainer>
            {/* 表頭 */}
            <UiTimeLineRow className={!isTeacher && 'userHead'}>
              <UiTimeLineBlock
                bgc={isTeacher ? '#E4E7EC' : '#5F73A9'}
                className={!isTeacher ? 'userHead head' : 'head'}>預習課程</UiTimeLineBlock>
              <UiTimeLineBlock 
                bgc={isTeacher ? '#E4E7EC' : '#394F8A'}
                className={!isTeacher ? 'userHead head' : 'head'}>
                <UiTimeText>{format(startAt, 'HH:mm')}</UiTimeText>
                上課中
                <UiTimeText>{format(endAt, 'HH:mm')}</UiTimeText>
              </UiTimeLineBlock>
              <UiTimeLineBlock 
                bgc={isTeacher ? '#E4E7EC' : '#5F73A9'}
                className={!isTeacher ? 'userHead head' : 'head'}>加課時間</UiTimeLineBlock>
            </UiTimeLineRow>

            {/* 內容 */}
            {
              timeLineData.map((timeLineRow, i) => {
                return (
                  <>
                    <UiTimeLineRow className={(!isTeacher && timeLineData.length - 1 === i) && 'userBody'}>
                      <UiTimeLineBlock className={!isTeacher ? 'userBody body' : 'body'}>&nbsp;</UiTimeLineBlock>
                      <UiTimeLineBlock bgc='#F2F4F8' className={!isTeacher ? 'userBody body' : 'body'}/>
                      <UiTimeLineBlock className={!isTeacher ? 'userBody' : 'body'}>&nbsp;</UiTimeLineBlock>
                      <UiTimeLineRail>
                      {          
                        (!Array.isArray(timeLineRow[0]) && timeLineRow.length > 0) &&
                          timeLineRow.map((timeLine, j) => {
                            const { joinedAt, leftAt } = timeLine;
                            const { startPosition, endPosition } = timeLineFormat(joinedAt, leftAt);
                            const joinText = format(joinedAt, 'HH:mm'); // 進入時間
                            const leftText = format(leftAt, 'HH:mm');   // 離開時間
                            const leftSessionAt = timeLineRow.leftSessionAt;  // 最後離開時間
                            let title = null;

                            // 判斷是否上課中
                            if(j === timeLineRow.length - 1){
                              leftSessionAt
                                ? title = `進入時間: ${joinText}, 離開時間: ${leftText}`
                                : title = `進入時間: ${joinText}, 上課中`;
                            }else{
                              title = `進入時間: ${joinText}, 離開時間: ${leftText}`;
                            }
                            
                            return (
                              <MatTooltip key={j} title={title}>
                                <UiTimeLineTrack left={startPosition} right={endPosition} />
                              </MatTooltip>
                            );
                          })
                      }
                      </UiTimeLineRail>
                    </UiTimeLineRow>
                  </>
                );
              })
            }
          </UiTimeLineContainer>
      </UiTableWrapper>

      {/* 表格顏色參考 */}
      {
        !isTeacher &&
          <UiRecordUser>
            <UiRecordUserIcon />
            <UiRecordUserText>學生上課時間</UiRecordUserText>
          </UiRecordUser>
      }
    </UiSessionRecordWrapper>
  );
};

SessionRecord.propTypes = {
  data: PropTypes.object,
};
