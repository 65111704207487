import styled from 'styled-components';

/**
 *@Table的外框
 */
export const UiTable = styled.div`
	display: block;
	padding: ${({ theme }) => theme.table.padding};
	width: 100%;
	height: 100%;

	> h4 {
		margin-top: 0;
		font-size: 20px;
		color: #242c3f;
		line-height: 30px;
	}
`;

/**
 @Table搜尋列表
 */
export const UiActionBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
`;

/**
 *@Flexbox全部置中
 */
export const UiflexBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	& > button + div,
	& > button + button,
	& > div + div {
		margin-left: 10px;
	}

	& > svg {
		margin: 0 3px;
	}
`;

export const UiTableTitle = styled.div`
	margin: 20px auto 40px;
	font-size: 2.5rem;
	font-weight: 700;
	text-align: center;
	color: ${({ theme }) => theme.palette.primary.main};
`;

export const UiTableButtonBox = styled.div`
	display: flex;
	justify-content: space-around;
	margin: auto;
	max-width: 350px;
`;

export const UiTitle = styled.div`
	margin: 1rem auto 1.5rem;
	min-height: 3.6rem;
	font-size: 2.5rem;
	font-weight: bold;
	text-align: center;
	color: #004b62;
`;


export const UiBsContentContainer = styled.div`
${({ theme }) => `
    flex: 1;
    padding: ${theme.spacing(6, 4)};
    background: '#eaeff1';
  `}
`;

export const UiGridContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: ${({ align }) => align ? align : 'flex-start'};
	justify-content: ${({ justify }) => justify ? justify : 'flex-start'};
	margin: 0 -1rem;
`;

export const UiEmphasize = styled.span`
		font-weight: 700;
		color: #ff5151;
`;

export const UiTableInfo = styled.div`
	padding: 1rem;
	color: ${({ theme }) => theme.palette.main.info};
`;



export const UiGridItem = styled.div`
	padding: 0 1rem;
	${
	({ theme: { media }, desktop }) => media.desktop`
	width:${desktop}%
	`}
	${
	({ theme: { media }, notebook }) => media.notebook`
	width:${notebook}%
	`}
	${({ theme: { media }, pad }) => media.pad`
	width:${pad}%
	`}
	${({ theme: { media }, phone }) => media.phone`
	width:${phone}%
	`}

	> div > div:first-child > div:first-child {
		background: #4c99a5;
		background-color: #4c99a5;
	}
`;


