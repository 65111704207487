import React from 'react';
import { UiCustomerLinkPage } from './CustomerLinkPage.style';
import { CustomerLink,Header } from 'components';


/**
 * 非組織成員頁面
 */

export const CustomerLinkPage = () => {
  return (
    <UiCustomerLinkPage>
      <Header/>
      <CustomerLink/>
    </UiCustomerLinkPage>
  );
};
