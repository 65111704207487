import styled from 'styled-components';

export const UiHeader = styled.div`
	width: 100%;
	min-width: 0;
	background-color: #fff;
	box-shadow: inset 0 -1px 0 #f0f0f0;

	> .container {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin: auto;
		padding: ${({isNeedLogo})=> isNeedLogo ? '14px 0 6px' : '14px 32px 6px'};
		width: 100%;
		max-width: ${({isNeedLogo})=> isNeedLogo ? '95%' : '100%'};

		/* ${({ theme: { media } }) => media.desktop`
      width: ${({ theme }) => theme.mediaWidth.desktop}
    `} */
		${({ theme: { media } }) => media.notebook`
      width: ${({ theme }) => theme.mediaWidth.notebook}
		`}
		${({ theme: { media } }) => media.pad`
			width: ${({ theme }) => theme.mediaWidth.pad}
		`}
		${({ theme: { media } }) => media.phone`
			width: ${({ theme }) => theme.mediaWidth.phone}
		`}

		> .logo {
			flex: 1;
			display: flex;
			align-items: center;

			> a {
				font-size: 0;
			}

			nav {
				margin-left: 50px;
			}

			/* > .tag {
				margin-left: 50px;
				padding: 4px 12px;
				font-size: 14px;
				color: #242c3f;
				background: #f5f5f5;
				border-radius: 4px;
				box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
				line-height: 24px;

				&.black {
					color: #fff;
					background: #121232;
				}

				& + .tag {
					margin-left: 8px;
				}
			} */
		}
	}

	.breadcrumbItem {
		display: flex;
		align-items: center;

		> svg {
			margin-right: 10px;
		}
	}
`;

export const UiHeaderText = styled.div`
	display: flex;
	align-items: center;
	color: #454b5c;

	& svg {
		margin-right: 10px;
	}
`;

export const UiClassName = styled.div`
	font-weight: 700;
`;

export const UiNotice = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	font-size: 2rem;
	border-radius: 50%;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	cursor: pointer;

	> svg {
		font-size: 1.75rem;

		path {
			fill: #8b90a0;
		}
	}
`;
export const UiNoticeNew = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	width: 12px;
	height: 12px;
	background-color: #ec7963;
	border-radius: 50%;
`;

export const UiAnnouncement = styled.div`
	display: flex;
	align-items: center;
	padding: 8px 16px;
	width: 100%;
	min-width: 0;
	max-width: 100%;
	background: #fafafc;
	border-radius: 12px;
	flex-wrap: wrap;
	cursor: pointer;

	> img {
		margin-right: 16px;
		width: 30px;
	}

	&:hover > span {
		height: 0;
	}

	> span {
		display: block;
		overflow: hidden;
		max-width: 0;
		height: 0;
		font-size: 18px;

		text-overflow: ellipsis;
		white-space: nowrap;
		color: #454b5c;
		transition: height 0.5s;
		line-height: 27px;

		&.active {
			width: 100%;
			max-width: calc(100% - 46px);
			height: 27px;
		}
	}
`;
