export const checkCookie = (cname) => {
  const name = cname + '=';
  const ca = document.cookie.split(';');

  return ca.some(item => {
    return item.trim().startsWith(name);
  });
};

export const delCookie = (name, domain, hostname) => {
  hostname.includes(domain)
    ? document.cookie = `${name}=;domain=${domain};Max-Age=-99999999;`
    : document.cookie = `${name}=;Max-Age=-99999999;`;
};

export const getCookie = (cname) => {
  let cvalue = null;
  const name = cname + '=';
  const ca = document.cookie.split(';');
  
  ca.forEach(item => {
    if(item.trim().startsWith(name)) cvalue = item.trim().substring(name.length, item.trim().length);
  });

  return cvalue;
};

export const setCookie = (name, value, domain, hostname) => {
  hostname.includes(domain)
    ? document.cookie = `${name}=${value};path=/;domain=${domain};`
    : document.cookie = `${name}=${value};path=/;`;
};