import { stringify } from 'query-string';
import { Api } from 'services/api';

const baseUrl = process.env.REACT_APP_EXAM_TEST_DOMAIN;

// 目前未使用
export const getTeacherExamList = async (params) => {
  const querystring = stringify(params);
  const response = await Api.get(`${baseUrl}/quiz/list/teacher?${querystring}`);
  return response;
};

// 目前未使用
export const getStudentExamList = async (params) => {
  const querystring = stringify(params);
  const response = await Api.get(`${baseUrl}/quiz/list/student?${querystring}`);
  return response;
};

// 目前未使用
export const updateExamStatus = async payload => {
  const response = await Api.post(`${baseUrl}/quiz/status`,payload);
  return response;
};


/**
 * 測驗列表
 *
 * @param {string} groupId 班級ＩＤ
 * @param {string} educationalSystem 學制
 * @param {string} subject 科目
 * @param {string} examName 測驗名稱
 * @param {boolean} unansweredOnly 僅顯示尚未作答測驗
 * @param {string} sortKey 排序ＫＥＹ  開始時間 startAt/結束時間 endAt
 * @param {string} sortOrder 針對『排序ＫＥＹ』升冪 asc/降冪 desc
 * @param {number} limit 一頁顯示幾筆
 * @param {array} beforeId 在 試卷ID 之前
 * @param {array} afterId 在 試卷ID 之後
 *
 * @return {object} https://asia-east1-live-oneclass-test.cloudfunctions.net/groups/docs/#/Exam/get__groupId__exams
 */
export const getGroupExamList = async (groupId, params) => {
  const { examName, educationalSystem, subject, unansweredOnly, sortKey, sortOrder, limit, beforeId, afterId } = params;

  let queryParams = {};
  if (educationalSystem) queryParams.educationalSystem = educationalSystem;
  if (subject) queryParams.subject = subject;
  if (examName) queryParams.examName = examName;
  if (unansweredOnly) queryParams.unansweredOnly = unansweredOnly;
  if (sortKey) queryParams.sortKey = sortKey;
  if (sortOrder) queryParams.sortOrder = sortOrder;
  if (limit) queryParams.limit = limit;
  if (beforeId) queryParams.beforeId = beforeId;
  if (afterId) queryParams.afterId = afterId;
  
  const querystring = stringify(queryParams);
  const response = await Api.get(`/groups/${groupId}/exams?${querystring}`);
  return response;
};

/**
 * 測驗前檢查
 *
 * @param {string} groupId 班級ＩＤ
 * @param {string} examId 考試ＩＤ
 *
 * @return {object} 
    https://asia-east1-live-oneclass-test.cloudfunctions.net/groups/docs/#/Exam/post__groupId__exams__examId__attend
 */
export const attendGroupExamForGroupUser = async (groupId, examId) => {
  const response = await Api.post(`/groups/${groupId}/exams/${examId}/attend`);
  return response;
};

/**
 * 作答結果
 *
 * @param {string} groupId 班級ＩＤ
 * @param {string} examId 考試ＩＤ
 *
 * @return {object} 
    https://asia-east1-live-oneclass-test.cloudfunctions.net/groups/docs/#/Exam/get__groupId__exams__examId__results
*/
export const getGroupExamResults = async (groupId, examId) => {
  const response = await Api.get(`/groups/${groupId}/exams/${examId}/results`);
  return response;
};

/**
 * 科目下拉選單
 *
 * @param {string} groupId 班級ＩＤ
 *
 * @return {object} 
    https://asia-east1-live-oneclass-test.cloudfunctions.net/groups/docs/#/Exam/get__groupId__exams_options
*/
export const getGroupExamOptions = async groupId => {
  const response = await Api.get(`/groups/${groupId}/exams/options`);
  return response;
};
