import React from 'react';
import { Button } from 'components';
import { UiCustomerLink } from './CustomerLink.style';


/**
 * 非組織人員頁面
 */

export const CustomerLink = () => {
  const goTutor = () => {
    window.open('https://tutor.oneclass.com.tw/#b3040');
  };

  return (
    <UiCustomerLink>
      <div>
        <div className="text">
          您目前不屬於此機構班級的學生 <br />
          快來加入OneClass體驗吧！
        </div>
        <div className="button">
          <Button buttonColor="new" onClick={goTutor}>立即體驗</Button>
        </div>
      </div>
    </UiCustomerLink>
  );
};
