import styled from 'styled-components';

export const UiMyOrganizations = styled.div`
display: block;

> .actionBox {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 34px;

	> .title {
		font-size: 32px;
		font-weight: 700;
		line-height: 36px;
		color: #242c3f;
	}
}
`;

export const UiBanner = styled.div`
	overflow: hidden;
	margin: auto;
	width: 100%;
	font-size: 0;
	border-radius: 12px;

	> img {
		width: 100%;
		object-fit: contain;
		object-position: center;
	}
`;

export const UiCardBox = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;

	> div {
		margin: 15px;
	}
`;