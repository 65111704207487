import styled from 'styled-components';

export const UiClickablePopoverMenu = styled.div`
	display: flex;
	border-radius: 50%;
	transition: 0.35s;

	& > svg {
		cursor: pointer;
	}

	&:hover {
		background-color: #fafafc;
	}
`;

export const UiClickablePopoverMenuList = styled.div`
	&.active {
		& li {
			color: #242c3f !important;
			border-bottom: 1px solid #f2f4f8 !important;

			&:hover {
				cursor: pointer !important;
				background-color: rgba(0, 0, 0, 0.04) !important;
			}
		}
	}

	& li:first-child {
		color: #242c3f;
		border-bottom: 1px solid #f2f4f8;

		&:hover {
			cursor: initial;
			background-color: initial;
		}
	}

	& li:last-child {
		color: #8b90a0;
		border-top: 1px solid #f2f4f8;

		&:hover {
			cursor: initial;
			background-color: initial;
		}
	}
`;
