import styled from 'styled-components';

export const UiCustomerLink = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;

	& .text {
		font-weight: bold;
		text-align: center;
		margin-bottom: 16px;
		line-height: 24px;
	}

	& .button {
		display: flex;
		justify-content: center;
	}
`;
