import styled from 'styled-components';

export const UiSideBar = styled.div`
	display: block;
	width: 200px;
	height: 100%;

	& > div + div {
		margin-top: 8px;
	}
`;

export const UiSiderBarItem = styled.div`
	border-radius: 12px;

	& > a {
		display: flex;
		align-items: center;
		padding: 12px 24px;
		max-height: 52.5px;
		font-size: 14px;
		text-decoration: none;
		color: ${({ active, disabled }) => disabled ? '#d5d7de' : (active ? '#ec7963' : '#8B90A0')};
		background-color: ${({ active }) => active ? 'rgba(236,121,99,0.1)' : '#fff'};
		border-radius: 12px;
		transition: 0.5s;
		line-height: 32px;
		cursor: pointer;

		&:hover {
			background-color: #fef2f0;
		}

		& > svg {
			margin-right: 1rem;
			font-size: 1.8rem;

			& > path {
				fill: ${({ active, disabled }) => disabled ? '#d5d7de' : (active ? '#ec7963' : '#8B90A0')};
			}
		}
	}
`;

export const UiSideBarSecond = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	padding: 12px 24px;
	max-height: 52.5px;
	font-size: 14px;
	text-decoration: none;
	color: ${({ active }) => active ? '#ec7963' : '#8B90A0'};
	background-color: ${({ active }) => active ? 'rgba(236,121,99,0.1)' : '#fff'};
	border-radius: 12px;
	transition: 0.5s;
	line-height: 32px;
	cursor: pointer;

	& > svg {
		margin-right: 1rem;
		font-size: 1.8rem;

		& > path {
			fill: ${({ active }) => active ? '#ec7963' : '#8B90A0'};
		}
	}

	&:hover {
		background-color: rgba(238, 135, 115, 0.1);

		div,
		span {
			opacity: 1;
			visibility: visible;
			transition: visibility 0s, opacity 0.3s linear;
		}
	}
`

export const UiSideBarSecondBox = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	padding: 10px;
	width: 210px;
	background-color: #fff;
	border-radius: 12px;
	opacity: 0;
	visibility: hidden;
	z-index: 999999;
	box-shadow: 2px 2px 10px rgb(0 0 0 / 10%);
	transform: translateX(100%);
`

export const UiSideBarSecondItem = styled.div`
	margin-top: 8px;

	&:first-child {
		margin-top: 0;
	}

	& > a {
		display: flex;
		align-items: center;
		padding: 12px 24px;
		max-height: 52.5px;
		font-size: 14px;
		text-decoration: none;
		color: ${({ active }) => active ? '#ec7963' : '#8B90A0'};
		background-color: ${({ active }) => active ? 'rgba(236,121,99,0.1)' : '#fff'};
		border-radius: 12px;
		line-height: 32px;
		cursor: pointer;

		&:hover {
			background-color: ${({ active }) => active ? 'rgba(238,135,115,0.1)' : '#f5f5f5'};
		}

		& > svg {
			margin-right: 1rem;
			font-size: 1.8rem;

			& > path {
				fill: ${({ active }) => active ? '#ec7963' : '#8B90A0'};
			}
		}
	}
`

export const UiSideBarSecondItemIcon = styled.span`
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	height: 21px;
	visibility: hidden;

	& > svg {
		font-size: 1.8rem;

		& > path {
			fill: #8b90a0;
		}
	}
`

export const UiNew = styled.div`
	position: absolute;
	right: 2rem;
	padding: 0 4px;
	font-size: 8px;
	color: #fff;
	background-color: #ec7963;
	border-radius: 2px;
	line-height: 1.7;
`