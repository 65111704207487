import styled from 'styled-components';

export const UiSingleLayout = styled.div`
	display: grid;
	overflow: hidden;
	height: 100vh;
	grid-template-areas: 'leftSide header' 'leftSide main';
	grid-template-rows: auto 1fr;
	grid-template-columns: 3.5fr 6.5fr;

	> .leftSidebar {
		display: flex;
		overflow: hidden;
		padding: 30px 48px;
		background: #fafafc;
		flex-direction: column;
		grid-area: leftSide;

		> .logo {
			font-size: 0;
			margin-bottom: 30px;

			> a {
				object-fit: contain;
				cursor: pointer;

				> img {
					height: 40px;
				}
			}
		}

		> .greetings {
			padding: 14px 0;
			font-size: 32px;
			font-weight: 700;
			color: #242c3f;
			line-height: 36px;
		}

		> .dashSkeleton {
			margin: 16px 0;
		}

		> .main {
			display: flex;
			padding: 20px 0;
			flex-direction: column;
			height: calc(100% - 104px);
			flex: 1;

			> .subTitle {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 36px;

				> div {
					display: flex;
					align-items: center;
					font-size: 20px;
					font-weight: 700;
					color: #454b5c;
					line-height: 32px;
				}

				> a {
					font-size: 16px;
					text-decoration: none;
					color: #8b90a0;
					line-height: 24px;
				}
			}

			> .wtfIssue {
				overflow: hidden;
				margin: auto;
				width: 90%;
				font-size: 0;
				border-radius: 12px;
				cursor: pointer;

				> img {
					width: 100%;
					border-radius: 12px;
					object-fit: contain;
					object-position: center;
				}
			}
		}
	}

	> .header {
		grid-area: header;
	}

	> .main {
		overflow-x: hidden;
		padding: 14px 18px 30px;
		height: 100%;
		grid-area: main;
		background-color: #fff;
	}
`;

export const UiSessionNum = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 16px;
	width: 32px;
	height: 32px;
	font-size: 14px;
	color: #fff;
	background: #ec7963;
	border-radius: 50px;
`;

export const UiSingleContent = styled.div`
	flex: 1;
	margin: auto;
	width: 95%;
	font-family: ${({theme})=>theme.typography.fontFamily};
	${({ theme: { media } }) => media.desktop`
		width:${({ theme }) => theme.mediaWidth.desktop}
		`}
	${({ theme: { media } }) => media.notebook`
		width:${({ theme }) => theme.mediaWidth.notebook}
		`}
	${({ theme: { media } }) => media.pad`
		width:${({ theme }) => theme.mediaWidth.pad}
		`}
	${({ theme: { media } }) => media.phone`
		width:${({ theme }) => theme.mediaWidth.phone}
		`}
`;
