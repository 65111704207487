import { stringify } from 'query-string';
import { Api } from 'services/api';
import { checkNaniLinkLogin } from 'services/api/login/login';

/**
 -@param {string} organizationId
 *@param {number} nowPage
 *@param {number} rowsPage
 *@param {string} fileName
 -@param {string} status
 *@獲取資源列表
 */
export const getResourcesList = isHome => (organizationId, classId) => async params => {
  const { nowPage = 0, rowsPage = 100, fileName, status } = params;
  const queryParams = {
    limit: rowsPage ? rowsPage : 100,
  };
  if (rowsPage && nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }
  if (fileName) {
    queryParams.fileName = fileName;
  }
  if (status) {
    queryParams.status = status;
  }
  const querystring = await stringify(queryParams);
  const response = isHome ?
    await Api.get(organizationId ?
      `/organizations/${organizationId}/groups/${classId}/resources?${querystring}` :
      `/groups/${classId}/resources?${querystring}`
    ) :
    await Api.get(`/organizations/${organizationId}/resources?${querystring}`);
  return response;
};


/**
 * 取得  所有教學資源 list  limit 100
 */
export const getAllResourcesList = isHome => (organizationId, classId) => () => {
  return getResourcesList(isHome)(organizationId, classId)({ rowsPage: 100, status: 'ready' });
};

/**
 -@param {string} organizationId
 *@param {object} data
 *@上傳檔案後新增資料到oneClass資料庫
 */
export const createResources = async (organizationId, data) => {
  const response = await Api.post(`/organizations/${organizationId}/resources`, data);
  return response;
};
/**
 *@param {string} bookId
 *@轉檔
 */
export const reconvertBook = async bookId => {
  const dada = {
    bookId
  };
  const response = await Api.post(`${process.env.REACT_APP_PDF_API_DOMAIN}/reconvert_book`, dada);
  return response;
};

/**
 *@param {string} organizationId
 *@更新檔案狀態
 */
export const refreshResourceState = async organizationId => {
  const response = await Api.get(`/organizations/${organizationId}/resources/refresh-status`);
  return response;
};

/**
 *@param {string} bookId
 *@刪除
 */
export const delBook = async (bookId) => {
  const { jwt } = JSON.parse(checkNaniLinkLogin());
  const dada = {
    bookId,
    token: jwt
  };
  await Api.post(`${process.env.REACT_APP_PDF_API_DOMAIN}/del_book`, dada);
  return true;
};

/**
 *@param {string} organizationId
 *@param {string} resourceId
 *@刪除oneClass資料庫中的pdf
 */
export const oneClassDelBook = async (organizationId, resourceId) => {
  const response = await Api.delete(`/organizations/${organizationId}/resources/${resourceId}`);
  return response;
};


/**
 *@param {string} tags
 *@param {string} displayName
 *@param {boolean} isPublish
 *@param {number} nowPage
 *@param {number} rowsPage
 *@獲取bookstore books
 */
export const getBookstoreBooks = (organizationId) => async (params) => {
  const { nowPage = 0, rowsPage = 100, tags, displayName } = params;
  let queryParams = {
    limit: rowsPage ? rowsPage : 100,
    isPublish: true
  };
  if (rowsPage && nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }

  if(displayName){
    queryParams.displayName = displayName;
  }

  if(tags && tags.length > 0){
    queryParams.tags = tags;
  }

  const querystring = await stringify(queryParams);
  const response = await Api.get(`/organizations/${organizationId}/resources/public/books?${querystring}`);
  return response;
};

/**
 *@param {string} category SchoolYear, EducationalSystem, Grade, Subject, Other"
 *@獲取 bookstore 標籤
 */
export const getBookstoreTags = (organizationId) => async ({ category, educationalSystem }) => {

  let queryParams = {
    category
  };

  if(educationalSystem){
    queryParams.educationalSystem = educationalSystem;
  }

  const querystring = await stringify(queryParams);
  const response = await Api.get(`/organizations/${organizationId}/resources/public/tags?${querystring}`);
  return response;
};

/**
 * @param {string} organizationId
 * @param {string} sessionId

 * @return {object} 課程 教材資源
 */
export const getSessionResourcesById = async (sessionId) => {
  const response = await Api.get(`/sessions/${sessionId}/resources`);
  return response;
};
