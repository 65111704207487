import React from 'react';

import { HomeGroupLayout, CustomerService } from 'components';


/**
 * 註冊/忘記密碼頁面
 */

export const CustomerServicePage = props => {
  return (
    <HomeGroupLayout showSideBar={false}>
      <CustomerService {...props} />
    </HomeGroupLayout>
  );
};



