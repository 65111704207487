import styled from 'styled-components';
import {
	FormHelperText as MatFormHelperText,
  FormControlLabel as MatFormControlLabel
} from '@material-ui/core';

export const UiRadioGroup = styled.div`
	display: flex;
	align-items: center;
`;

export const UiFormLabel = styled.div`
	display: flex;
	align-items: center;
	min-width: 150px;
	font-size: 1rem;
	color: rgba(0, 0, 0, 0.54);
	line-height: 1.1875em;
`;

export const UiFormHelperText = styled(MatFormHelperText)`
	color: #f44336;
`;

export const UiRadio = styled(MatFormControlLabel)`
	> span:first-child.Mui-checked {
		color: #ec7963;
	}
`;