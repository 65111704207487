import styled from 'styled-components';
import { Avatar as MatAvatar } from '@material-ui/core';
export const UiHomeMemberProfileCardRead = styled.div`
	display: block;
`;

export const UiHomeMemberProfileLabelGroup = styled.div`
	display: flex;
	justify-content: center;
	margin: 0 auto;
	padding-bottom: 1rem;
	width: 50%;

	& > span {
		color: #189798;
	}

	& > svg {
		margin-right: 0.5rem;
		font-size: 1.2rem;
	}

	& > p {
		margin-top: 0;
		margin-bottom: 0;
		width: 45%;
		text-align: right;
		word-wrap: break-word;
	}

	& > p:last-child {
		margin-left: 0.5rem;
		width: 55%;
		text-align: left;
	}

	& > button {
		margin: 0 0.5rem;
	}
`;
export const UiMatAvatar = styled(MatAvatar)`
	width: 60px;
	height: 60px;
`;
export const UiHomeMemberProfileLabel = styled.div`
	& span {
		padding-right: 7.5px;
	}
`;

export const UiTip = styled.div`
	margin: 0 auto;
	padding-bottom: 1rem;
	width: 50%;
	color: #2b7285;
`;
