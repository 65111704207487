/* eslint-disable max-len */
import React, { useRef, useState,Fragment } from 'react';
import PropTypes from 'prop-types';
import { UiCodeInput } from './CodeInput.style';


/**
 * 代碼輸入
 */
 const range = (size) => [...Array(size).keys()];

export const CodeInput = ({ length, onChange, separator = null, separators = {},isError }) => {
  const [characters, setCharacters] = useState({});
  const inputRefs = useRef({});
  const setCharacter = (position, value) => {
    const n = {
      ...characters,
      [position]: value
    };
    setCharacters(n);
    onChange(Object.values(n).join('').trim());
  };

  return (
    <UiCodeInput isError={isError}>
      {range(length).map((i) => {
        return (
          <Fragment key={i}>
            {i > 0 && (
              <span className="text">{separator}</span>
            )}
            {separators[i] && <span className="text">{separators[i]}</span>}
            <input
              ref={(el) => (inputRefs.current[i] = el)}
              onChange={(e) => {
                setCharacter(i, e.target.value);

                if (!e.target.value) {
                  return;
                }

                if (inputRefs.current[i + 1]) {
                  inputRefs.current[i + 1].focus();
                }
              }}
              onFocus={(e) => e.target.select()}
              onClick={(e) => e.target.select()}
              autoFocus={i === 0}
              maxLength={1}
              onPaste={(e) => {
                if (i !== 0) {
                  return;
                }
                const paste = (e.clipboardData || window.clipboardData).getData(
                  'text'
                );
                if (paste && paste.length === length) {
                  setCharacters(paste.split(''));
                  range(length).forEach((i) => {
                    inputRefs.current[i].value = paste[i];
                  });
                  onChange(paste.trim());
                }
              }}
              onKeyDown={(e) => {
                if (e.key === characters[i]) {
                  e.preventDefault();
                  if (inputRefs.current[i + 1]) {
                    inputRefs.current[i + 1].focus();
                  }
                }

                if (e.key === 'Backspace') {
                  e.preventDefault();
                  setCharacter(i, '');
                  inputRefs.current[i].value = '';
                  if (inputRefs.current[i - 1]) {
                    inputRefs.current[i - 1].focus();
                  }
                }
              }}
              />
          </Fragment>
        );
      })}
    </UiCodeInput>
  );
};

CodeInput.propTypes = {

};


