import React, { useEffect, useMemo } from 'react';
import sessionReportEmpty from 'assets/images/sessionReportEmpty.png';
import SessionSummary from './SessionSummary';
import SessionRecord from './SessionRecord';
import SessionPerformance from './SessionPerformance';
// import SessionScreenshot from './SessionScreenshot';

import { Icon, Loading, } from 'components';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getGroupInfoById as getGroupInfoByIdApi } from 'services/api/home/class';
import { getSessionReport as getSessionReportApi } from 'services/api/organization/session';
import { useClass } from 'store/class';
import { useSetState } from 'utils/hooks/useSetState';
import { useServerTime } from 'utils/hooks/useServerTime';
import { 
  UiActionWrapper,
  UiActionContainer,
  UiPreviousPageContainer,
  UiUpdatedTimeContainer,
  UiSessionReportWrapper,
  UiEmptyWrapper,
  UiEmptyImgContainer,
  UiEmptyMessage,
} from './SessionReport.style';


/**
 * 課堂報告
 */

export const SessionReport = () => {
  const history = useHistory();
  const { classId, sessionId, timeSpanId } = useParams();
  const location = useLocation();
  const { nowPage } = location.state;
  const organizationId = process.env.REACT_APP_LIVE_ORGANIZATION_ID;
  const [{ myClasses: { dataInfo: { userType } } }] = useClass();
  const [,{ getToday }] = useServerTime();
  const isTeacher = userType === 'teacher' ? true : false;
  const [{
    isLoading,
    classData,
    summaryData,
    attendanceData,
    performanceData,
    // screenshotData,
  }, setState]  = useSetState({
    isLoading: true,
    classData: null,
    summaryData: null,
    attendanceData: null,
    performanceData: null,
    // screenshotData: null,
  });

  const clickGoBack = () => {
    const path = `/home/${classId}/sessions`;
    const state = {nowPage };
    history.push(path, state);
  };

  /* 取得課堂報告 */
  const fetchSessionReport = async () => {
    const res = await Promise.all([
      getSessionReportApi(organizationId, sessionId, timeSpanId, 'summary'),
      getSessionReportApi(organizationId, sessionId, timeSpanId, 'attendance'),
      getSessionReportApi(organizationId, sessionId, timeSpanId, 'performance'),
      // getSessionReportApi(organizationId, sessionId, timeSpanId, 'screenshot'),
    ]);

    res[0].isSuccess && setState({ summaryData: res[0].data });
    res[1].isSuccess && setState({ attendanceData: res[1].data });
    res[2].isSuccess && setState({ performanceData: res[2].data });
    // res[3].isSuccess && setState({ screenshotData: res[3].data });
  };

  /* 取得班級資訊 */
  const fetchGroupById = async () => {
    const { isSuccess, data } = await getGroupInfoByIdApi(classId);
    isSuccess && setState({ classData: data });
  };

  /* 打課堂報告、班級資訊API */
  const fetchData = async () => {
    setState({ isLoading: true });
    await fetchSessionReport();
    await fetchGroupById();
    setState({ isLoading: false });
  };

  const isHaveData = useMemo(() => {
    return !summaryData || !attendanceData || !performanceData; 
    // || !screenshotData // 課堂截圖之判斷
  }, [summaryData, attendanceData, performanceData]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {/* 回上頁、更新時間 */}
      <UiActionWrapper>    
        <UiActionContainer>
          <UiPreviousPageContainer onClick={clickGoBack}>
            <Icon title='返回上一頁' name='arrowBackIos' size='1.25rem' haveBg={false} icolor='#8B90A0' />
            返回上一頁
          </UiPreviousPageContainer>
        </UiActionContainer>
        <UiUpdatedTimeContainer>
          上次更新時間：{getToday('yyyy / MM / dd HH:mm')}
        </UiUpdatedTimeContainer>
      </UiActionWrapper>

      {
        isLoading
          ? <Loading />    
          : isHaveData
              ? <UiEmptyWrapper>
                  <UiEmptyImgContainer>
                    <img src={sessionReportEmpty} alt='無課堂報告' />
                  </UiEmptyImgContainer>
                  <UiEmptyMessage>
                    { 
                      isTeacher 
                        ? '查無此課程紀錄'
                        : '您未出席該課程，故無報表資料可供顯示'
                    }
                  </UiEmptyMessage>
                </UiEmptyWrapper>
              : /* 課程摘要、上課紀錄、課堂表現、課堂截圖 */
                <UiSessionReportWrapper>
                  <SessionSummary data={summaryData} classData={classData} />
                  <SessionRecord data={attendanceData} />
                  <SessionPerformance data={performanceData} />
                  {/* <SessionScreenshot data={screenshotData} /> */}
                </UiSessionReportWrapper>
      }
    </>
  );
};
