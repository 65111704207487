import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import lrz from 'lrz';
import {
  Divider as MatDivider,
  Avatar as MatAvatar,
  Dialog as MatDialog,
 } from '@material-ui/core';

import { useLocation } from 'react-router-dom';
import { getPageName } from 'utils/pagePathSlicer';
import { useSetState } from 'utils/hooks/useSetState';
import { useAlert } from 'utils/hooks/useAlert';
import { useUuid } from 'utils/hooks/useUuid';
import { Icon, Button } from 'components';
import {
  UiPostModal,
  UiPostTitle,
  UiCloseButton,
  UiUserInfo,
  UiTextarea,
  UiUpdateFileBox,
  UiUpdateFileItem,
  UiFileBox,
  UiFileItem,
  UiImageItem,
  UiAddFileItem,
  UiDialogContent,
  UiDialogContentText,
  UiDialogActions,
  UiPostContent,
  UiBottonBox,
} from './PostModal.style';


/**
 * 建立/編輯貼文彈窗
 */

const IMAGE = 'image';
const POST = 'post';

export const PostModal = (
  {
    data,
    isOpen,
    name,
    isLoading,
    avatarSrc = '',
    getModalState = () => { },
    onSubmit = () => { },
    isOnlyImage = false,
    isSecret = false,
    title,
    placeholder,
    type = POST,
    dialogContent = ''
  }) => {
  const [getUuid] = useUuid();
  const { setAlert } = useAlert();
  const { pathname } = useLocation();
  const [{ state, content, files, fileIsImage, editFiles, postId,dialogState }, setState] = useSetState({
    postId: '',
    state: false,
    content: '',
    files: [],
    fileIsImage: false,
    editFiles: [],
    dialogState:false,
  });

  //改變modal 開關
  const modalStateHandler = () => {
    getModalState(!isOpen);
    setState({
      state: !isOpen,
      dialogState:false
    });
  };

  const checkData = () => {
    if(files.length > 0 || content !== ''){
      setState({
        dialogState:true
      });
    }else {
      modalStateHandler();
    }
  };

  const onDialogClose = () => {
    setState({
      dialogState:!dialogState
    });
  };

  useEffect(() => {
    setState({
      state: isOpen,
      content: '',
      files: [],
    });
  }, [isOpen]);

  const onTextChange = value => {
    setState({
      content: value
    });
  };

  //上傳檔案
  const onFileChange = filesData => {
    const nextFilesData = Object.values(filesData).map(value => value);
    if (!filesData || nextFilesData.length === 0) return;

    let isImage = false;
    const defaultFileLength = editFiles.length; //編輯的檔案數量
    const fileType = nextFilesData[0].type.split('/')[0]; //上傳檔案的類型
    if (isOnlyImage && fileType !== IMAGE) return;
    if (defaultFileLength === 0) {
      if (files.length > 0) {
        isImage = files[0].type.split('/')[0] === IMAGE ? true : false;
        if (files[0].type.split('/')[0] !== fileType) return;
      } else {
        isImage = fileType === IMAGE ? true : false;
      }
    } else {
      const editFirstFileType = editFiles[0].type.split('/')[0]; //編輯的第一個檔案類型
      if (editFirstFileType !== fileType) return;
      isImage = editFirstFileType === IMAGE ? true : false;
    }

    if (files.length >= (6 - defaultFileLength) && isImage) {
      setAlert('上傳圖片最多6張!', 'wrarning');
      return;
    }
    if (files.length > (0 + defaultFileLength) && !isImage) {
      setAlert('上傳檔案最多1個!', 'wrarning');
      return;
    }
    if (isImage) {
      Promise.all(nextFilesData.map(file => {
        return lrz(file).then(res => {
          let newFile = {
            id: getUuid(),
            name: file.name,
            type: file.type,
          };
          newFile.path = res.base64;
          for (let value of res.formData.values()) {
            newFile.value = value;
          }
          return newFile;
        });
      })).then(res => {
        const newFiles = files.concat(res).slice(0, 6);
        setState({
          files: newFiles,
          fileIsImage: isImage
        });
      });
    } else {
      const newFiles = files.concat(nextFilesData.map(item => {
        return {
          id: getUuid(),
          name: item.name,
          type: item.type,
          value:item
        };
      }));
      setState({
        files: newFiles,
        fileIsImage: isImage
      });
    }
  };

  //刪除正要上傳的檔案
  const removeFile = id => {
    const nextFiles = files.filter(file => file.id !== id);
    setState({
      files: nextFiles,
    });
  };

  //刪除編輯的檔案
  const removeEditFile = id => {
    const nextFiles = editFiles.filter(file => file.id !== id);
    setState({
      editFiles: nextFiles,
    });
  };

  //發佈
  const submitHandler = () => {
    const payload = {
      postId,
      content,
      files,
      editFiles
    };
    onSubmit(payload);
  };

  useEffect(() => {
    if (!data) return;
    const { attachments = [], id, content } = data;
    const editFiles = attachments.map(item => {
      return {
        id: getUuid(),
        path: item.attachmentUrl,
        name: item.attachmentName,
        type: item.attachmentType
      };
    });
    const firstFileType = editFiles.length > 0 ? editFiles[0].type.split('/')[0] : false;
    setState({
      postId: id,
      content: content,
      fileIsImage: firstFileType === IMAGE ? true : false,
      editFiles
    });
  }, [data, isOpen]);
  return (
    <UiPostModal maxWidth={false} open={state} onClose={() => checkData()}>
      <UiPostTitle>{title || '建立貼文'}</UiPostTitle>
      <UiCloseButton onClick={() => checkData()}>
        <Icon name="close" icolor="#a1a4b1" haveBg={false}/>
      </UiCloseButton>
      <MatDivider />
      {/* 不是用於上傳相片 */}

      <UiPostContent>
        {
          !isOnlyImage &&
          <UiUserInfo>
            <MatAvatar src={avatarSrc} alt={name} />
            <span>{name}</span>
          </UiUserInfo>
        }
        <UiTextarea
          defaultValue={data && data.content}
          autoFocus
          placeholder={placeholder || `${name}, 在想些什麼？`}
          onChange={e => onTextChange(e.target.value)}
          isOnlyImage={isOnlyImage}
        />
        <UiFileBox>
          {
            files.map(file => {
              const Component = fileIsImage ?
                <UiImageItem key={file.id}>
                  <img src={file.path} alt={file.id} />
                  <Icon title='移除' name='delete' onClick={() => removeFile(file.id)} />
                </UiImageItem> :
                <UiFileItem key={file.id}>
                  {file.name}
                  <Icon title='移除' name='close' onClick={() => removeFile(file.id)} />
                </UiFileItem>;
              return Component;
            })
          }
          {
            editFiles.map(file => {
              const Component = fileIsImage ?
                <UiImageItem key={file.id}>
                  <img src={file.path} alt={file.id} />
                  <Icon title='移除' name='delete' onClick={() => removeEditFile(file.id)} />
                </UiImageItem> :
                <UiFileItem key={file.id}>
                  {file.name}
                  <Icon title='移除' name='close' onClick={() => removeEditFile(file.id)} />
                </UiFileItem>;
              return Component;
            })
          }
          {
            (
              (isOnlyImage || (fileIsImage && (files.length + editFiles.length) > 0)) &&
              (files.length + editFiles.length) < 6)
            &&
            < UiAddFileItem >
              <Icon title='上傳圖片' name='add' />
              <input
                type="file"
                onChange={e => onFileChange(e.target.files)} accept="image/*"
                onClick={(event) => {
                  event.target.value = null;
                }}
                multiple
              />
            </UiAddFileItem>
          }
        </UiFileBox>
        {
          (isOnlyImage || isSecret) ||
          <UiUpdateFileBox>
            <UiUpdateFileItem type="Picture">
              <Icon.Svg name='Picture' />
              <p>相片</p>
              <input
                type="file"
                onChange={e => onFileChange(e.target.files)} accept="image/*"
                multiple
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
            </UiUpdateFileItem>
            <UiUpdateFileItem type="Video">
              <Icon.Svg name='Video' />
              <p>影片</p>
              <input
                type="file"
                onChange={e => onFileChange(e.target.files)} accept="video/*,audio/*"
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
            </UiUpdateFileItem>
            <UiUpdateFileItem type="File">
              <Icon.Svg name='File' />
              <p>附件</p>
              <input
                type="file"
                onChange={e => onFileChange(e.target.files)} accept=".pdf,.doc,.docx"
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
            </UiUpdateFileItem>
          </UiUpdateFileBox>
        }
      </UiPostContent>
      <MatDivider />
      <UiBottonBox>
        <Button
          disabled={type === POST ? !content : (!content && files.length === 0)}
          onClick={() => submitHandler()} loading={isLoading}
          dataGaCategory={getPageName(pathname)}
          dataGaAction='發佈貼文'
        >
          發佈貼文
        </Button>
      </UiBottonBox>
      <MatDialog
        maxWidth="xl"
        open={dialogState}
        onClose={()=>onDialogClose()}
      >
        <UiDialogContent>
          <UiDialogContentText>
            <div>
              <Icon name="errorOutline" icolor="#facea8" haveBg={false} size="7rem"/>
            </div>
            {dialogContent}
          </UiDialogContentText>
        </UiDialogContent>
        <UiDialogActions>
          <Button onClick={()=>onDialogClose()} buttonColor="cancel">
            取消
          </Button>
          <Button onClick={()=>modalStateHandler()} buttonColor="new">
            確定
          </Button>
        </UiDialogActions>
      </MatDialog>
    </UiPostModal >
  );
};

PostModal.propTypes = {
  data: PropTypes.object,
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  avatarSrc: PropTypes.string,
  getModalState: PropTypes.func,
  onSubmit: PropTypes.func,
  isOnlyImage: PropTypes.bool,
  isSecret: PropTypes.bool,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  dialogContent:PropTypes.string,
};

