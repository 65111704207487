import React from 'react';
import { HomeSetting, HomeGroupLayout } from 'components';


/**
 * 班級設定頁面
 */

export const HomeSettingPage = () => {
  return (
    <HomeGroupLayout>
      <HomeSetting />
    </HomeGroupLayout>
  );
};



