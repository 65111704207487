// https://github.com/diegohaz/arc/wiki/API-service
import { stringify } from 'query-string';
import { logout } from 'services/api/login';

const checkStatus = response => {
  if (response.ok) {
    return response;
  }
  const error = new Error(`${response.status} ${response.statusText}`);
  error.response = response;
  throw error;
};

const parseJSON = response => response.json();

const formatResponse = response => {
  response.isSuccess = (response.status === 'success' || response.code === 'SUCCESS');
  if(response.error?.errorCode === 1002) {
    logout();
    // window.location.reload();
  }
  return response;
};

const parseSettings = ({ method = 'GET', data, ...otherSettings } = {}) => {
  const headers = {
    'Content-Type': otherSettings['Content-Type'] || 'application/json',
    'Authorization': '',
    ...otherSettings.headers,
  };
  const settings = Object.assign({
    body: data ? JSON.stringify(data) : undefined,
    method,
    headers,
  });
  return settings;
};

const parseEndpoint = (endpoint, params) => {
  const url = endpoint.indexOf('http') === 0 ? endpoint : process.env.REACT_APP_API_DOMAIN + endpoint;
  const querystring = params ? `?${stringify(params)}` : '';
  return `${url}${querystring}`;
};



const api = {};

api.request = async (endpoint, { params, ...settings } = {}) =>
  await fetch(parseEndpoint(endpoint, params), parseSettings(settings))
    .then(checkStatus)
    .then(parseJSON)
    .then(formatResponse)
    .catch(err=> {
      // eslint-disable-next-line no-console
      console.error(err);
      logout();
      // window.location.reload();
    });
['GET'].forEach(method => {
  api[method] = (endpoint, settings) => api.request(endpoint, { method, ...settings });
});
['POST', 'PUT','DELETE','PATCH'].forEach(method => {
  api[method] = (endpoint, data, settings) => api.request(endpoint, { method, data, ...settings });
});

api.create = (settings = {}) => ({
  settings,

  setToken(token) {
    this.settings.headers = {
      ...this.settings.headers,
      Authorization: `${token}`,
    };
  },

  unsetToken() {
    this.settings.headers = {
      ...this.settings.headers,
      Authorization: undefined,
    };
  },

  request(endpoint, settings) {
    return api.request(endpoint, Object.assign({}, this.settings, settings));
  },

  post(endpoint, data, settings) {
    return this.request(endpoint, { method: 'POST', data, ...settings });
  },

  get(endpoint, settings) {
    return this.request(endpoint, { method: 'GET', ...settings });
  },

  put(endpoint, data, settings) {
    return this.request(endpoint, { method: 'PUT', data, ...settings });
  },

  patch(endpoint, data, settings) {
    return this.request(endpoint, { method: 'PATCH', data, ...settings });
  },

  delete(endpoint, data, settings) {
    return this.request(endpoint, { method: 'DELETE', data, ...settings });
  },
});

export const Api = api.create();

export default api;
