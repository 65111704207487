import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { StylesProvider as MatStylesProvider, ThemeProvider as MatThemeProvider } from '@material-ui/core/styles';
import zhTwLocale from 'date-fns/locale/zh-TW';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from 'styled-components';
import { SnackbarProvider } from 'notistack';

import configureStore from 'store';
import * as serviceWorker from './serviceWorker';
import theme from 'theme';
import './i18n';
import { Alert, Beforeunload } from 'components';
import App from 'components/App';
import { BodyStyle } from './index.style';

const store = configureStore({});

/* GA 追蹤 Component */
const GATracker = () => {
  /* GA 追蹤事件 */
  const gaEventTrack = () => {
    // body 外無 hasAttribute 方法，避免錯誤
    const body = document.querySelector('body');

    body.addEventListener('click', (e) => {
      let target = e.target;
      
      // 查找元素不包含 data-ga-action，且到 BODY 後跳出
      while(target.hasAttribute('data-ga-action') === false && target.tagName !== 'BODY'){
        // 父元素覆寫當下元素
        target = target.parentNode;
      }

      if(target.hasAttribute('data-ga-action')){
        const dataGaCategory = target.getAttribute('data-ga-category'); // 分類：網站頁面
        const dataGaAction = target.getAttribute('data-ga-action');     // 事件：功能名稱
        // console.log('ga tracking element');
        // console.log(dataGaCategory);
        // console.log(dataGaAction);
        ReactGA.event({
          category: dataGaCategory,
          action: dataGaAction,
        });

        return false;
      }
    }, false);

    // select 專用
    body.addEventListener('change', (e) => {
      let target = e.target;

      // checkbox 跳出
      if(target.tagName === 'INPUT') return;
      
      // 查找元素不包含 data-ga-action，且到 BODY 後跳出
      while(target.hasAttribute('data-ga-category') === false && target.tagName !== 'BODY'){
        // 父元素覆寫當下元素
        target = target.parentNode;
      }

      if(target.hasAttribute('data-ga-category')){
        const dataGaCategory = target.getAttribute('data-ga-category'); // 分類：網站頁面    
        const dataGaAction = target.value;  // 事件：功能名稱（取得 option name）
        // console.log('onChange ga tracking element');
        // console.log(dataGaCategory);
        // console.log(dataGaAction);
        ReactGA.event({
          category: dataGaCategory,
          action: dataGaAction,
        });
      }
    }, false);
  };

  useEffect(() => {
    gaEventTrack();
  }, []);

  return <></>;
};

const Main = () => (
  <Beforeunload onBeforeunload={() => '是否要離開囉？'}>
    <GATracker />
    <CssBaseline />
    <MatStylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <MatThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhTwLocale}>
            <SnackbarProvider
              maxSnack={3}
              autoHideDuration={2000}
              anchorOrigin={
                {
                  vertical: 'top',
                  horizontal: 'right'
                }
              }
            >
              <Alert />
              <Provider store={store}>
                <BrowserRouter>
                  <BodyStyle />
                  <App />
                </BrowserRouter>
              </Provider>
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </MatThemeProvider>
      </ThemeProvider>
    </MatStylesProvider>
  </Beforeunload >
);

const root = document.getElementById('root');

ReactDOM.render(Main(), root);

if (module.hot) {
  module.hot.accept('components/App', () => {
    require('components/App');
    ReactDOM.render(Main(), root);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
