import React from 'react';
import PropTypes from 'prop-types';
import { getDurationText, getEduSubjectsText, getGradesText } from 'helper/openCourse';
import {
  UiBody,
  UiBodyTitle,
  UiBodySubtitle,
  UiBookContainer,
  UiBook,
  UiHeader,
  UiHeaderDetail,
  UiHeaderTitle,
  UiHeaderSubtitle,
  UiSessionCard,
} from './SessionCard.style';

export const SessionCard = (props) => {
  const { onClick, session } = props;
  const { title } = session;
  const durationText = getDurationText(session);
  const gradesText = getGradesText(session);
  const eduSubjectsText = getEduSubjectsText(session);
  const newSession = { ...session, eduSubjectsText, gradesText, durationText };

  return (
    <UiSessionCard onClick={()=>onClick(newSession)}>
      <UiHeader>
        <UiBookContainer>
          <UiBook />
        </UiBookContainer>
        <UiHeaderDetail>
          <UiHeaderTitle>{title}</UiHeaderTitle>
          <UiHeaderSubtitle>{gradesText}</UiHeaderSubtitle>
        </UiHeaderDetail>
      </UiHeader>

      <UiBody>
        <UiBodyTitle>{eduSubjectsText}</UiBodyTitle>
        <UiBodySubtitle>{durationText}</UiBodySubtitle>
      </UiBody>
    </UiSessionCard>
  );
};


SessionCard.propTypes = {
  onClick: PropTypes.func,
  session: PropTypes.object,
};