import React from 'react';
import PropTypes from 'prop-types';
import { Header, Footer, SideBar, HomeBanner } from 'components';
import { UiHomeGroupLayout, UiHomeGroupContent, UiChildrenContainer } from './HomeGroupLayout.style';


/**
 * 前台頁面組件 沒有SideBar 用於條款頁
 */

export const HomeGroupLayout = ({ children, showSideBar = true }) => {
  return (
    <UiHomeGroupLayout>
      <Header />
      {/* <HomeBanner showSideBar={showSideBar} /> */}
      <UiHomeGroupContent showSideBar={showSideBar ? true : false}>
        {
          showSideBar &&
          <SideBar />
        }

        <UiChildrenContainer>{children}</UiChildrenContainer>
      </UiHomeGroupContent>
      <Footer />
    </UiHomeGroupLayout>
  );
};

HomeGroupLayout.propTypes = {
  children: PropTypes.node,
  showSideBar: PropTypes.bool
};


