import React, { useEffect } from 'react';

import { useClass } from 'store/class';
import { UiHomeInfo, UiHomeInfoTitle, UiInfoBox, UiDescription } from './HomeInfo.style';


/**
 * 班級簡介頁面
 */

export const HomeInfo = () => {
  const [{ classBrief }, { getClassBrief }] = useClass();
  useEffect(() => {
    getClassBrief();
  }, []);
  return (
    <UiHomeInfo>
      <UiHomeInfoTitle>簡介</UiHomeInfoTitle>
      <UiInfoBox>
        <UiDescription>{classBrief.description}</UiDescription>
      </UiInfoBox>
    </UiHomeInfo>
  );
};
