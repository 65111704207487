import { COMPANY_DOMAIN, SERVICE_NAME, } from 'constants/index';
import { getCookie, delCookie, setCookie, } from 'utils/cookie';

const COOKIE_NAME_SERVICE_ROOT = 'nani_oneclass_serviceRoot';
const COOKIE_NAME_LIVEONECLASS_ORGANIZATION_ID = 'nani_oneclass_liveOneClass_organizationId';

export const delOnePaperLoginCookie = () => {
  const cookieNameServiceRoot = COOKIE_NAME_SERVICE_ROOT;
  const cookieNameOrganizationId = COOKIE_NAME_LIVEONECLASS_ORGANIZATION_ID;
  const cookieDomain = COMPANY_DOMAIN;
  const hostname = window.location.hostname;
  delCookie(cookieNameServiceRoot, cookieDomain, hostname);
  delCookie(cookieNameOrganizationId, cookieDomain, hostname);
};

export const getOnePaperLoginCookie = () => {
  const cookieName = COOKIE_NAME_SERVICE_ROOT;
  return getCookie(cookieName);
};

export const setOnePaperLoginCookieByServiceRoot = () => {
  const cookieName = COOKIE_NAME_SERVICE_ROOT;
  const cookieValue = SERVICE_NAME;
  const cookieDomain = COMPANY_DOMAIN;
  const hostname = window.location.hostname;
  setCookie(cookieName, cookieValue, cookieDomain, hostname);
};

export const setOnePaperLoginCookieByOrganizationId = (organizationId) => {
  const cookieName = COOKIE_NAME_LIVEONECLASS_ORGANIZATION_ID;
  const cookieValue = organizationId;
  const cookieDomain = COMPANY_DOMAIN;
  const hostname = window.location.hostname;
  setCookie(cookieName, cookieValue, cookieDomain, hostname);
};