import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField as MatTextField,Box , TextareaAutosize} from '@material-ui/core';
import { useSetState } from 'utils/hooks/useSetState';
import { Card, DateTimePicker, UploadButton, Checkbox,Button,Icon, AlbumSlider } from 'components';
import { UiButtonBox,UiExistFileBox } from './HomeworkCardEdit.style';
import { EDIT_STATUS, HOMEWORK_SETTINGS_VALUE } from 'constants/index';

/**
 * 編輯作業卡牌
 */

export const HomeworkCardEdit = ({
  data,
  type = '',
  isLoading = false,
  isOpen,
  getOpenState = () => {},
  onSubmit = () => { },
  changeCardState = () => { },
}) => {
  const [state, setOpen] = useState(true);
  const [{
    title,
    description,
    dueAt,
    publishedAt,
    file,
    attachments,
    isLateSubmissionAllowed,
    accessLevel,
    sliderState,
    initSlide,
    filesLimit
   }, setState] = useSetState(
    {
      title:'',
      description:'',
      dueAt:'',
      publishedAt:'',
      file:[],
      attachments:[],
      isLateSubmissionAllowed:false,
      accessLevel:HOMEWORK_SETTINGS_VALUE.OWNER,
      sliderState:false,
      initSlide:0,
      filesLimit:10
    }
  );

  const modalStateHandler = () => {
    changeCardState();
    setOpen(!isOpen);
    getOpenState(!isOpen);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const closeHandler = e => {
    if (!e) {
      setState({
        sliderState: false
      });
      return;
    }
    if (e.currentTarget !== e.target) {
      e.stopPropagation();
      return;
    }
    setState({
      sliderState: false
    });
  };

  const openAlbumSlider = (state, index) => {
    setState({
      sliderState: state,
      initSlide: index,
    });
  };

  useEffect(() => {
    if(!data) return;
    const {
      title,
      description,
      dueAt,
      publishedAt,
      attachments,
      isLateSubmissionAllowed,
      accessLevel
   } = data;
   setState({
    title,
    description,
    dueAt,
    publishedAt,
    attachments,
    isLateSubmissionAllowed,
    accessLevel
   });
  }, [data]);

  const onChangeHandle = (value,key) => {
    setState({
      [key]:value
    });
  };

  const onSubmitHandle = () => {
    const payload = {
      title,
      description,
      dueAt,
      publishedAt,
      file,
      isLateSubmissionAllowed,
      accessLevel
    };
    if(type === EDIT_STATUS.EDIT) {
      payload.attachments = attachments;
    }
    onSubmit(payload);
    setState({
      title:'',
      description:'',
      dueAt:'',
      publishedAt:'',
      attachments:[],
      file:[],
      isLateSubmissionAllowed:false,
      accessLevel:HOMEWORK_SETTINGS_VALUE.OWNER
     });
  };

  const onDelete = (target) => {
    const newAttachments = attachments.filter(attachment=>attachment.attachmentUrl !== target);
    setState({
      attachments:newAttachments
    });
  };

  return (
    <>
      {
        state &&
        <Card>
          <Box p={2}>
            <MatTextField
              label="作業標題"
              variant="outlined"
              style={{width: '100%'}}
              value={title}
              onChange={e=>onChangeHandle(e.target.value,'title')}
            />
          </Box>
          <Box p={2}>
            <TextareaAutosize
              placeholder="作業內容"
              rowsMin={7}
              style={{width: '100%', resize: 'none', padding: '10px' }}
              value={description}
              onChange={e=>onChangeHandle(e.target.value,'description')}
            />
          </Box>
          <Box p={2}>
            <DateTimePicker
              label="截止日期"
              value={dueAt}
              fullWidth={false}
              onChange={n=>onChangeHandle(n,'dueAt')}
            />
          </Box>
          <Box p={2}>
            <DateTimePicker
              label="預約發佈"
              value={publishedAt}
              fullWidth={false}
              onChange={n=>onChangeHandle(n,'publishedAt')}
            />
          </Box>
          {(type === EDIT_STATUS.EDIT && attachments.length > 0) &&
          <Box p={2}>
            <UiExistFileBox>
              <span>已附加檔案 :</span>
              {
                attachments.map((attachment,index)=>{
                  return (
                    <div className="item" key={attachment.attachmentUrl}>
                      <span onClick={() => openAlbumSlider(true,index)}>{attachment.attachmentName}</span>
                      <Icon name="delete" size="small" onClick={() => onDelete(attachment.attachmentUrl)} />
                    </div>
                  );
                })
              }
            </UiExistFileBox>
          </Box>}
          <Box p={2}>
            <UploadButton
              label="附加檔案"
              onChange={files=>onChangeHandle(files,'file')}
              filesLimit={filesLimit - attachments.length - file.length}
            />
          </Box>
          <Box pl={2}>
            <Checkbox
              label="允許遲交"
              checkedValue="isLateSubmissionAllowed"
              onChange={value=>{
                const isLateSubmissionAllowedValue = value === 'isLateSubmissionAllowed' ? true : false;
                onChangeHandle(isLateSubmissionAllowedValue,'isLateSubmissionAllowed');
              }}
              checked={isLateSubmissionAllowed}
            />
          </Box>
          <Box pl={2} pt={1}>
            <Checkbox
              label="其他教師可看見"
              checkedValue={true}
              onChange={value=>{
                const isAccessLevel = value === true ? HOMEWORK_SETTINGS_VALUE.GROUP : HOMEWORK_SETTINGS_VALUE.OWNER;
                onChangeHandle(isAccessLevel,'accessLevel');
              }}
              checked={accessLevel === HOMEWORK_SETTINGS_VALUE.GROUP}
            />
          </Box>
          <UiButtonBox>
            <Button onClick={modalStateHandler} buttonColor="cancel" loading={isLoading}>取消</Button>
            <Button onClick={onSubmitHandle} buttonColor="new" loading={isLoading}>送出</Button>
          </UiButtonBox>
          {attachments.length > 0 &&
          <AlbumSlider
            isOpen={sliderState}
            close={closeHandler}
            defaultData={{ attachments }}
            initalSlide={initSlide}
            type="slider"
          />}
        </Card>
      }
    </>

  );
};

HomeworkCardEdit.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    userName: PropTypes.string,
    createData: PropTypes.string,
    contentTitle: PropTypes.string,
    content: PropTypes.string,
    deadline: PropTypes.string,
    number: PropTypes.number,
    total: PropTypes.number,
    dueAt: PropTypes.any,
    attachments: PropTypes.array,
  }),
  type: PropTypes.string,
  onSubmit: PropTypes.func,
  changeCardState: PropTypes.func,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool
};


