import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  IconButton as MatIconButton,
  Avatar as MatAvatar
} from '@material-ui/core';

import { useUser } from 'store/user';
import { ClickablePopoverMenu } from 'components';
import { UiAvatarMenu, UiNickname, UiButtonWrapper } from './AvatarMenu.style';


/**
 * Header 用頭像組件
 */

export const AvatarMenu = () => {
  const history = useHistory();
  const [{ profile, myAllOrganizations,myOrganization }, { userLogout }] = useUser();
  const isHome = useRouteMatch({ path: '/home' }) ? true : false;
  const logoutHandler = () => {
    userLogout();
  };

  const goToHome = () => {
    history.push('/home');
  };

  const goToMemberCenter = () => {
    history.push('/home/membercenter/profile');
  };

  const goToAdminPage = () => {
   // eslint-disable-next-line max-len
   window.location.href = `https://live-oms${process.env.REACT_APP_ENV === 'release' ? '' : '-' + process.env.REACT_APP_ENV}.oneclass.com.tw/home`;
  };

  const popoverMenuList = () => {
    const list = [
      { label: profile.nickname ? profile.nickname : profile.lastName + profile.firstName },
      { label: '登出', func: logoutHandler, iconName: 'Logout',isSvg:true, },
      { label:`版本 v${process.env.REACT_APP_VERSION || '1.0.0'}`}
    ];
    if(myOrganization.isLoaded){
      list.splice(1, 0,
        { label: '回到首頁', func: goToHome, iconName: 'GroupList',isSvg:true, },
      );
      list.splice(2, 0,
        { label: '會員中心', func: goToMemberCenter, iconName: 'Member',isSvg:true, },
      );
    }
    if (isHome && Object.keys(myAllOrganizations.ownOrganizationsDataMap).length > 0) {
      list.splice(2, 0,
        { label: '後台管理', func: goToAdminPage, iconName: 'Oms',isSvg:true, }
      );
    }
    return list;
  };




  return (
    <UiAvatarMenu>
      {
        profile && profile.id && (
          <ClickablePopoverMenu menuList={popoverMenuList()}>
            <UiButtonWrapper>
              <MatIconButton color="inherit">
                <MatAvatar src={profile.thumbnailUrl || ''} alt={profile.nickname} />
              </MatIconButton>
            </UiButtonWrapper>
          </ClickablePopoverMenu>
        )
      }
    </UiAvatarMenu>
  );
};

AvatarMenu.propTypes = {
  userId: PropTypes.string
};


