import { useEffect, useMemo } from 'react';
import { useClass } from 'store/class';

const ALL_SCHOOL_YEAR = 'all';
const schoolYearFormat = (years) => 
      years.map(year=> ({
        name:year + '學年度',
        value:year
      }));

const formatWithAll = (options) =>  [
  {  
    name:'全部',
    value: ALL_SCHOOL_YEAR 
  }, ...options
];

const formatWithNone = (options) => [
  ...options,
  {  
    name:'無',
    value: '' 
  }
];

export const useSchoolYear = () => {

  const [{ schoolYear }, { getSchoolYear }] = useClass();

  useEffect(() => {
    if(schoolYear.data.length > 0) return;
    getSchoolYear({ full: false });
  },[]);

  const base = schoolYearFormat(schoolYear.data || []);

  const schoolYearOptions = useMemo(() => base, [schoolYear]);

  const schoolYearOptionsWithAll = useMemo(() => formatWithAll(schoolYearOptions), [schoolYearOptions]);

  const schoolYearOptionsWithNone = useMemo(() => formatWithNone(schoolYearOptions), [schoolYearOptions]);

  return { schoolYearOptions, schoolYearOptionsWithAll, schoolYearOptionsWithNone };
};


export const useAllSchoolYear = () => {

  const [{ allSchoolYear }, { getSchoolYear }] = useClass();

  useEffect(() => {
    if(allSchoolYear.data.length > 0) return;
    getSchoolYear({ full: true });
  },[]);

  const base = schoolYearFormat(allSchoolYear.data || []);

  const schoolYearOptions = useMemo(() => base, [allSchoolYear]);

  const schoolYearOptionsWithAll = useMemo(() => formatWithAll(schoolYearOptions), [schoolYearOptions]);

  const schoolYearOptionsWithNone = useMemo(() => formatWithNone(schoolYearOptions), [schoolYearOptions]);

  return { schoolYearOptions, schoolYearOptionsWithAll, schoolYearOptionsWithNone };
};