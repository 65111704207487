/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo } from 'react';
import theme from 'theme';

import { stringify } from 'query-string';
import {
  Table,
  IconInput,
  Select,
  TimePicker,
  Button,
  Prompt,
  Loading,
  OutlineButton,
  ResourceModal,
  Icon,
} from 'components';
import { ORGANIZATION_SETTINGS_VALUE, EXAM_PROJECT_NAME, minute } from 'constants/index';
import { useSessions } from 'store/sessions';
import { useUser } from 'store/user';
import { useClass } from 'store/class';
import { useAlert } from 'utils/hooks/useAlert';
import { useSetState } from 'utils/hooks/useSetState';
import { useServerTime } from 'utils/hooks/useServerTime';
import { useParams, useHistory, useLocation, } from 'react-router-dom';
import { UiTable, UiActionBox, UiflexBox } from 'styles';
import { keepSessionReport } from 'services/api/organization/session';
import { getPageName } from 'utils/pagePathSlicer';
import {
  UiSessionsTableButtonBox,
  // UiLoadingContainer,
  // UiTableWrapper,
  // UiTableContainer,
  UiTitle,
  UiBannerWrapper,
  UiBannerFigure,
  UiBannerImg,
  UiBannerFigcaption,
  UiBannerLink,
} from './HomeSessionsTable.style';


/**
 * 前台課程表
 */

const schema = {
  name: {
    name: '課程名稱',
    defaultValue: ''
  },
  subject:{
    name:'科目',
    defaultValue:'--'
  },
  startDate: {
    name: '日期',
    defaultValue: ''
  },
  startTime: {
    name: '時間',
    defaultValue: ''
  },
  hostName: {
    name: '教師',
    defaultValue: ''
  }
};

const NOW = 'NOW';
const PROCESSING = 'PROCESSING';
const PAST = 'PAST';
const FUTURE = 'FUTURE';
const ALL = 'ALL';

const selectOptions = [
  {
    name: '今日課程',
    value: NOW
  },
  {
    name: '當前課程',
    value: PROCESSING
  },
  {
    name: '已上課程',
    value: PAST
  },
  {
    name: '未來課程',
    value: FUTURE
  },
  {
    name: '所有課程',
    value: ALL
  }
];

const publicClassLinkText = '前往會員公開課';

export const HomeSessionsTable = () => {
  const history = useHistory();
  const { setAlert } = useAlert();
  const { pathname, state } = useLocation();
  const { classId } = useParams();
  const [
    { allInterval, futureInterval, pastInterval, processingInterval, timestamp: serverTimestamp, todayInterval },
    { setServerTime }
  ] = useServerTime();
  const [{ myOrganization, profile }] = useUser();
  const [{ myClasses }] = useClass();
  const { dataInfo: { userType } } = myClasses;
  const [{ sessions }, { getSessions, attendanceSession }] = useSessions();
  const [
    {
      dateInterval,
      name,
      nowPage,
      rowsPage,
      startAt,
      endAt,
      sessionAttendanceManagementSetting,
      isClick,
      // isFirstMount,
      isListLoading,
      isOpenResourceModal,
      resourceData,
      tableSessionId,
    }, setState] = useSetState({
      dateInterval: 'now',
      name: '',
      nowPage: state?.nowPage ?? 0,
      rowsPage: 10,
      startAt: null,
      endAt: null,
      officialName: '',
      sessionAttendanceManagementSetting: ORGANIZATION_SETTINGS_VALUE.NONE,
      isClick: true,
      // isFirstMount: true,
      isListLoading: true,
      isOpenResourceModal: false,
      resourceData: [],
      tableSessionId: null,
  });

  useEffect(() => {
    if (myOrganization.isLoaded) {
      const { officialName,sessionAttendanceManagementSetting } = myOrganization.organization;
      setState({
        officialName,
        sessionAttendanceManagementSetting
      });
    }
  }, [myOrganization]);

  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };
  const onchangeHandler = (value, key) => {
    setState({
      [key]: value,
      nowPage: 0
    });
  };

  const onSelectChangeHandler = selected => {
    let dateParams = {};
    setState({
      nowPage: 0
    });
    switch (selected) {
      case NOW:
        dateParams = { ...todayInterval };
        break;
      case PAST:
        dateParams = { ...pastInterval };
        break;
      case PROCESSING:
        setServerTime();
        dateParams = { ...processingInterval };
        break;
      case FUTURE:
        dateParams = { ...futureInterval };
        break;
      case ALL:
        dateParams = { ...allInterval };
        break;
      default:
        break;
    }
    setState({
      dateInterval: selected,
      ...dateParams
    });
  };

  /* click 課程教材彈窗 - 取消按鈕 */
  const clickResourceModalCancelHandler = () => {
    setState({ isOpenResourceModal: false});
  };

  /* click 課程教材彈窗 - 確認按鈕 */
  const clickResourceModalOkHandler = resourceValue => {
    const classRoomUrl = `${process.env.REACT_APP_ONEBOOK_TEACHER_DOMAIN}/${resourceValue}`;
    window.open(classRoomUrl, '_blank');  // 跳轉至 oneBook 教材
    setState({ isOpenResourceModal: false });
  };

  useEffect(() => {
    if(!serverTimestamp) return;
    (async () => {
      setState({ isListLoading: true });
      await getSessions({ nowPage, rowsPage, name, startAt, endAt }, serverTimestamp);
      setState({
        isListLoading: false,
        // isFirstMount: false
      });
    })();
  }, [nowPage, rowsPage, name, startAt, endAt, serverTimestamp]);

  useEffect(() => {
    if(resourceData.length <= 0) return;

    setState({
      resourceValue: resourceData[0].value,
    });
  }, [resourceData]);

  /* 排序 sessions 透過「課程開始時間」 */
  const sortSessionsDataByStartAt = (sessions) => {
    return sessions.sort((a, b) => {
      if(a.startAt < b.startAt) return -1;
      return 0;
    });
  };
  /* 取得距離上課時間最近的一筆課程 */
  const getLatestSession = () => {
    const sortedSessions = sortSessionsDataByStartAt([ ...sessions.data ]);
    sortedSessions.find(session => session.isCanEnterClass.type !== 'end');
  };
  /* 取得課程結束後，button 變成「課程結束」的剩餘時間 */
  const getRemainingTime = (latestSession) => {
    // 90分鐘 + 10秒
    const requiredTimeOfSessionEndBtn = 90 * 60 * 1000 + 10000;
    const { endAt } = latestSession;
    const remainingTime = (endAt + requiredTimeOfSessionEndBtn) - serverTimestamp;
    return remainingTime;
  };
  useEffect(() => {
    if(sessions.data.length === 0) return;
    // 取得距離上課時間最近的一筆課程
    const latestSession = getLatestSession();

    if(!latestSession) return;
    // 取得課程結束後，button 變成「課程結束」的剩餘時間
    const remainingTime = getRemainingTime();

    // 時間到重打 API
    const timeoutId = setTimeout(async () => {
      setServerTime();
    }, remainingTime);
    return () => clearTimeout(timeoutId);
  }, [sessions.data]);


  // const btnText = (type) => {
  //   switch (type) {
  //     case 'before10':
  //       return '進入教室';
  //     case 'noStart':
  //     case 'before20':
  //       return userType === 'teacher' ? '進入教室' : '預習教材';
  //     case 'after90':
  //       return '回到課堂';
  //     case 'end':
  //       return userType === 'teacher' ? '課程教材' : '複習教材';
  //     default:
  //       break;
  //   }

  // };

  const ActionComponents = ({ params }) => {
    const {
      isCanEnterClass: { type },
      resources,
      id,
      timeSpanId,
      hostId,
      hostName,
      hostAttendance,
      preExamId,
      postExamId,
      type: sessionsType,
      users,
      startAt,
     } = params;
    const usersLength = users?.length || 0;

    // 更新課堂報表
    const fetchKeepSessionReport = async () => {
      const payload = {
        sessionId: id,
        timeSpanId,
      };
      const { isSuccess } = await keepSessionReport(payload);
      if (!isSuccess) {
        setAlert('取得課堂報表失敗!', 'error');
      } 
    };

    const enterInteractiveSession = () => {
      const isCallReportApi = hostId === profile.id || users.some(id => id === profile.id);
      if (isCallReportApi) {
        fetchKeepSessionReport();
      }

      let classType = '';
      switch(sessionsType){
        case 'interactive':
          classType = usersLength <= 1 ? 'single' : 'group';
          break;
        case 'collaborative':
          classType = usersLength <= 1 ? 'sync-single' : 'sync-multiple';
          break;
        case 'video':
          classType = 'group';
          break;
        default :
          break;
      }
      const querystring = stringify({
        bookIds: resources,
        classType: classType,
        role: userType,
        userName: myOrganization.userProfile.nickname,
        userId: profile.id,
        sessionId: id
      },{ arrayFormat: 'index' });
      window.open(`${process.env.REACT_APP_ONEBOARD_DOMAIN}/${id}/setup?${querystring}`, '_blank');
    };

    // click 進入教室按鈕
    const onClickEnterClassHandler = () => {
      setState({ tableSessionId: id });
      enterInteractiveSession();
    };

    const openExam = (testId) => {
      const openUrl = window.open('', '_blank');
      const url = `${process.env.REACT_APP_TEST_DOMAIN}?project=${EXAM_PROJECT_NAME}&range=${testId}&user=${profile.id}&roomId=${id}&teacherName=${hostName}&username=${profile.nickname}`;
      openUrl.location = url;
    };

    const attendanceHandle = async () => {
      if(!isClick) return;
      setState({
        isClick:false
      });
      let checkType = 'checkIn';
      if(hostAttendance?.checkInAt) checkType = 'checkOut';
      await attendanceSession(id,timeSpanId,{
        [checkType]:true
      });
      if(checkType === 'checkOut') window.open('https://docs.google.com/forms/d/e/1FAIpQLScqdg675ccYb0nHZ9LnvdgX1nVOK5iRuFrVZO7NVxyrplEuQg/viewform');
      setTimeout(()=>{
        setState({
          isClick:true
        });
      },1000);
    };

    const reviseVideoOnDemand = () => {
      window.open(`https://oneboard-bs-dev.oneclass.com.tw/player/${id}`);
    };

    const checkType = useMemo(()=>{
      if(sessionAttendanceManagementSetting !== ORGANIZATION_SETTINGS_VALUE.HOST || params.hostId !== profile.id){
        return {
          type:'notShow'
        };
      }

      if(hostAttendance?.checkOutAt){
        return {
          type:'finish',
          name:'簽退完成'
        };
      }else if(hostAttendance?.checkInAt){
        return {
          type:'needCheckOut',
          name:'教師簽退'
        };
      }else{
        if(type === 'end'){
          return {
            type:'finish',
            name:'觀看錄影'
          };
        }else{
          return {
            type:'needCheckIn',
            name:'教師簽到'
          };
        }

      }

    },[hostAttendance]);

    const goExamResultListPage = () => {
      history.push(`/home/${classId}/sessions/${id}/exam-result`);
    };

    // click 課堂報告
    const gotoSessionReport = () => {
      const location = {
        pathname: `/home/${classId}/sessions/${id}/timespan/${timeSpanId}/report`,
        state: {
          resourceId: resources[0],
          nowPage,
        }
      };
      history.push(location);
    };

    const enterGroup = (t) => {
      switch(t){
        case 'noStart':
        case 'before30':
          return (
            <Button disabled>課程尚未開始</Button>
          );
        case 'before10':
        case 'before5':
          return (
            <Button
              onClick={onClickEnterClassHandler}
              buttonColor='normal'
              dataGaCategory={getPageName(pathname)}
              dataGaAction='進入教室'
            >
              進入教室
            </Button>
          );
        // 上課時間結束後，5分鐘內
        case 'after5':
        case 'after90':
          return (
            <Button
              onClick={onClickEnterClassHandler}
              buttonColor='normal'
              dataGaCategory={getPageName(pathname)}
              dataGaAction={userType === 'teacher' ? '回到課堂' : '進入教室'}
            >
              {userType === 'teacher' ? '回到課堂' : '進入教室'}
            </Button>
          );
        case 'end':
          return (
            <Button disabled>課程結束</Button>
          );
        default:
          return null;
      }
    };

    return (
      <UiSessionsTableButtonBox>
        {enterGroup(type)}
        {
          (checkType.type !== 'notShow' && type !== 'noStart' && checkType.type !== 'finish') &&
          <OutlineButton
            buttonColor='normal'
            onClick={() => attendanceHandle()}
            loading={!isClick}
          >
            {checkType.name}
          </OutlineButton>
        }
        {
          // 此為 OneBroad 開課 並且 課程結束
          type === 'end' &&
          <>
            <Button
              buttonColor="new"
              onClick={()=> reviseVideoOnDemand()}
            >觀看錄影
            </Button>
          </>
        }
        <Button
          dataGaCategory={getPageName(pathname)}
          dataGaAction={userType === 'teacher' ? '課程教材' : ((type === 'after90' || type === 'end') ? '複習教材' : '預習教材')}
          // onClick={()=> onSessionModalToggle(id)}
          onClick={()=>setState({ isOpenResourceModal: true, tableSessionId: id, })}
        >
          {
            userType === 'teacher' ? '課程教材' : ((type === 'after90' || type === 'end') ? '複習教材' : '預習教材')
          }
        </Button>
        {
          (preExamId && userType === 'student') &&
          <Button
            buttonColor="visibility"
            dataGaCategory={getPageName(pathname)}
            dataGaAction='課前測驗'
            onClick={()=>openExam(preExamId)}
          >課前測驗
          </Button>
        }
        {
          (postExamId && userType === 'student') &&
          <Button
            buttonColor="visibility"
            dataGaCategory={getPageName(pathname)}
            dataGaAction='課後測驗'
            onClick={()=>openExam(postExamId)}
          >課後測驗
          </Button>
        }
        {
          ((preExamId || postExamId) && userType === 'teacher') &&
          <Button
            buttonColor="visibility"
            onClick={()=> goExamResultListPage()}
          >測驗結果
          </Button>
        }
        {
          // 課程類型為「協作、互動」，且「上課前十分鐘（預課時間）」，才顯示課堂報告
          ((sessionsType === 'collaborative' || sessionsType === 'interactive') && serverTimestamp > startAt - 10 * minute) &&
            <Button
              onClick={gotoSessionReport}
            >課堂報告
            </Button>
        }
      </UiSessionsTableButtonBox>
    );
  };

  return (
    <UiTable>
      { /* 課程教材 彈窗 */
        isOpenResourceModal &&
          <ResourceModal
            sessionId={tableSessionId}
            isOpenResourceModal={isOpenResourceModal}
            onCancel={clickResourceModalCancelHandler}
            onOk={clickResourceModalOkHandler}
          />
      }

      {/* 會員公開課 Banner */}
      {
        userType === 'student' &&
          <UiBannerWrapper>
            <UiBannerFigure>
              <UiBannerImg onClick={()=>history.push(`/home/${classId}/opencourse`)} />
              <UiBannerFigcaption>
                <UiBannerLink to={`/home/${classId}/opencourse`}>
                  <span>{publicClassLinkText}</span>
                  <Icon
                    icolor={theme.palette.primary.main}
                    haveBg={false}
                    name='chevronRight'
                  />
                </UiBannerLink>
              </UiBannerFigcaption>
            </UiBannerFigure>
          </UiBannerWrapper>
      }


      <UiActionBox>
        <UiTitle>課程</UiTitle>
      </UiActionBox>
      <UiActionBox>
        <UiflexBox>
          <IconInput
            placeholder='搜尋課程名稱'
            onClick={value => onchangeHandler(value, 'name')}
            onChange={value => onchangeHandler(value, 'name')}
          />
          {
            dateInterval === NOW &&
            <>
              <TimePicker
                label="選擇課程開始時間"
                value={startAt}
                onChange={value => onchangeHandler(value, 'startAt')}
              />
              <TimePicker
                label="選擇課程結束時間"
                value={endAt}
                onChange={value => onchangeHandler(value, 'endAt')}
              />
            </>
          }

        </UiflexBox>
        <UiflexBox>
          <Select
            options={selectOptions}
            submitHandler={value => onSelectChangeHandler(value)}
            label="課程區間"
            value={ALL}
          />
        </UiflexBox>
      </UiActionBox>

      {isListLoading && <Loading />}
      {
        sessions.data.length === 0
          ? (!isListLoading) && <Prompt message="目前暫無課程"></Prompt>
          : <Table
              data={sessions.data}
              schema={schema}
              changePage_Rows={changePage_Rows}
              totalPage={sessions.total}
              ActionComponents={ActionComponents}
              nowPage={nowPage}
            />
      }

      {/* 表格內滾動、表格內loading */}
      {/* {
        isFirstMount
          ? <Loading />
          : <UiTableWrapper footerHeight={'100px'} isLoading={isListLoading}>
              {
                sessions.data.length === 0
                  ? !isListLoading && <Prompt message="目前暫無課程"></Prompt>
                  : <>
                      <UiLoadingContainer isLoading={isListLoading}>
                        <Loading />
                      </UiLoadingContainer>
                      <UiTableContainer isLoading={isListLoading}>
                        <Table
                          data={sessions.data}
                          schema={schema}
                          changePage_Rows={changePage_Rows}
                          totalPage={sessions.total}
                          ActionComponents={ActionComponents}
                          nowPage={nowPage}
                        />
                      </UiTableContainer>
                    </>
              }
            </UiTableWrapper>
      } */}

    </UiTable>
  );
};
