import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components';
import { UiOutlineButton, UiInlineFlex } from './OutlineButton.style';


/**
 * button to outline style
 */

export const OutlineButton = ({
  buttoncolor = 'normal',
  children,
  onClick,
  disabled = false,
  loading = false,
  size,
  icon,
  textColor,
  
}) => {
  const clickEvent = (!loading && !disabled) ? onClick : () => { };

  return (
    <UiOutlineButton
    variant="outlined"
    size={size}
    textColor={buttoncolor === 'cancel'? '#3a4052' : textColor}
    buttoncolor={disabled ? 'disable' : buttoncolor}
    onClick={clickEvent}
    >
      {icon && <UiInlineFlex><Icon icolor={textColor} name={icon} haveBg={false} /></UiInlineFlex>}
      {children}
    </UiOutlineButton>
  );
};

OutlineButton.propTypes = {
  buttoncolor: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  size: PropTypes.number,
  icon: PropTypes.string,
  textColor: PropTypes.string,
};


