import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  UiHomeMemberProfileCardEdit,
} from './HomeMemberProfileCardEdit.style';
import { TextField } from '@material-ui/core';
import {
  DatePicker,
  CitySelector,
  Form,
  Select,
  NameInput,
  AvatarUpload,
} from 'components';
import { getTime } from 'date-fns';
import { useAlert } from 'utils/hooks/useAlert';
import { useSetState } from 'utils/hooks/useSetState';
import { useFirebaseStorage } from 'utils/hooks/useFirebaseStorage';

/**
 * 編輯會員資料
 */
const genderOptions = [
  {
    name: '男',
    value: 'male',
  },
  {
    name: '女',
    value: 'female',
  },
  {
    name: '其他',
    value: 'other',
  }
];
export const HomeMemberProfileCardEdit = ({
  data = {},
  changeCardType = () => { },
  cityChange = () => { },
  submitformHandler = () => { },
  isLoading,
}) => {
  const [{
    imgUrl,
  }, setState] = useSetState({
    imgUrl: '',
  });
  const { setAlert } = useAlert();
  const { upload } = useFirebaseStorage();
  const schema = {
    nickname: {
      component: TextField,
      elementProps: {
        variant: 'outlined',
        label: '暱稱',
        value: '',
        helperText: ''
      },
      rules: {
        maxLength: {
          value: 24,
          message: '最大長度為 24 個字元'
        }
      }
    },
    name: {
      component: NameInput,
      elementProps: {
        variant: 'outlined',
        helperText: '',
        onChange: (n) => {
          return n[0];
        }
      },
      rules: {
      }
    },
    mobileNumber: {
      component: TextField,
      elementProps: {
        variant: 'outlined',
        label: '手機號碼',
        value: '',
        helperText: ''
      },
      rules: {
      }
    },
    phoneNumber: {
      component: TextField,
      elementProps: {
        variant: 'outlined',
        label: '電話號碼',
        value: '',
        helperText: ''
      },
      rules: {
      }
    },
    gender: {
      component: Select,
      elementProps: {
        variant: 'outlined',
        label: '性別',
        options: genderOptions,
        value: 'other',
        isOutline: false,
        defaultValue: 'other',
        onChangeName: 'submitHandler',
      },
      rules: {
      }
    },
    birthday: {
      component: DatePicker,
      elementProps: {
        label: '生日',
        minDate: new Date(1900, 1, 1, 1),
        onChange: (n) => {
          return n[0];
        },
        helperText: ''
      }
    },
    addressData: {
      component: CitySelector,
      elementProps: {
        variant: 'outlined',
        onChange: ([val]) => { return val; },
      },
      rules: {
        required: {
          value: true,
          message: '本欄位為必填'
        },
      }
    },
    address: {
      component: TextField,
      elementProps: {
        variant: 'outlined',
        label: '地址',
        value: '',
        helperText: ''
      },
      rules: {
      }
    },
  };

  const nextData = useCallback(() => {
    // schema 比對
    let newData = Object.assign({}, schema);
    let nameData = {};
    let addressData = {};
    if (data['firstName'] && data['lastName']) {
      const firstName = data['firstName'];
      const lastName = data['lastName'];
      nameData = { firstName, lastName };
    }

    if (data['addressCountyName'] && data['addressDistrictName']) {
      const addressCountyName = data['addressCountyName'];
      const addressDistrictName = data['addressDistrictName'];
      addressData = { addressCountyName, addressDistrictName };
    }
    Object.entries(newData).forEach(([key]) => {
      if (!data['gender']) {
        data['gender'] = 'other';
      }


      // set data
      if (key === 'staffId') {
        newData[key].elementProps.defaultValue = data['id'];
      } else if (key === 'name') {
        newData[key].elementProps.defaultValue = nameData;
      } else if (key === 'addressData') {
        newData[key].elementProps.defaultValue = addressData;
      } else if (key === 'birthday') {
        if (data[key]) {
          const year = data[key].substr(0, 4);
          const month = data[key].substr(4, 2) - 1;
          const day = data[key].substr(6, 2);
          newData[key].elementProps.defaultValue = getTime(new Date(year, month, day));
        } else {
          newData[key].elementProps.defaultValue = data[key];
        }

      }
      else {
        newData[key].elementProps.defaultValue = data[key];
      }
    });
    return newData;
  }, [data]);


  const buttons = [
    {
      text: '回到上一頁',
      color: 'info',
      icon: 'exitToApp',
      func: () => { changeCardType('read'); }
    },
    {
      text: '確認',
      color: 'new',
      type: 'submit',
    }
  ];

  // 上傳檔案
  const onUploaded = async fileTarget => {//編輯才有上傳圖片
    const file = fileTarget[0];
    const enCodeUserId = encodeURIComponent(data.id).replace('.', '');
    const fileType = file.type.split('/')[1];
    const uploadPath = `user/profile/avatars/${enCodeUserId}.${fileType}`;
    const { status, url } = await upload(uploadPath, file);

    if (status) {
      setState({ imgUrl: url });
      setAlert('上傳成功!', 'success');
    } else {
      setAlert('上傳失敗!', 'wrarning');
    }
  };

  const submitHandler = data => {
    if (imgUrl !== '')
      data.thumbnailUrl = imgUrl;

    if (data.addressData.county) {
      if (!data.addressData.township) {
        schema.addressData.elementProps['required'] = true;
        return;
      }
      if (!data.address) {
        setAlert('請填寫地址', 'error');
        return;
      }
    }

    if (data.name.firstName !== '' && data.name.lastName !== '') {
      schema.name.elementProps['required'] = false;
    } else if (data.name.firstName !== '' || data.name.lastName !== '') {
      schema.name.elementProps['required'] = true;
      return;
    } else {
      schema.name.elementProps['required'] = false;
    }
    submitformHandler(data);
  };
  return (
    <UiHomeMemberProfileCardEdit>
      <AvatarUpload
        imgUrl={imgUrl !== '' ? imgUrl : data.thumbnailUrl}
        onUploaded={onUploaded}
      />
      <Form
        schema={!isLoading ? nextData() : {}}
        isLoading={isLoading}
        onSubmit={submitHandler}
        buttons={buttons}
      />
    </UiHomeMemberProfileCardEdit>
  );
};

HomeMemberProfileCardEdit.propTypes = {
  data: PropTypes.shape({

  }),
  changeCardType: PropTypes.func,
  cityChange: PropTypes.func,
  submitformHandler: PropTypes.func,
  isLoading: PropTypes.bool,
};


