import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSetState } from 'utils/hooks/useSetState';
import { useHomework } from 'store/homework';
import { Table, Button } from 'components';
import { SUBMIT_STATUS } from 'constants/index';
import { formatTimestamp } from 'utils/date';
import { UiTable, UiActionBox, UiflexBox } from 'styles';
import { AlbumSlider } from 'components';
import JSZip from 'jszip';
import { UiFileActionBox, UiStudentLink } from './HomeworkSubmittedTable.style';


/**
 * 作業繳交狀況
 */

const schema = {
  ownerAttendeeNumber: {
    name: '座號',
    defaultValue: '-'
  },
  ownerName: {
    name: '姓名',
    defaultValue: ''
  },
  submitStatus: {
    name: '繳交狀況',
    defaultValue: '',
    importantCondition: 'submitStatus',
  },
  submittedAt: {
    name: '繳交時間',
    defaultValue: '-',
  },
  submitCount: {
    name: '繳交次數',
    defaultValue: '0',
  },
  attachments: {
    name: '附件',
    defaultValue: '-'
  },
  comment: {
    name: '留言',
    defaultValue: '-'
  }
};

const AttachmentsBox = ({ data,fileName }) => {

  const [{
    sliderState,
    initSlide
  },setState] = useSetState({
    sliderState:false,
    initSlide:0
  });

  const openAlbumSlider = (state, index) => {
    setState({
      sliderState: state,
      initSlide: index,
    });
  };

  const closeHandler = e => {
    if (!e) {
      setState({
        sliderState: false
      });
      return;
    }
    if (e.currentTarget !== e.target) {
      e.stopPropagation();
      return;
    }
    setState({
      sliderState: false
    });
  };
  const downloadZip = async (data) => {
    const zip = new JSZip();
    await Promise.all(data.map(async item=>{
      const fileBlob = await fetch(item.attachmentUrl).then(res => res.blob());
      zip.file(item.attachmentName, fileBlob);
    }));

    const zipFile = await zip.generateAsync({type: 'blob' }).then(content => {
      return content;
    });

    const url = window.URL.createObjectURL(new Blob([zipFile]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `${fileName}.zip`);
		document.body.appendChild(link);
		link.click();

  };
  return (
    <>
      <UiFileActionBox>
        <div onClick={() => downloadZip(data)}>下載</div>
        <div onClick={() => openAlbumSlider(true,0)}>預覽</div>
      </UiFileActionBox>
      <AlbumSlider
        isOpen={sliderState}
        close={closeHandler}
        defaultData={{ attachments:data }}
        initalSlide={initSlide}
        type="slider"
      />
    </>
  );
};

export const HomeworkSubmittedTable = () => {
  const [{ submittedHomeworks }, { getSubmittedHomeworks }] = useHomework();
  const { data, total,mission } = submittedHomeworks;
  const history = useHistory();
  const { classId } = useParams();
  const [{ nowPage, rowsPage }, setState] = useSetState({
    nowPage: 0,
    rowsPage: 10,
  });

  const goToStudentSubmittedHomeWorkInfo = id => {
    history.push(`/home/${classId}/homeworks/submitted/${id}`);
  };

  const StudentUrl = ({ name, id }) => (
    <UiStudentLink onClick={(e) => {
      e.preventDefault();
      goToStudentSubmittedHomeWorkInfo(id);
    }}>
      {name}
    </UiStudentLink>
  );


  const submissionsFormat = data => {
    return data.map((item, index) => ({
      ...item,
      ownerName: <StudentUrl name={item.ownerName} id={item.ownerId} />,
      submitStatus: SUBMIT_STATUS[item.submitStatus],//item.submittedAt ? '已繳交' : '未繳交',
      submittedAt: item.submittedAt && formatTimestamp(item.submittedAt, 'yyyy-MM-dd HH:mm:ss'),
      attachments: item.attachments.length > 0 &&
        <AttachmentsBox data={item.attachments} fileName={`${mission.title}_${item.ownerName}_${item.submitCount}`} />
    }));
  };

  const isLate = (submitStatus, conditionData) => {
    if (submitStatus === SUBMIT_STATUS.lateSubmit) return true;
    return false;
  };

  useEffect(() => {
    getSubmittedHomeworks({ nowPage, rowsPage });
  }, [nowPage, rowsPage]);

  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };

  const goHomeList = () => {
    history.push(`/home/${classId}/homeworks/`);
  };

  return (
    <UiTable>
      <UiActionBox>
        <UiflexBox>
          <Button onClick={goHomeList}>返回作業列表</Button>
        </UiflexBox>
      </UiActionBox>
      <Table
        data={submissionsFormat(data)}
        schema={schema}
        changePage_Rows={changePage_Rows}
        totalPage={total}
        nowPage={nowPage}
        dataConditionFormat={isLate}
      />
    </UiTable>
  );
};
