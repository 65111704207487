import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Icon, Table, } from 'components';
import { format } from 'date-fns';
import { useClass } from 'store/class';
import {
  UiSessionPerformance,
  UiTitleWrapper,
  UiTableWrapper,
  UiPerformanceForUser,
  UiPerformanceCard,
  UiPerformanceCardTitle,
  UiPerformanceCardIcon,
  UiPerformanceCardContent,
  UiPerformanceCardAverage,
} from './SessionPerformance.style';

/**
 * 學生課堂表現
 */

/* 假資料 */
// const data = [{
//   attendeeNumber: 1,
//   nickname: '莫札特',
//   trophy: 90,
//   raiseHand: 6,
//   speakerSecond: '01:20',
//   inattentive: 2,
// }];

const schema = {
  attendeeNumber:{
    name: '座號',
    defaultValue: '-',
  },
  nickname:{
    name: '姓名',
    defaultValue: '-',
  },
  trophy:{
    name: '總獎盃數',
    defaultValue: '-',
  },
  raiseHand:{
    name: '舉手次數',
    defaultValue: '-',
  },
  // speakerSecond:{
  //   name: '發言秒數',
  //   defaultValue: '-',
  // },
  inattentive:{
    name: '不專心次數',
    defaultValue: '-',
  },
};

export const SessionPerformance = (props) => {
  const [{ myClasses: { dataInfo: { userType } } }] = useClass();
  const isTeacher = userType === 'teacher' ? true : false;
  const { userRecords, userRecordAverages, users } = props?.data;

  /* 學生所有紀錄 */
  const studentRecords = (() => {
    const zip = users.map(user => {
      // 學生身份之資料處理用
      let record = { status: 'missed' };
      userRecords.forEach(userRecord => {
        record = (user.id === userRecord.userId) ? userRecord : record;
      });

      return {...user, ...record};
    });

    const records = zip.map(value => {
      const { attendeeNumber, inattentive, nickname, raiseHand, speakerSecond, status, trophy } = value;

      const obj = status  === 'missed' 
        ? {
            trophy: '-',
            raiseHand: '-',
            speakerSecond: '-',
            inattentive: '-'
          }
        : {
            trophy,
            raiseHand,
            speakerSecond: format(speakerSecond, 'mm:ss'),
            inattentive
          };

      return {
        attendeeNumber,
        nickname,
        ...obj
      };
    });

    return records;
  });

  /* 學生平均紀錄 */
  const averageRecords = (() => {
    const { 
      attendeeNumber = '-', 
      inattentive, 
      nickname = '班級平均', 
      raiseHand, 
      speakerSecond, 
      trophy
    } = userRecordAverages;

    return {
      attendeeNumber,
      nickname,
      trophy,
      raiseHand,
      speakerSecond: speakerSecond ? format(speakerSecond, 'mm:ss') : '-',
      inattentive
    };
  });

  const tableData = useMemo(() => {
    return [...studentRecords(), averageRecords()];
  }, [userRecords, userRecordAverages, users]);

  return (
    <UiSessionPerformance>
      <UiTitleWrapper>
        {
          isTeacher
            ? '學生課堂表現'
            : '課堂表現'
        }
      </UiTitleWrapper>

      {
        isTeacher
          ? /* 表格 */
            <UiTableWrapper>
              <Table
                schema={schema}
                data={tableData}
                isNeedPagination={false}
              />
            </UiTableWrapper>
          : /* 學生表現 */
            <UiPerformanceForUser>
              <UiPerformanceCard>
                <UiPerformanceCardTitle>
                  <span>獎盃數</span>
                  <UiPerformanceCardIcon className="trophy">
                    <Icon.Svg name="Trophy" />
                  </UiPerformanceCardIcon>
                </UiPerformanceCardTitle>
                <UiPerformanceCardContent>{userRecords[0].trophy}</UiPerformanceCardContent>
                <UiPerformanceCardAverage>班級平均值 {userRecordAverages.trophy}</UiPerformanceCardAverage>
              </UiPerformanceCard>
              <UiPerformanceCard>
                <UiPerformanceCardTitle>
                  <span>不專心次數</span> 
                  <UiPerformanceCardIcon className="running">
                    <Icon.Svg name="Running" />
                  </UiPerformanceCardIcon>
                </UiPerformanceCardTitle>
                <UiPerformanceCardContent>{userRecords[0].inattentive}</UiPerformanceCardContent>
                <UiPerformanceCardAverage>班級平均值 {userRecordAverages.inattentive}</UiPerformanceCardAverage>
              </UiPerformanceCard>
              <UiPerformanceCard>
                <UiPerformanceCardTitle>
                  <span>舉手次數</span> 
                  <UiPerformanceCardIcon className="raisehand">
                    <Icon.Svg name="Raisehand" />
                  </UiPerformanceCardIcon>
                </UiPerformanceCardTitle>
                <UiPerformanceCardContent>{userRecords[0].raiseHand}</UiPerformanceCardContent>
                <UiPerformanceCardAverage>班級平均值 {userRecordAverages.raiseHand}</UiPerformanceCardAverage>
              </UiPerformanceCard>
              {/* 發言時間 */}
              {/* <UiPerformanceCard>
                <UiPerformanceCardTitle>
                  <span>發言時間</span>
                  <UiPerformanceCardIcon className="messageWaveform">
                    <Icon.Svg name="MessageWaveform" />
                  </UiPerformanceCardIcon>
                </UiPerformanceCardTitle>
                <UiPerformanceCardContent>
                  {format(userRecords[0].speakerSecond, 'mm:ss')}
                </UiPerformanceCardContent>
                <UiPerformanceCardAverage>
                  班級平均值
                  {format(userRecordAverages.speakerSecond, 'mm:ss')}
                </UiPerformanceCardAverage>
              </UiPerformanceCard> */}
            </UiPerformanceForUser>
      }

    </UiSessionPerformance>
  );
};

SessionPerformance.propTypes = {
  data: PropTypes.shape({
    userRecords: PropTypes.object,
    userRecordAverage: PropTypes.object,
    users: PropTypes.object,
  }),
};

