import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Calendar from '../Calendar';
import SessionModal from '../SessionModal';
import {
  UiBody,
  UiCategoryWrapper,
  UiCategory,
  UiCategoryColor,
  UiCategoryName,
  UiClose,
  UiHeader,
  UiModalContent,
  UiMuiCalendarModal,
  UiTitle,
} from './CalendarModal.style';
import { 
  Fade as MuiFade,
  useMediaQuery
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Icon } from 'components';
import { getDurationText, getGradesText, getEduSubjectsText } from 'helper/openCourse';
import { getPublished as getPublishedApi } from 'services/api/courses';

const title = '課程行事曆';
const categories = [
  {
    name: '自習教室',
    color: '#EC7963',
  },
  {
    name: '解題教室',
    color: '#FACD61',
  }
];

/* 自習教室顏色表 */
const selfStudy = {
  // backgroundColor: '#FEF2F0',
  // borderColor: '#EC7963',
  // textColor: '#D26855',
  backgroundColor: '#EC7963',
  borderColor: '#EC7963',
};

/* 解題教室顏色表 */
const problemSolving = {
  // backgroundColor: '#FFFAEF',
  // borderColor: '#FACD61',
  // textColor: '#BC8531',
  backgroundColor: '#FACD61',
  borderColor: '#FACD61',
};


export const CalendarModal = ({ onClose, open }) => {
  const theme = useTheme();
  const isPad = useMediaQuery(theme.breakpoints.down('sm'));
  const isLessThanCalendarHeight = useMediaQuery('(max-height:920px)');
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openCoursesOri, setOpenCoursesOri] = useState([]); // 會員公開課 API 資料
  const [session, setSession] = useState({});

  /**
   * calendar 顯示用資料
   */
   const events = useMemo(() => {
    if (!openCoursesOri.length) return;
    const eventMap = new Map();

    // format API 資料
    openCoursesOri.forEach(course => {
      const { classification, endAt, title, startAt } = course;
      const key = `${startAt}-${endAt}`;
      const value = {
        end: endAt,
        title,
        start: startAt,
      };

      // 設定 calendar event 顏色
      const setColor = (category) => {
        value.backgroundColor = category.backgroundColor;
        value.borderColor = category.borderColor;
        value.textColor = category.textColor;
      };
      if (classification === '自習教室') setColor(selfStudy);
      else if (classification === '解題教室') setColor(problemSolving);

      // 將 value 設定至 map 對應的 key
      eventMap.has(key)
        ? eventMap.get(key).push(value)
        : eventMap.set(key, [value]);
    });

    // 回傳 calendar events 需要的資料
    return [...eventMap.keys()].reduce((prev, curr) => {
      prev.push(...eventMap.get(curr));
      return prev;
    }, []);
  }, [openCoursesOri]);

  /**
   * click calendar event
   * @param {object} info calendar event info
   */
  const eventClick = (info) => {
    setIsOpen(true);
    const { title } = info.event;
    const index =  openCoursesOri.findIndex((openCourse) =>
      openCourse.title === title
    );
    const durationText = getDurationText(openCoursesOri[index]);
    const eduSubjectsText = getEduSubjectsText(openCoursesOri[index]);
    const gradesText = getGradesText(openCoursesOri[index]);
    const newSession = { ...openCoursesOri[index], durationText, eduSubjectsText, gradesText };
    setSession(newSession);
  };

  /**
   * 打 API，取得會員公開課
   * @param {object} calendarView calendar view 物件
   */
  const fetchOpenCourses =  async (calendarView) => {
    setIsLoading(true);
    const { currentEnd, currentStart } = calendarView;
    const endAt = new Date(currentEnd).getTime();
    const startAt = new Date(currentStart).getTime();
    const params = { endAt, startAt };
    const response = await getPublishedApi(params);
    const { status } = response;
    if (status === 'success') {
      const { data } = response;
      setOpenCoursesOri(data);
    }
    setIsLoading(false);
  };

  /**
   * 設定 calendar events
   * @param {object} calendarView calendar view 物件
   */
  const setEvents = (calendarView) => {
    fetchOpenCourses(calendarView);
  };

  return (
    <>
      <SessionModal
        isOpen={isOpen}
        onClose={()=>setIsOpen(false)}
        session={session}
      />

      <UiMuiCalendarModal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={open}
        onClose={onClose}
      >
        <MuiFade in={open}>
          <UiModalContent isPad={isPad} isLessThanCalendarHeight={isLessThanCalendarHeight}>
            <UiHeader>
              <UiTitle>{title}</UiTitle>
              <UiClose>
                <Icon
                  haveBg={false}
                  icolor='#A1A4B1'
                  name='close'
                  onClick={onClose}
                />
              </UiClose>
              <UiCategoryWrapper>
                {
                  categories.map(({ name, color }, index) => 
                    <UiCategory key={index}>
                      <UiCategoryColor background={color} />
                      <UiCategoryName>{name}</UiCategoryName>
                    </UiCategory>
                  )
                }
              </UiCategoryWrapper>
            </UiHeader>
            <UiBody>
              <Calendar
                events={events}
                eventClick={eventClick}
                loading={isLoading}
                setEvents={setEvents}
              />
            </UiBody>
          </UiModalContent>
        </MuiFade>
      </UiMuiCalendarModal>
    </>
  );
};

CalendarModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
