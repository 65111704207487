import React,{ useEffect,useMemo } from 'react';
import { Table, Icon,IconInput, BsModal, Button, CodeInput,InlineSelect } from 'components';
import { Tab,Box, } from '@material-ui/core';
import { useSetState } from 'utils/hooks/useSetState';
import { getPrivateResources,getOrder,getPrivateQuizzes,getQuizzesOptions } from 'services/api/home/users';
import {
  UiHomeSupplementaryMaterialsTable,
  UiMatTabs,
  UiModalContent,
  UiModalSuccessContent,
  UiCategories,
  UiCategory,
  UiFlexBox,
  UiNoContent,
 } from './HomeSupplementaryMaterialsTable.style';
 import frameImage from 'assets/images/frame.png';


/**
 * 輔助教學資源頁面
 */

const ORDER_STATUS = {
  INIT:'init',
  ERROR:'error',
  SUCCESS:'success'
};

const CATEGORIES = {
  TEST:'test',
  BOOK:'book'
};

export const HomeSupplementaryMaterialsTable = () => {
  const [{
    tabIndex,
    nowPage,
    rowsPage,
    isLoading,
    isOpen,
    code,
    resources,
    total,
    orderStatus,
    orderText,
    codePackages,
    category,
    quizzes,
    quizzesTotal,
    testNowPage,
    testRowsPage,
    subjectOptions,
    productInfoOptions,
    subjectCode,
    productCode,
    keyword,
   }, setState] = useSetState({
    tabIndex: 0,
    nowPage: 0,
    rowsPage: 10,
    isLoading: false,
    isOpen: false,
    code:'',
    resources:[],
    total:10,
    orderStatus:  ORDER_STATUS.INIT,
    orderText:'請輸入6位數教材領取碼',
    codePackages:[],
    category:CATEGORIES.BOOK,
    quizzes:[],
    quizzesTotal:0,
    testNowPage:0,
    testRowsPage:10,
    subjectOptions:[],
    productInfoOptions:[],
    subjectCode:'',
    productCode:'',
    keyword:''
   });

  const schema = {
    displayName: {
      name: '檔案名稱',
      defaultValue: '-'
    }
  };

  const quizzesSchema = {
    productInfoText: {
      name: '品項名稱',
      defaultValue: '-'
    },
    subjectText: {
      name: '科目',
      defaultValue: '-'
    },
    productNumberText: {
      name: '回數',
      defaultValue: '-'
    }
  };

  const getTabIndexHandler = (e,tabIndex) => {
    setState({
      tabIndex
    });
  };

  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };

  const changeQuizzesPage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      testNowPage: newPage,
      testRowsPage: newRowsPage
    });
  };

  const onChangeCategory = target => {
    setState({
      category:target
    });
  };

  const ActionComponents = ({ params }) => {
    const checkBook = () => {
      const classRoomUrl = `${process.env.REACT_APP_ONEBOOK_DOMAIN}/${params.resourceId}`;
      window.open(classRoomUrl, '_blank');
    };
    return (
      <Button buttonColor="cancel" onClick={checkBook}>查看教材</Button>
    );
  };

  const ActionQuizzesComponents = ({ params }) => {
    const checkBook = () => {
      window.open(params.previewUrl, '_blank');
    };
    return (
      <Button buttonColor="cancel" onClick={checkBook}>查看教材</Button>
    );
  };

  const onModalOk = async () => {
    if(code.length !== 6) return;
    setState({
      isLoading: true
    });
    const { isSuccess, data ,error } = await getOrder(code);
    if(isSuccess){
      const { packages } = data;
      const books = [];
      packages.forEach(proPackage=>{
        proPackage.items.forEach(item=>{
          books.push(item);
        });
      });
      setState({
        orderStatus: ORDER_STATUS.SUCCESS,
        isLoading: false,
        codePackages:books
      });

      const params = {
        nowPage,
        rowsPage
      };

      await fetchResources(params);

      const quizzesParams = {
        nowPage:testNowPage,
        rowsPage:testRowsPage,
        subjectCode,
        productCode
      };

      await fetchQuizzes(quizzesParams);

    }else{
      const { errorCode } = error;
      let text = '未知錯誤請重新再試';
      if(errorCode === 170010) {
        text = '該序號不存在';
      }else if(errorCode === 70002 || errorCode === 170011) {
        text = '該序號已被使用';
      }
      setState({
        orderStatus: ORDER_STATUS.ERROR,
        orderText: text,
        isLoading: false
      });
    }
  };

  const modalToggle = () => {
    setState({
      orderStatus: ORDER_STATUS.INIT,
      orderText: '請輸入6位數教材領取碼',
    });
    setState({
      isOpen : !isOpen,
    });
  };

  const onCodeChange = value => {
    setState({
      code:value
    });
  };

  const onChangeHandler = (value,key)  => {
    setState({
      [key]:value
    });
  };

  const selectChangeHandler = (value,key) => {
    if(key === 'subjectCode') {
      setState({
        productCode:''
      });
    }
    setState({ [key]: value });
  };

  const fetchResources = async (params) => {
    const { data,isSuccess } = await getPrivateResources(params);
    if(isSuccess){
      const resourcesData = data.resources.map(resource=>{
        return {
          ...resource,
          isCompressImageText: resource.compressImage ? '是' : '否',
          svgTypeText: resource.svgType === 'svg_realtext' ? '是' : '否'
        };
      });
      setState({
        resources: resourcesData,
        total: data.total
      });
    }else{
      setState({
        resources: [],
        total:0
      });
    }
  };

  const fetchQuizzes = async (params) => {
    const { data,isSuccess } = await getPrivateQuizzes(params);
    if(isSuccess){
      const quizzesData = data.quizzes.map(quiz=>{
        return {
          ...quiz,
          subjectText:quiz.subject.name,
          productInfoText:quiz.productInfo.name,
          productNumberText:quiz.productNumber.name
        };
      });
      setState({
        quizzes: quizzesData,
        quizzesTotal: data.total,
      });
    }else{
      setState({
        quizzes: [],
        quizzesTotal:0,
      });
    }
  };

  const fetchQuizzesOptions = async () => {
    const { data:{ subjects,subjectProducts } } = await getQuizzesOptions();
    const defaultOption = [
      {
        name:'全部科目',
        value:''
      }
    ];
    const subjectData = defaultOption.concat(subjects.map(subject=>{
      return {
        name:subject.name,
        value:subject.code
      };
    }));
    setState({
      subjectOptions:subjectData,
      productInfoOptions:subjectProducts,
    });
  };

  const productInfoOptionsData = useMemo(()=>{
    const defaultProdOption = [
      {
        name:'全部品項',
        value:''
      }
    ];
    if(!subjectCode || !productInfoOptions) return defaultProdOption;

    const productInfoData = defaultProdOption.concat(productInfoOptions[subjectCode].map(product=>{
      return {
        name:product.name,
        value:product.code
      };
    }));
    return productInfoData;
  },[subjectCode]);


  useEffect(() => {
    const params = {
      nowPage,
      rowsPage,
      keyword
    };

    fetchResources(params);
  }, [nowPage,rowsPage,keyword]);

  useEffect(()=>{
    const params = {
      nowPage:testNowPage,
      rowsPage:testRowsPage,
      subjectCode,
      productCode
    };
    fetchQuizzes(params);
  },[testNowPage,testRowsPage,subjectCode,productCode]);

  useEffect(()=>{
    fetchQuizzesOptions();
  },[]);

  return (
    <UiHomeSupplementaryMaterialsTable>
      <div className="flex">
        <h4>教學資源</h4>
        <Button iconSvg="Gift" onClick={modalToggle}>領取教材</Button>
      </div>
      <UiMatTabs
        value={tabIndex}
        onChange={getTabIndexHandler}
      >
        <Tab label="我的教材" />
      </UiMatTabs>
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <UiCategories>
          <UiCategory
            className={category === CATEGORIES.BOOK ? 'active':''}
            onClick={()=> onChangeCategory(CATEGORIES.BOOK)}>
              電子書
          </UiCategory>
          <UiCategory
            className={category === CATEGORIES.TEST ? 'active':''}
            onClick={()=> onChangeCategory(CATEGORIES.TEST)}>
            卷類資源
          </UiCategory>
        </UiCategories>
        {
          (category === CATEGORIES.BOOK && resources.length > 0) &&
          <IconInput
            placeholder="搜尋"
            onClick={value => onChangeHandler(value, 'keyword')}
          />
        }
        {
         ( category === CATEGORIES.TEST && quizzes.length > 0) &&
          <UiFlexBox>
            <InlineSelect
              label="科目"
              placeholder="科目"
              options={subjectOptions}
              value={subjectCode}
              onChange={ value => selectChangeHandler( value,'subjectCode')}
            />
            <InlineSelect
              label="品項"
              placeholder="品項"
              options={productInfoOptionsData}
              value={productCode}
              disabled={subjectCode === ''}
              onChange={ value => selectChangeHandler( value ,'productCode')}
            />
          </UiFlexBox>
        }
      </Box>
      {
        category === CATEGORIES.BOOK ?
        (resources.length > 0 ?
        <Table
          data={resources}
          schema={schema}
          changePage_Rows={changePage_Rows}
          totalPage={total}
          ActionComponents={ActionComponents}
          nowPage={nowPage}
        /> :
          <UiNoContent>
            <img src={frameImage} alt="noContent" />
            <div>
              這裡目前沒有教材<br/>
              請洽各區南一業務購買
            </div>
          </UiNoContent>
        ) :
        (
          quizzes.length > 0 ?
          <Table
            data={quizzes}
            schema={quizzesSchema}
            changePage_Rows={changeQuizzesPage_Rows}
            totalPage={quizzesTotal}
            ActionComponents={ActionQuizzesComponents}
            nowPage={testNowPage}
          />:
          <UiNoContent>
            <img src={frameImage} alt="noContent" />
            <div>
              這裡目前沒有考卷<br/>
              請洽各區南一業務購買
            </div>
          </UiNoContent>
        )
      }
      <BsModal
        open={isOpen}
        title={orderStatus === ORDER_STATUS.SUCCESS ? '領取教材成功！': '領取教材'}
        onOk={onModalOk}
        onCancel={modalToggle}
        okText="領取"
        isLoading={isLoading}
        oKDisplay={orderStatus !== ORDER_STATUS.SUCCESS}
        cancelText={orderStatus === ORDER_STATUS.SUCCESS ? '關閉' : '取消'}
        >
        {orderStatus === ORDER_STATUS.SUCCESS ?
        <UiModalSuccessContent>
          <div className="title">您領取的教材如下：</div>
          <ul>
            {
              codePackages.filter((codePackage) => codePackage.itemName).map((codePackage,index)=>{
                return (
                  <li key={index}>
                    {index + 1}. {codePackage.itemName}
                  </li>
                );
              })
            }
          </ul>
          <div className="remind">
            <div>
              <Icon.Svg color="#F9C74F" name="Lightbulb"/>
            </div>
            <span>
              領取內容請至我的教材查看<br/>
              考卷可在「線上派卷」中使用；電子書教材可在「課程」中使用
            </span>
          </div>
        </UiModalSuccessContent>:
        <>
          <CodeInput
            length={6}
            onChange={onCodeChange}
            separator={''}
            isError={orderStatus === ORDER_STATUS.ERROR}
          />
          <UiModalContent isError={orderStatus === ORDER_STATUS.ERROR}>{orderText}</UiModalContent>
        </>
        }
      </BsModal>
    </UiHomeSupplementaryMaterialsTable>
  );
};
