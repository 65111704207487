import React, { useEffect } from 'react';
import lrz from 'lrz';

import { PostCard, Loading, Icon, PostModal, Button } from 'components';
import { useFirebaseStorage } from 'utils/hooks/useFirebaseStorage';
import { useUser } from 'store/user';
import { useClass } from 'store/class';
import { useAlert } from 'utils/hooks/useAlert';
import { useParams } from 'react-router-dom';
import { useSetState } from 'utils/hooks/useSetState';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';
import { usePosts } from 'store/posts';
import { ORGANIZATION_SETTINGS_VALUE } from 'constants/index';
import { useCalTime } from 'utils/hooks/useCalTime';
import {
  UiHomePasteTable,
  UiCardBox,
  UiPostActionBox,
  UiPostFlexBox,
  UiPostButton,
  UiNoDataBox,
  UiPictureBox,
  UiUiNoDataText,
  UiNoDataButton,
} from './HomePasteTable.style';
import defaultAvatarImage from 'assets/images/Avatar_Member_Default.png';
import emptyImage from 'assets/images/empty_post.png';


export const HomePasteTable = () => {
  const [{ myClasses }] = useClass();
  const { classId } = useParams();
  const [calTime] = useCalTime();
  const { setAlert } = useAlert();
  const [{ myOrganization, profile: { id, nickname, thumbnailUrl } }] = useUser();
  const [{ postsList },
    { getPostList,
      createPost,
      updatePost,
      deletePost,
      likePost,
      undoLikePost,
      replyPost,
      updateReplyPost,
      getPostReplies,
    }] = usePosts();
  const { upload } = useFirebaseStorage();

  const [{
    userId,
    userType,
    nowPage,
    rowsPage,
    PostsDataList,
    listLoading,
    hasNextPage,
    modalState,
    editModalState,
    isAdmin,
    isLoading,
    userNickname,
    userAvatar,
    isHidden,
  }, setState
  ] = useSetState({
    userId: '',
    userType: '',
    nowPage: 0,
    rowsPage: 5,
    PostsDataList: [],
    listLoading: false,
    hasNextPage: true,
    reset: false,
    modalState: false,
    editModalState: false,
    isAdmin: false,
    isLoading: false,
    isDisabled:false,
    userNickname: '',
    userAvatar: '',
    isHidden: false,
  });

  //滾動加載觸發後事件
  const handleLoadMore = async () => {
    setState({
      listLoading: true
    });
    const page = await getPostList({ nowPage, rowsPage });
    if(!page){
      setState({
        listLoading: false,
        hasNextPage: false,
      });

      return;
    }
    const pageLength = page.posts && page.posts.length;
    let isHasNextPage = ((nowPage * rowsPage) + pageLength) >= page.total ? false : true;

    setState({
      PostsDataList:
        PostsDataList.length > 0 ?
          PostsDataList.concat(postsList.data) :
          postsList.data,
      listLoading: false,
      nowPage: nowPage + 1,
      hasNextPage: isHasNextPage
    });
  };

  //刪除
  const onDeleteHandler = async data => {
    let type = '';
    if (userId === data.ownerId) {
      type = 'delete';
    } else if (userType === 'teacher') {
      type = 'ban';
    }
    await deletePost({ type, ...data });
  };
  //按讚
  const onLikePostChageHandler = async (doLike, postId) => {
    if (doLike) {
      await likePost(postId);
    } else {
      await undoLikePost(postId);
    }
  };

  //滾動加載trigger
  const infiniteRef = useInfiniteScroll({
    loading: listLoading,
    hasNextPage,
    onLoadMore: handleLoadMore
  });
  //權限 組織名取得
  useEffect(() => {
    const { dataInfo: { isOwner, userType, isHidden } } = myClasses;
    if (myOrganization.isLoaded && myOrganization.userProfile) {
      let targetThumbnailUrl = '';
      const {
        userProfile: { nickname: OrgNickname, thumbnailUrl: OrgThumbnailUrl,role },
        organization:{ staffProfileOriginSetting, customerProfileOriginSetting } } = myOrganization;
      if(role === 'staff'){
        targetThumbnailUrl =
          staffProfileOriginSetting === ORGANIZATION_SETTINGS_VALUE.DEFAULT ? thumbnailUrl : OrgThumbnailUrl;
      }else{
        targetThumbnailUrl =
          customerProfileOriginSetting === ORGANIZATION_SETTINGS_VALUE.DEFAULT ? thumbnailUrl : OrgThumbnailUrl;
      }
      setState({
        isAdmin: isOwner,
        userType,
        userId: id,
        isHidden,
        userNickname: OrgNickname,
        userAvatar: targetThumbnailUrl,
      });
    } else {
      setState({
        isAdmin: isOwner,
        userType,
        userId: id,
        isHidden,
        userNickname: nickname,
        userAvatar: thumbnailUrl,
      });
    }


  }, [myOrganization, myClasses]);

  //新增貼文彈窗
  const openModal = () => {
    setState({
      modalState: true
    });
  };

  const closeModal = () => {
    setState({
      modalState: false,
      editModalState: false,
    });
  };

  const getEditModalState = state => {
    setState({
      editModalState: state
    });
  };

  const getModalState = state => {
    setState({
      modalState: state
    });
  };

  //上傳檔案
  const onUploaded = async file => {
    if (!file.type) return;
    const uploadPath = `${classId}/posts/${id}/${Date.now()}${file.name}`;
    const { status, url } = await upload(uploadPath, file);

    if (status) {
      return url;
    } else {
      return false;
    }
  };

  const onCreateSubmit = async createData => {
    const { files, content } = createData;
    if(content === '' && files.length === 0) return;
    setState({
      isLoading: true
    });
    await Promise.all(files.map(async file => {
      const response = await onUploaded(file.value);
      if (!response) throw response;
      return {
        attachmentUrl: response,
        attachmentName: file.name,
        attachmentType: file.type
      };
    })).then(async filesData => {
      if(filesData.length !== 0){
        setAlert('上傳檔案成功!', 'success');
      }
      const payload = {
        content,
        attachments: filesData
      };
      const isSuccess = await createPost(payload);
      if (isSuccess) {
        closeModal();
      }
    }).catch(() => {
      setAlert('上傳檔案失敗!', 'wrarning');
    });
    setState({
      isLoading: false
    });
  };

  const onEditHandler = async editData => {
    const { postId, files, editFiles, content } = editData;
    const nextEditFiles = editFiles.map(item => {
      return {
        attachmentUrl: item.path,
        attachmentName: item.name,
        attachmentType: item.type
      };
    });
    if (files.length > 0) {
      Promise.all(files.map(async file => {
        const response = await onUploaded(file.value);
        if (!response) throw response;
        return {
          attachmentUrl: response,
          attachmentName: file.name,
          attachmentType: file.type
        };
      })).then(async filesData => {
        if(filesData.length !== 0){
          setAlert('上傳檔案成功!', 'success');
        }
        const payload = {
          content,
          attachments: [...nextEditFiles, ...filesData]
        };
        const isSuccess = await updatePost(postId)(payload);
        if (isSuccess) {
          closeModal();
        }
        return isSuccess;
      }).catch(() => {
        setAlert('上傳檔案失敗!', 'wrarning');
      });
    } else {
      const payload = {
        content,
        attachments: [...nextEditFiles]
      };
      const isSuccess = await updatePost(postId)(payload);
      if (isSuccess) {
        closeModal();
      }
      return isSuccess;
    }

  };

  const uploadFileFormat = async file => {
    const response = await onUploaded(file);
    const attachments = {};
    attachments.attachmentUrl = response;
    attachments.attachmentName = file.name;
    attachments.attachmentType = file.type;
    return attachments;
  };

  const isNeedCompressedImage = async file => {
    let attachments = '';
    if (file.type.split('/')[0] === 'image') {
      await lrz(file).then(async res => {
        for (let value of res.formData.values()) {
          attachments = await uploadFileFormat(value);
        }
      });
    } else {
      attachments = await uploadFileFormat(file);
    }
    return attachments;
  };

  //回覆貼文func
  const replieMessage = async (params, id) => {
    const { content, files } = params;
    const payload = {
      content
    };
    if (files.length > 0) {
      const attachments = await isNeedCompressedImage(files[0]);
      payload.attachments = [attachments];
    }

    await replyPost(id)(payload);
  };

  const editReplieMessage = async (params, id) => {
    const { content, existFiles, files } = params;
    const payload = {
      content
    };
    if (existFiles.length === 0 && files.length === 0) {
      payload.attachments = [];
    }
    if (existFiles.length === 0 && files.length > 0) {
      const attachments = await isNeedCompressedImage(files[0]);
      payload.attachments = [attachments];
    }

    await updateReplyPost(id)(payload);
  };

  //取得先前留言
  const getPrevMessage = async (params, id) => {
    await getPostReplies(id)(params);
  };

  return (
    <UiHomePasteTable ref={infiniteRef}>
      {
        (postsList.data.length <= 0 && !listLoading) ? <></> :
          ((isHidden && isAdmin) || !isHidden) &&
          <UiPostActionBox onClick={() => openModal('create')}>
            <div>{userNickname}, 在想些什麼？</div>
            <UiPostFlexBox>
              <UiPostButton type="Picture"><Icon.Svg name='Picture' />相片</UiPostButton>
              <UiPostButton type="Video"><Icon.Svg name='Video' />影片</UiPostButton>
              <UiPostButton type="File"><Icon.Svg name='File' />附件</UiPostButton>
            </UiPostFlexBox>
          </UiPostActionBox>
      }

      {
        (postsList.data.length <= 0 && !listLoading) && <UiNoDataBox>
          <UiPictureBox path={emptyImage} />
          <UiUiNoDataText>班級還沒有貼文，趕快成為第一個發佈貼文的人吧！</UiUiNoDataText>
          <UiNoDataButton>
            <Button onClick={() => openModal('create')}>發佈貼文</Button>
          </UiNoDataButton>
        </UiNoDataBox>
      }
      <UiCardBox>
        {postsList.data && postsList.data.map(item => {
          const time = (item.publishedAt) && calTime(item.publishedAt);
          const canDelete = (userId === item.ownerId || isAdmin) ? true : false;
          const canEdit = (userId === item.ownerId) ? true : false;
          const isLike = item.likedUsers && item.likedUsers.some(user => user.userId === id);
          if (!item.isBanned)
            return (
              <PostCard
                key={item.id}
                data={item}
                isLike={isLike}
                onDelete={onDeleteHandler}
                onEdit={onEditHandler}
                canDelete={canDelete}
                canEdit={canEdit}
                time={time}
                isAdmin={isAdmin}
                onLikePostChage={onLikePostChageHandler}
                replieMessage={replieMessage}
                editReplieMessage={editReplieMessage}
                getPrevMessage={getPrevMessage}
                avatarSrc={userAvatar || defaultAvatarImage}
                editModalState={editModalState}
                getEditModalState={getEditModalState}
              />
            );
        })}
      </UiCardBox>
      {listLoading && <Loading />}
      <PostModal
        isLoading={isLoading}
        isOpen={modalState}
        getModalState={getModalState}
        onSubmit={onCreateSubmit}
        avatarSrc={userAvatar || defaultAvatarImage}
        name={userNickname}
        dialogContent={<span>你尚未分享貼文<br/>確定要離開頁面且不發佈貼文</span>}
      />
    </UiHomePasteTable>
  );
};
