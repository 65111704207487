import styled from 'styled-components';

export const UiSessionRecordWrapper = styled.div`
    margin-bottom: 64px;
`;

export const UiTitleWrapper = styled.div`
    color: #242C3F;
    font-family: Noto Sans CJK TC;
	font-size: 1.6rem;
	font-weight: 400;
	margin-bottom: 18px;
`;

export const UiTableWrapper = styled.div`
    display: flex;
    overflow-x: auto;
    padding-top: 30px;

    ::-webkit-scrollbar{ height: 6px };
    ::-webkit-scrollbar-thumb{
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.3);
    }
`;

export const UiTimeLineContainer = styled.div`
    padding: 8px 0;
`;

export const UiTimeLineRow = styled.div`
    display: flex;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);

    &.userHead{
        border-bottom: none;
        > div:first-child {
            border-top-left-radius: 30px;
        }

        > div:last-child {
            border-top-right-radius: 30px;
        }
    }
    
    &.userBody{
        border-bottom: none;
        > div:first-child{
            border-bottom-left-radius: 30px;
        }
        > div:nth-last-child(2){
            border-bottom-right-radius: 30px;
        }
    }
`;

export const UiTimeLineRail = styled.div`
    height: 8px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FBE4E0;
    margin: auto;
`;

export const UiTimeLineTrack = styled.div`
    height: inherit;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    background: #EC7963;

    left: ${({left}) => `${left}px` };
	width: ${({right, left}) =>`${right - left}px`};
`;

export const UiTimeLineBlock = styled.div`
    color: #242C3F;
    font-size: 1rem;
    font-weight: 500;
    padding: 16px;
    text-align: center;
    position: relative;

    height: ${({ height }) => height };
    width: ${({ width }) => width || '100px' };
    background: ${({ bgc }) => bgc || '#FAFAFC'};

    :nth-child(2), :nth-child(3n + 5) {
        width: ${({ width }) => width || '216px' };
        border-left: 1px solid rgb(249, 199, 79);
        border-right: 1px solid rgb(249, 199, 79);
    }

    &.head {
        height: 53.9px;

        @media screen and (max-width: 1280px) {
            height: 52.5px;
        }
    }

    &.body {
        height: 50.5px;

        @media screen and (max-width: 1280px) {
            height: 49.5px;
        }
    }

    &.userBody {
        height: 200px;
    }

    &.userBody, &.userHead {
        width: 250px;

        :nth-child(2), :nth-child(3n + 5) {
            width: 754px;
            border-left: none;
            border-right: none;
        }
    }
`;

export const UiTimeText = styled.div`
    color: #242C3F;
    font-size: 1rem;
    font-weight: 600;
    line-height: 30px;
    position: absolute;
    top: -30px;

    :nth-child(1){ left: -20px };
    :nth-child(2){ left: calc(100% - 20px) };
`;

export const UiRecordUser = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 15px;
`;

export const UiRecordUserIcon = styled.div`
	margin-right: 10px;
    height: 16px;
    width: 16px;
    background: #EC7963;
    border-radius: 50%;
`;

export const UiRecordUserText = styled.div`
    margin-right: 20px;
`;
