import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TextField as MatTextField } from '@material-ui/core';

import { Form, Modal } from 'components';
import { useSetState } from 'utils/hooks/useSetState';
import { usePosts } from 'store/posts';
import { format } from 'date-fns';


import { UiHomePostForm, UiDateTitle } from './HomePostForm.style';


/**
 * 新增編輯貼文表單
 */
const schema = {
  title: {
    component: MatTextField,
    elementProps: {
      label: '貼文標題',
      placeholder: '請輸入貼文標題',
      helperText: '',
      variant: 'outlined'
    },
    rules: {
      required: {
        value: true,
        message: '本欄位為必填'
      }
    }
  },
  content: {
    component: MatTextField,
    elementProps: {
      label: '貼文內容',
      placeholder: '請輸入貼文內容',
      variant: 'outlined',
      helperText: '',
      multiline: true,
      rows: 20
    },
    rules: {
      required: {
        value: true,
        message: '本欄位為必填'
      }
    }
  }
};

export const HomePostForm = () => {
  const history = useHistory();
  const { classId } = useParams();

  const [, { createPost, clearPostList }] = usePosts();

  const [{ isModalOpen, isLoading }, setState] = useSetState({
    isModalOpen: false,
    isLoading: false
  });

  const formButtons = [
    {
      text: '回到上一頁',
      color: 'info',
      icon: 'exitToApp',
      func: () => setState({ isModalOpen: true })
    },
    {
      text: '發布貼文',
      type: 'submit',
    }
  ];


  const getModalStateHandler = state => {
    setState({ isModalOpen: state });
  };

  const modalButtons = [
    {
      text: '取消',
      color: 'highlight',
      func: () => { }
    },
    {
      text: '確定',
      func: () => {
        clearPostList();
        history.goBack();
      }
    }
  ];

  const onSubmitHandler = async data => {
    setState({ isLoading: true });
    const isSuccess = await createPost({ payload: data });
    setState({ isLoading: false });
    if (isSuccess) {
      clearPostList();
      history.goBack(`/home/${classId}/paste`);
    }
  };

  return (
    <UiHomePostForm>
      <UiDateTitle>{format(new Date(), 'yyyy-MM-dd hh:mm (EEE)')}</UiDateTitle>
      <Form schema={schema} buttons={formButtons} onSubmit={onSubmitHandler} isLoading={isLoading} />
      <Modal
        isOpen={isModalOpen}
        text="貼文內容不保存，回到貼文列表"
        buttons={modalButtons}
        getModalState={getModalStateHandler}
      />
    </UiHomePostForm>
  );
};



