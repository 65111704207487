import React from 'react';
import PropTypes from 'prop-types';
import {
  Radio as MatRadio,
  RadioGroup as MatRadioGroup,
} from '@material-ui/core';
import { UiRadioGroup, UiFormHelperText, UiFormLabel, UiRadio } from './RadioGroup.style';


/**
 * Radio
 */

export const RadioGroup = ({
  label,
  data = [],
  onChange,
  value,
  defaultValue,
  helperText,
  error,
  isRow = true,
}) => {
  return (
    <UiRadioGroup>
      { label && <UiFormLabel>{label}</UiFormLabel> }
      <MatRadioGroup
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        row={isRow}
      >
        {
          data.map(item => (
            <UiRadio 
              key={item.value}
              value={item.value}
              label={item.label}
              checked={item[value]}
              control={<MatRadio color="primary" />}
            />
          ))
        }
      </MatRadioGroup>
      {(helperText && error) && (
          <UiFormHelperText >
            {helperText}
          </UiFormHelperText>
        )}
    </UiRadioGroup>
  );
};

RadioGroup.propTypes = {
  label: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  onChange: PropTypes.func,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  isRow: PropTypes.bool,
};


