import styled from 'styled-components';
import openCourseMan from 'assets/images/open_course_man.svg';

const widthPageMan = '172px';

export const UiHomeOpenCourse = styled.div`
	display: block;
`;

export const UiPageTitleWrapper = styled.div`
	padding: 40px calc(40px + ${widthPageMan}) 40px 40px;
	position: relative;

	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const UiPageTitle = styled.div`
	color: #242C3F;
	font-size: 1.6rem;
`;

export const UiPageManContainer = styled.div`
	width: ${widthPageMan};
	position: absolute;
	top: 10%;
	right: 0;
`;

export const UiPageMan = styled.img.attrs({
	src: openCourseMan,
})`
	height: 100%;
	width: 100%;
`;
