import React, { useEffect,useRef } from 'react';
import { Link,useParams } from 'react-router-dom';
import logo from 'assets/images/logo.png';
import {
  getGroupAnnouncements as getGroupAnnouncementsApi,
} from 'services/api/home/announcement';
import { useHistory } from 'react-router-dom';
import { useInterval } from 'ahooks';
import announcementIcon from 'assets/images/announcement.png';
import { useUser } from 'store/user';
import { AvatarMenu, Icon } from 'components';
import { useServerTime } from 'utils/hooks/useServerTime';
import { useSetState } from 'utils/hooks/useSetState';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { useClass } from 'store/class';
import { UiHeader, UiHeaderText, UiClassName,UiAnnouncement } from './Header.style';

/**
 * Header
 */

// eslint-disable-next-line react/prop-types
export const Header = ({ isNeedLogo = true }) => {
  const organizationId = process.env.REACT_APP_LIVE_ORGANIZATION_ID;
  const { classId } = useParams();
  const history = useHistory();
  const [{ timestamp: serverTimestamp }] = useServerTime();
  const [{
    announcements,
    total,
    announcementIndex
  },setState] = useSetState({
    announcements:[],
    total:0,
    announcementIndex:0
  });
  const ref = useRef(null);
  const [{ profile,myOrganization }] = useUser();
  const [{ myClasses }, { getGroupInfoById }] = useClass();
  const { dataInfo: myClassInfo } = myClasses;
  const isLogin = profile?.id;

  const fetchAnnouncements = async () => {
    const nowTime = new Date(serverTimestamp).getTime();
    const { isSuccess,data } = await getGroupAnnouncementsApi(organizationId)({nowPage:0, rowsPage:10});
    if(isSuccess){
      const nextData = data.announcements.filter( announcement => {
        const publishedAt = announcement?.publishedAt;
        return publishedAt && nowTime > publishedAt;
      });
      setState({
        announcements: nextData,
        total: nextData.length
      });
    }else{
      setState({
        announcements: [],
        total: 0
      });
    }
  };

  useEffect(()=>{
    if(!classId || !myOrganization.isLoaded) return;
    getGroupInfoById(classId);
  },[classId,myOrganization.isLoaded]);

  // useEffect(()=>{
  //   if(!isLogin) return;
  //   fetchOrganizationById(organizationId);
  // },[isLogin]);

  useEffect(()=>{
    if(!isLogin || !profile || !myOrganization.isLoaded || !serverTimestamp) return;
    fetchAnnouncements();
  },[isLogin, profile, myOrganization.isLoaded, serverTimestamp]);

  useInterval(() => {
    setState({
      announcementIndex: announcementIndex + 1 >= total ? 0 : announcementIndex + 1
    });
  }, 10000);

  return (
    <UiHeader isNeedLogo={isNeedLogo}>
      <div className="container">
        {
          isNeedLogo 
            ? <div className="logo">
                <Link to={myOrganization.isLoaded ? '/home' : '/tutor'}>
                  <img src={logo} alt="全國最大線上家教中心"/>
                </Link>
                <Breadcrumbs>
                  {/* {isLogin && <div className="breadcrumbItem">
                    <UiHeaderText>
                      <Icon.Svg name="School"/>
                      {myOrganization?.organization?.officialName || ''}
                    </UiHeaderText>
                  </div>} */}
                  {
                    myClassInfo?.name &&
                    <Typography color="textPrimary" component="div">
                      <UiHeaderText>
                        <Icon.Svg name="School"/>
                        <UiClassName>{myClassInfo?.name}</UiClassName>
                      </UiHeaderText>
                    </Typography>
                  }
                </Breadcrumbs>
              </div> 
            : <UiAnnouncement onClick={()=>history.push('/home/announcement')}>
                <img src={announcementIcon} alt="announcement" />
                {
                  announcements.map((announcement,index)=>{
                    return (
                      <span
                        key={index}
                        className={announcementIndex === index ? 'active' : ''}
                        ref={ref}
                      >
                        {announcement.title}
                      </span>
                    );
                  })
                }
              </UiAnnouncement>
        }
        <AvatarMenu />
      </div>
    </UiHeader>
  );
};
