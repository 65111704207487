import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Menu as MatMenu,
  MenuItem as MatMenuItem,
  ListItemIcon as MatListItemIcon,
  ListItemText as MatListItemText
} from '@material-ui/core';

import { Icon } from 'components';
import { UiClickablePopoverMenu, UiClickablePopoverMenuList } from './ClickablePopoverMenu.style';


/**
 * 將套用的組件賦予點擊彈出選單
 */

export const ClickablePopoverMenu = ({
  children,
  menuList,
  width,
  isActive
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);


  const clickHandler = event => {
    setAnchorEl(event.currentTarget);
  };

  const closeHandler = () => {
    setAnchorEl(null);
  };

  const actionHandler = callback => {
    closeHandler();
    callback && callback();
  };

  return (
    <>
      <UiClickablePopoverMenu onClick={clickHandler} width={width}>
        {children}
      </UiClickablePopoverMenu>
      <MatMenu
        anchorEl={anchorEl}
        onClose={closeHandler}
        open={open}
      >
        <UiClickablePopoverMenuList className={isActive && 'active'}>
          {
            menuList.map((menuItem, index) => {
              if (menuItem.isShow !== false)
                return (

                    <MatMenuItem key={index} onClick={() => { actionHandler(menuItem.func); }}>
                      {
                        menuItem.iconName && (
                          <MatListItemIcon>
                            {
                              menuItem.isSvg ?
                                <Icon.Svg name={menuItem.iconName} haveBg={false} color="#242c3f"/> :
                                <Icon name={menuItem.iconName} size="20px" haveBg={false} icolor="#242c3f"/>
                            }
                          </MatListItemIcon>
                        )
                      }

                        <MatListItemText primary={menuItem.label} />
                    </MatMenuItem>
                );
              })
            }
        </UiClickablePopoverMenuList>
      </MatMenu>
    </>
  );
};

ClickablePopoverMenu.propTypes = {
  children: PropTypes.node,
  isActive:PropTypes.bool,
  width: PropTypes.string,
  menuList: PropTypes.arrayOf(
    PropTypes.shape({

    })
  )
};


