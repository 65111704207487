import styled from 'styled-components';
import { Typography as MuiTypography, } from '@material-ui/core';
import {
  ToggleButton as MuiToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup,
} from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';

const headerToolbarCenter = 2;

export const UiToolbar = styled.div`
  margin: 19px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const UiStack = styled.div`
  display: flex;
  align-items: center;

  &:nth-child(${headerToolbarCenter}) {
    gap: 30px;
  };
`;

export const UiMuiToggleButton = withStyles((theme) => ({
  root: {
    transition: '0.1s',

    '&:hover': {
      background: '#FEF2F0',
      '& svg': {
        color: theme.palette.btn.normal,
      }
    },
    '& svg': {
      transition: '0.1s',
      color: '#637381',
    },
    '&.Mui-selected, &.Mui-selected:hover': {
      background: '#FEF2F0',
    },
  },

  selected: {
    '& svg': {
      color: theme.palette.btn.normal,
    }
  }
}))(MuiToggleButton);

export const UiMuiToggleButtonGroup = withStyles((theme) => ({
  root: {
    gap: theme.spacing(0.5),
  },
  grouped: {
    padding: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: '8px',
    },
    '&:first-child': {
      borderRadius: '8px',
    }
  }
}))(MuiToggleButtonGroup);

export const UiMuiTypography = styled(MuiTypography)`
  font-size: 1.34rem;
  font-weight: 700;
`;
