import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { getPageName } from 'utils/pagePathSlicer';
import { UiDashBoardCard } from './DashBoardCard.style';


/**
 * 班級課程卡牌
 */

export const DashBoardCard = ({
  className,
  onClick,
  disabled = false,
  src,
  title,
  groupName,
  sessionInterval,
  isActive,
  hostName,
  defaultImg
}) => {
  const { pathname } = useLocation();
  const onClickHandle = () => {
    disabled || (onClick && onClick());
  };
  return (
  <UiDashBoardCard
    className={className}
    onClick={onClickHandle}
    data-ga-category={getPageName(pathname)}
    data-ga-action={`今日課程_${title}`}
  >
      <div className="image">
        <img src={src || defaultImg} alt="session" data-testid="Img"/>
      </div>
      <div className="info">
        <div className="info_item">
          <div className="title">{title}</div>
          <div className="badge">{groupName}</div>
        </div>
        <div className="info_item">
          <div className="time">
            {sessionInterval}
            {isActive || <span>已結束</span>}
          </div>
          <div className="name">
            {hostName} 老師
          </div>
        </div>
      </div>
  </UiDashBoardCard>
  );
};

DashBoardCard.propTypes = {
  className :PropTypes.string,
  onClick :PropTypes.func,
  disabled :PropTypes.bool,
  src :PropTypes.any,
  title :PropTypes.string,
  groupName :PropTypes.string,
  sessionInterval :PropTypes.string,
  isActive :PropTypes.bool,
  hostName :PropTypes.string,
  defaultImg :PropTypes.any
};
