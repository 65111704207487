import styled from 'styled-components';

export const UiHomePasteTable = styled.div`
	display: block;
	margin: 0 auto;
	max-width: 680px;
`;

export const UiTitleBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1.5rem;
	width: 100%;

	button {
		margin: 0;
	}
`;

export const UiTitle = styled.div`
	font-size: 1.25rem;
	font-weight: bolder;
	color: #163e6f;
`;

export const UiCardBox = styled.div`
	margin: 1rem 0;

	& > div {
		margin: 1rem 0 2rem;
	}
`;

export const UiPostActionBox = styled.div`
	padding: 0.75rem 1rem;
	background-color: #fff;
	border: 1px solid #e4e7ec;
	border-radius: 16px;

	& > div:nth-child(1) {
		padding: 1rem;
		width: 100%;
		font-size: 14px;
		color: #a1a4b1;
		background-color: #fff;
		border-radius: 5px;
		cursor: pointer;
		margin-bottom: 1rem;
	}
`;

export const UiPostFlexBox = styled.div`
	display: flex;
	justify-content: space-around;
	width: 100%;

	& > div {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 5px 10px;
		padding: 0.75rem;
		font-size: 14px;
		background-color: #fafafc;
		border-radius: 16px;
		cursor: pointer;

		&:hover {
			opacity: 0.8;
		}
	}
`;

export const UiPostButton = styled.div`
	display: flex;
	justify-content: center;
	padding: 0.5rem 1rem;
	font-size: 14px;
	color: ${({ theme }) => theme.palette.textSub};
	background-color: ${({ theme }) => theme.palette.tagInfo};
	border-radius: 1rem;
	opacity: 1;
	transition: 0.35s;
	flex: 1;
	cursor: pointer;

	& > svg {
		margin-right: 0.5rem;
		font-size: 1.7rem;
		color: ${({ theme }) => theme.palette.btnNormalHover};

		& path {
			fill: ${({ type }) => type === 'Picture' ? '#FFA31A' : (type === 'Video' ? '#F6564B' : '#5EB762')};
		}
	}

	&:hover {
		background-color: #fff;
	}
`;

export const UiNoDataBox = styled.div`
	width: 100%;
`

export const UiPictureBox = styled.div`
	display: block;
	margin: auto;
	width: 36%;
	height: 240px;
	background: url("${({ path }) => path}");
	background-position: center;
	background-size: cover;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
`

export const UiUiNoDataText = styled.div`
	margin-top: 2rem;
	text-align: center;
	color: #3a4052;
`

export const UiNoDataButton = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 2rem;

	> button {
		width: 205px;
		border-radius: 16px;
	}
`