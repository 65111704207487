import ReactGA from 'react-ga4';
import { COMMON_ACTION_TYPE } from 'constants/index';

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

const trackPage = page => {
  ReactGA.send({ hintType: 'pageview', page });
};

const gaMiddleware = store => next => action => {
  if (action.type === COMMON_ACTION_TYPE.LOCATION_CHANGE) {
    const nextPage = `${action.payload.location.pathname}${action.payload.location.search}`;
    trackPage(nextPage);
  }
  return next(action);
};


export default gaMiddleware;
