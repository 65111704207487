import styled from 'styled-components';
import book from 'assets/images/book.svg';

export const UiSessionCard = styled.div`
  height: 224px;
  width: 320px;
  border: 1px solid #F2F4F8;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  padding: 20px 20px 14px 20px;

  display: flex;
  flex-direction: column;
`;

export const UiHeader = styled.div`
  border-bottom: 1px solid #E4E7EC;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  flex: 1;
`;

export const UiBookContainer = styled.div`
  min-height: 56px;
  max-height: 56px;
  min-width: 56px;
  max-width: 56px;
  background: #FAD7D1;
  border-radius: 50%;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UiBook = styled.img.attrs({
  src: book,
})``;

export const UiHeaderDetail = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UiHeaderTitle = styled.p`
  color: #2F3649;
  font-size: 1.34rem;
  margin: 0;

  display: -webkit-inline-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

export const UiHeaderSubtitle = styled.p`
  color: #8B90A0;
  font-size: 0.934rem;
  margin: 0;

  display: -webkit-inline-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

export const UiBody = styled.div`
`;

export const UiBodyTitle = styled.p`
  color: #454B5C;
  font-size: 1.06rem;
  line-height: 24px;
  margin: 0;

  display: -webkit-inline-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

export const UiBodySubtitle = styled.p`
  color: #8B90A0;
  font-size: 1.06rem;
  line-height: 28px;
  margin: 0;
`;
