import React from 'react';
// import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import {
  Table,
  Icon,
  Button,
} from 'components';
import { getGroupExamResults } from 'services/api/home/exam';
import { usePagination } from 'utils/hooks/usePagination';
// import { useSetState } from 'utils/hooks/useSetState';
import {
  UiHomeExamResults,
  UiHomeExamGoBack,
  UiHomeExamTitle,
  UiHomeExamTable,
  UiEmptyBox,
  UiEmptyImage,
  UiEmptyText,
} from './HomeExamResults.style';
import { format } from 'date-fns';
import emptyExam from 'assets/images/emptyExam.png';


/**
 * 作答結果
 */

 const EXAMNOTFINISHED = 'examNotFinished';
 const ANSWERED = 'answered';
 const MISSED = 'missed';
 const TOBECORRECTED = 'toBeCorrected';
 
 const EXAM_STATUS = {
   [EXAMNOTFINISHED]: '尚未作答',
   [ANSWERED]: '作答完成',
   [MISSED]: '缺考',
   [TOBECORRECTED]: '待批改'
 };

 const schema = {
  attendeeNumber: {
    name: '座號',
    defaultValue: '-',
  },
  userNickname:{
    name:'學生姓名',
    defaultValue: '',
  },
  statusText:{
    name:'狀態',
    defaultValue:'',
  },
  // submitTime:{
  //   name:'交卷時間',
  //   defaultValue:'',
  // },
  // answerPeriodText:{
  //   name:'作答時間',
  //   defaultValue:'',
  // },
  // scoreText:{
  //   name:'分數',
  //   defaultValue:'-',
  // }
};

export const HomeExamResults = () => {
  const history = useHistory();
  const examName = history.location.state.examName;
  const { classId, examId } = useParams();

  const goBack = () => {
    history.goBack();
  };

  // 操作
  const ActionComponents = ({ params }) => {
    const { status, userId, examId } = params;
    const goExamResults = () => {
      const url = `${process.env.REACT_APP_ONEEXAM_DOMAIN}/user/report/${examId}/${userId}`;
      window.open(url);
    };

    return (
      <Button onClick={goExamResults} disabled={status !== ANSWERED}>
        作答結果
      </Button>
    );
  };

  const { data , total } = usePagination(
    () => getGroupExamResults(
      classId,
      examId
    ),
    {
      resKeyId:'id',
      deps: [classId],
      page: 0,
      limit: 100,
      formatResult: data => data.map( item => {
        const { status, submittedAt, answerPeriod } = item;
        return {
          ...item,
          scoreText: item.score.toString(),
          statusText: <div key={item.examId} style={{ color: status === MISSED ? '#F44336' : '#505565'}}>
              {EXAM_STATUS[status]}
          </div>,
          submitTime: submittedAt ? format(submittedAt,'yyyy-MM-dd HH:mm') : '-',
          answerPeriodText: answerPeriod ? `${answerPeriod} 分` : '-',
        };
      })
    });

  return (
    <UiHomeExamResults>
      <UiHomeExamGoBack onClick={goBack}>
        <Icon.Svg name="ChevronRight" />
        <span>返回上一頁</span>
      </UiHomeExamGoBack>
      <UiHomeExamTitle>{examName}</UiHomeExamTitle>
      <UiHomeExamTable>
        {
          data.length === 0 && <UiEmptyBox>
            <UiEmptyImage path={emptyExam} />
            <UiEmptyText>該測驗目前尚無學生進行作答</UiEmptyText>
          </UiEmptyBox>
        }
        {
          data.length > 0 && <Table
            data={data}
            schema={schema}
            totalPage={total}
            ActionComponents={ActionComponents}
            isNeedPagination={false}
          />
        }
      </UiHomeExamTable>
    </UiHomeExamResults>
  );
};

HomeExamResults.propTypes = {

};
