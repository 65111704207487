import React, { useEffect, useCallback,useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { format, add, addMinutes, getTime } from 'date-fns';
import { EDIT_STATUS,ORGANIZATION_SETTINGS_VALUE } from 'constants/index';
import { getPrivateResources } from 'services/api/home/users';
import { useSessions } from 'store/sessions';
import {useOrganization} from 'store/organization';
import { useTeacher } from 'store/teacher';
import { useAlert } from 'utils/hooks/useAlert';
import { useClass } from 'store/class';
import { makeStyles } from '@material-ui/core/styles';
import { useUser } from 'store/user';
import { useResource } from 'store/resource';
import { useSetState } from 'utils/hooks/useSetState';
import {
  Form,
  DateTimePicker,
  Select,
  TransferListModal,
  TraceTextForm,
  Checkbox,
  Button,
  IconInput,
} from 'components';
import { UiSessionForm, UiFilter, UiSearchInput,UiPublicFilterBox } from './SessionForm.style';
import { TextField, InputAdornment,Tabs as MatTabs, Tab as MatTab, AppBar as MatAppBar } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { TablePagination } from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';

/**
 * 新增課程表單
 */
const SessionTypeMap = {
  normal: '一般課程',
  video: '視訊課程',
  interactive: '互動課程'
};

const SelectOptions = [
  { name: '15分', value: 15 },
  { name: '30分', value: 30 },
  { name: '45分', value: 45 },
  { name: '50分', value: 50 },
  { name: '60分', value: 60 },
  { name: '90分', value: 90 },
  { name: '120分', value: 120 }
];

const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: '15px',
	},
}));

const Tags = ({ data = [] }) => (
  <>
   {
     data.map(item => <div key={item.tagId}>{item.label}</div>)
   }
 </>
 );

// eslint-disable-next-line react/prop-types
const DurationSelect = ({ dateTime, duration, ...props }) => {
  const time = format(add(new Date(dateTime), { minutes: duration }), 'HH:mm');
  const info = duration ? time : '__:__';
  return (
    <Select {...props} helperText={`下課時間 當天 ${info}`} />
  );
};

const OTHER = 'other';

export const SessionForm = ({ isOrganization, editStatus }) => {
  const history = useHistory();
  const styles = useStyles();
  const organizationId = process.env.REACT_APP_LIVE_ORGANIZATION_ID;
  const { classId ,sessionId } = useParams();
  const [{orgSubjects},{getOrganizationSubjects}] = useOrganization();
  const [{
    allResourceList,
    resources,
    bookstoreBooks,
    bookstoreTags,
    gradeTags,
    subjectTags
  }, {
    getAllResource,
    getResourcesList,
    getBookstoreBooks,
    getBookstoreTags,
    getGradeTags,
    getSubjectTags
   }] = useResource();
  const [{ organizationStaffs },
    { getAllTeachers, getTeachers, getOrganizationStaffs }] = useTeacher();
  const [{ profile, myOrganization: { organization } }] = useUser();
  const [{ myClasses,classes,subjects }, { getSubjects }] = useClass();
  const [{ sessions }, { createSession, createOrganizationSession, updateOrganizationSession }] = useSessions();
  const { dataMaps } = sessions;
  const { dataInfo: { id,isOwner,educationName } } = myClasses;
  const { dataInfo: { id:orgClassId,isOwner:orgISOwner,educationName:orgEducationName } } = classes;
  const isHome = useRouteMatch({ path: '/home' }) ? true : false;
  const { setAlert } = useAlert();
  const [{
    startAt,
    duration,
    timeSpanId,
    searchTeacherValue,
    searchResourceValue,
    isLoading,
    data,
    title,
    teacherHelpText,
    resourceHelpText,
    error,
    hideTime,
    reset,
    formSchema,
    searchKey,
    searchValue,
    currentGroupHostOnly,
    searchIsLoading,
    targetTab,
    privateResources,
    hostId,
    resourceId,
    isReset,
    privateResourcesPage,
    privateResourcesRows,
    privateResourcesTotal,
  }, setState
  ] = useSetState({
    startAt: Date.now(),
    duration: 0,
    searchTeacherValue: '',
    searchResourceValue: '',
    isLoading: editStatus === EDIT_STATUS.CREATE ? false : true,
    data: null,
    teacherHelpText: '請選擇教師',
    resourceHelpText: '請選擇教學資源',
    error: false,
    hideTime: false,
    reset: false,
    formSchema: {},
    searchKey: '',
    searchValue: '',
    currentGroupHostOnly: false,
    searchIsLoading: false,
    targetTab:0,
    privateResources:[],
    hostId:'',
    resourceId:'',
    isReset:false,
    privateResourcesPage:0,
    privateResourcesRows:10,
    privateResourcesTotal:0
  });

  const [{ displayName, nowPage, rowsPage }, setPublicResourcesState] = useSetState({
    displayName: '',
    nowPage: 0,
    rowsPage: 100,
  });

  const [selectedOption,setSelectedOption] = useSetState({
    schoolYear: '',
    educationalSystem: '',
    grade: '',
    subject: ''
  });

  const { educationalSystem } = selectedOption;

  let selectTypeOptions = organization?.availableSessionTypeSetting.map(sessionType => ({
    name: SessionTypeMap[sessionType],
    value: sessionType
  }));


  const selectFilterTypeOptions = [
    {
      name: '手機號碼',
      value: 'mobileNumber'
    },
    {
      name: '暱稱',
      value: 'nickname'
    },
    {
      name: '帳號ID',
      value: 'targetUserId'
    }
  ];

  const searchPlaceholderMap = {
    mobileNumber: '請輸入教師手機號碼',
    nickname: '請輸入教師暱稱',
    targetUserId: '請輸入教師帳號ID'
  };

  const fetchResources = async (params) => {
    const { data,isSuccess } = await getPrivateResources(params);
    if(isSuccess){
      const resourcesData = data.resources.map(resource=>{
        return {
          ...resource,
          id: resource.resourceId,
          title:resource.displayName,
        };
      });
      setState({
        privateResources: resourcesData,
        privateResourcesTotal: data.total,
      });
    }else{
      setState({
        privateResources: [],
        privateResourcesTotal:0,
      });
    }
  };

  useEffect(() => {
    if (!organization) return;
    setState({
      isLoading: true
    });

    if(educationName === OTHER || orgEducationName === OTHER) delete schema.subjectCode;
    Object.entries(schema).forEach(([key]) => {
      if(key === 'subjectCode'){
        schema[key].elementProps.options = isOrganization ? orgSubjects.data : subjects.data;
      }
    });
    setState({
      formSchema: schema,
      isLoading: false
    });
  }, [organization, profile,orgSubjects.data, subjects.data,resourceId]);

  useEffect(() => {
    const params = {
      nowPage: privateResourcesPage,
      rowsPage:privateResourcesRows
    };
    fetchResources(params);
  }, [privateResourcesPage,privateResourcesRows]);


  const schema = {
    name: {
      component: TraceTextForm,
      elementProps: {
        label: '課程名稱',
        helperText: '',
        variant: 'outlined',
        placeholder: '請輸入課程名稱',
        maxTextNum: 70
      },
      rules: {
        required: {
          value: true,
          message: '本欄位為必填'
        },
        maxLength: {
          value: 70,
          message: '最大長度為 70 個字元'
        },
      }
    },
    sessionType: {
      component: Select,
      elementProps: {
        label: '課程類型',
        defaultValue: 'normal',
        options: selectTypeOptions,
        onChange: (type) => { sessionTypeChange(type[0]); return type[0]; },
        onChangeName: 'submitHandler'
      }
    },
    subjectCode: {
      component: Select,
      elementProps: {
        label: '科目',
        defaultValue: '',
        options: [],
        onChange: (subject) => { subjectChangeHandle(subject[0]); return subject[0]; },
        onChangeName: 'submitHandler'
      },
      rules: {
        required: {
          value: true,
          message: '本欄位為必填'
        }
      }
    },
    startAt: {
      component: DateTimePicker,
      elementProps: {
        label: '上課時間',
        defaultValue: Date.now(),
        value: '',
        helperText: '',
        needHide: true,
      }
    },
    duration: {
      elementProps: {
        label: '課程時長',
        value: '',
        defaultValue: null,
        options: SelectOptions,
        onChangeName: 'submitHandler',
      },
      rules: {
        required: {
          value: true,
          message: '本欄位為必填'
        }
      }
    },
    hostId: {
      elementProps: {
        buttonName: '選擇授課教師',
        title: '選擇授課教師',
        helperText: '',
        type: 'singleChoice',
        onChange: ([{ id }]) => id
      }
    },
    resourceId: {
      elementProps: {
        buttonName: '選擇教學檔案',
        title: '選擇教學檔案',
        helperText: '',
        type: 'singleChoice',
        onChange: ([{ id }]) => {
          setState({
            resourceId:id,
            isReset: false
          });
          return id;
        }
      }
    },
    preExamId: {
      component: TextField,
      elementProps: {
        label: '課前測驗ID',
        helperText: '',
        variant: 'outlined',
        placeholder: '請輸入課前測驗ID',
        maxTextNum: 30
      }
    },
    postExamId: {
      component: TextField,
      elementProps: {
        label: '課後測驗ID',
        helperText: '',
        variant: 'outlined',
        placeholder: '請輸入課後測驗ID',
        maxTextNum: 30
      }
    },
  };

  // 取得所有教師、資源列表
  useEffect(() => {
    if(!id && !orgClassId) return;
    if(isOrganization){
      if( orgEducationName !== OTHER) getOrganizationSubjects(organizationId,classId);
    }else{
      if(educationName !== OTHER)getSubjects(classId);
    }
    getAllTeachers();
    getAllResource();
    getOrganizationStaffs();

    // 畫面標題
    let title = '';
    switch (editStatus) {
      case EDIT_STATUS.CREATE:
        title = '新增課程';
        break;
      case EDIT_STATUS.EDIT:
        title = '編輯課程';
        break;
      case EDIT_STATUS.READ:
        title = '檢視課程';
        break;
      default:
        break;
    }

    setState({ title });

    if (sessionId) {
      if (dataMaps) {
        // 取 store 資料
        let sessionInfo = dataMaps[sessionId];
        setState({ timeSpanId: sessionInfo.timeSpanId });
        sessionInfo = Object.assign(
          sessionInfo,
          { duration: sessionInfo.duration.slice(0, sessionInfo.duration.length - 2) }
        );
        setState({ data: sessionInfo });
      }
    }
  }, [id]);

  useEffect(() => {
    if (!data) return;
    setState({ isLoading: false });
  }, [data]);

  const nextData = useMemo(() => {
    // 不可被編輯的欄位
    let blackList = ['hostId', 'name', 'timeSpanId', 'startAt', 'endAt', 'resourceId'];
    let isRead = false;
    const nowSchema = formSchema || schema;
    // schema 比對
    let newData = Object.assign({}, nowSchema);
    // 根據網址狀態判斷
    switch (editStatus) {
      // 編輯
      case EDIT_STATUS.EDIT:
        if (!data) return;
        if(data.startAt > new Date().getTime()) {
          blackList = blackList.filter(item => item !== 'startAt');
        }
        break;
      // 檢視
      case EDIT_STATUS.READ:
        isRead = true;
        if (!data) return;
        break;
      // 建立 & 預設
      case EDIT_STATUS.CREATE:
        Object.entries(newData).forEach(([key]) => {
          if(organization.tutorialExamSetting === ORGANIZATION_SETTINGS_VALUE.DISABLED) {
            if(key === 'postExamId' || key === 'preExamId'){
              delete newData[key];
            }
          }
        });
        return newData;
      default:
        return formSchema;
    }

    Object.entries(newData).forEach(([key]) => {
      if(key === 'subjectCode'){
        newData[key].elementProps.defaultValue = data.subjects[0]?.code;
      }else if(key === 'sessionType'){
        newData[key].elementProps.defaultValue = data.type;
      }else{
        newData[key].elementProps.defaultValue = data[key];
      }

      if (editStatus === EDIT_STATUS.READ) {
        newData[key].elementProps.disabled = isRead;
      } else {
        newData[key].elementProps.disabled = blackList.includes(key) ? !isRead : isRead;
      }
      if(organization.tutorialExamSetting === ORGANIZATION_SETTINGS_VALUE.DISABLED) {
        if(key === 'postExamId' || key === 'preExamId'){
          delete newData[key];
        }
      }
    });

    return newData;
  }, [data, formSchema]);

  const onFormChangeHandler = data => {
    if (data.startAt && data.startAt !== startAt) setState({ startAt: data.startAt });
    if (data.duration && data.duration !== duration) setState({ duration: data.duration });
    if (data.hostId && data.hostId !== hostId) {
      setState({
        hostId: data.hostId,
        resourceId:'',
        isReset:true
      });
    }
  };

  const onSubmitHandler = async data => {
    if(!resourceId) {
      setAlert('請重新選擇教材');
      return;
    }
    if (!resourceId) {
      setState({
        error: true
      });
      return;
    }
    setState({ isLoading: true });
    data.endAt = getTime(addMinutes(data.startAt, data.duration));
    data.hostId = hostId;
    data.resourceId = resourceId;
    delete data.duration;
    data = Object.assign(data, { timeSpanId });
    let isSuccess;

    if (isOrganization) {
      const isEdit = editStatus === EDIT_STATUS.EDIT;
      isEdit ?
        isSuccess = await updateOrganizationSession({
          data
        }) :
        isSuccess = await createOrganizationSession({
          data
        });
    } else {
      isSuccess = await createSession({
        data
      });
    }

    setState({ isLoading: false });
    isSuccess && history.goBack();
  };

  const searchHandler = key => value => {
    setState({ [key]: value });
  };

  const chooseResources = (tabIndex) => {
    switch(tabIndex){
      case 0:
        return formatResourceListData(searchResourceValue ? resources.data : allResourceList.data);
      case 1:
        return tableData;
      case 2:
        return privateResources;
      default:
        return [];
    }
  };

  // 搜尋教師列表
  useEffect(() => {
    if (!searchTeacherValue) return;
    const params = {
      mobileNumber: searchTeacherValue
    };
    getTeachers(params);
  }, [searchTeacherValue]);

  // 搜尋資源列表
  useEffect(() => {
    if (!searchResourceValue) return;
    const params = {
      fileName: searchResourceValue
    };
    getResourcesList(params);
  }, [searchResourceValue]);
  // myClasses
  useEffect(() => {
    if(educationName === OTHER || orgEducationName === OTHER) return;
    getBookstoreBooks({
      nowPage,
      rowsPage,
      displayName,
      tags: Object.values(selectedOption).filter(item => item)
     });
  }, [nowPage, rowsPage, displayName, selectedOption]);

  useEffect(() => {
    if(educationName === OTHER || orgEducationName === OTHER) return;
    const tagCategory = ['SchoolYear', 'EducationalSystem', 'Grade', 'Subject'];
    Promise.all(tagCategory.map(category => getBookstoreTags({ category })));
  },[]);

  useEffect(() => {
    if(!educationalSystem) return;
    const code  = bookstoreTags.dataMap['EducationalSystem'][educationalSystem].code;
    getGradeTags(code);
    getSubjectTags(code);
  },[educationalSystem]);

  useEffect(()=>{
    if(isOwner) return;
    setState({hostId: profile.id});
  },[profile]);

  const formatTagOptions = useCallback((tags = []) => {
    return tags?.map(item => ({ name: item.label, value: item.tagId })).concat([{ name: '清除條件', value: '' }]) || [];
  },[]);

  const schoolYearOptions = useMemo(() =>
    formatTagOptions(bookstoreTags.data['SchoolYear'])
  ,[bookstoreTags.data]);

  const educationalSystemOptions = useMemo(() =>
    formatTagOptions(bookstoreTags.data['EducationalSystem'])
  ,[bookstoreTags.data]);

  const gradeOptions = useMemo(() => formatTagOptions(gradeTags.data),[gradeTags.data]);
  const subjectOptions = useMemo(() => formatTagOptions(subjectTags.data),[subjectTags.data]);

  const tagFormat = useCallback((category,tagIds) => {
    if(!category || !bookstoreTags.data[category]?.length) return [];
    const filteredTags = tagIds.filter(tagId => bookstoreTags.dataMap[category][tagId]);
    return filteredTags.map(tagId => bookstoreTags.dataMap[category][tagId]);
  },[bookstoreTags]);


  const tableData = useMemo(() =>  bookstoreBooks.data.map((book,index) => {
    const { bookId, displayName, tags } = book;

    return {
      id: bookId,
      bookId,
      title:displayName,
      displayName,
      SchoolYear: <Tags data={tagFormat('SchoolYear',tags)}/>,
      EducationalSystem: <Tags data={tagFormat('EducationalSystem',tags)}/>,
      Grade: <Tags data={tagFormat('Grade',tags)}/>,
      Subject: <Tags data={tagFormat('Subject',tags)}/>
    };
  }),
  [bookstoreBooks.data,subjectOptions,educationalSystemOptions, gradeOptions, subjectOptions]);

  const formatTeacherListData = (teacherList = []) => {
    // const { dataInfo: { isOwner, ownerId } } = myClasses;
    // const ownerIsTeacher = teacherList.some(item => item.id === ownerId);
    // if (isOwner && searchTeacherValue === '' && !ownerIsTeacher) {
    //   teacherList = [{
    //     id: profile.id,
    //     nickname: profile.nickname
    //   }, ...teacherList];
    // }
    return teacherList.map(item => ({
      id: item.id,
      imagePath: item.thumbnailUrl,
      title: item.nickname,
      content: item.mobileNumber,
    }));
  };
  const formatResourceListData = (resourceList = []) => resourceList.map(item => ({
    id: item.resourceId,
    imagePath: '',
    title: item.displayName,
    content: '',
  }));

  const buttons = [
    {
      text: '取消',
      color: 'cancel',
      func: () => history.goBack()
    },
    {
      text: '確認',
      color: 'new',
      type: 'submit',
    },
  ];

  const sessionTypeChange = (type) => {
    setState({
      hideTime: type === 'permanent' ? true : false
    });
  };

  const subjectChangeHandle = subject => {
    setState({
      subjectCode:subject
    });
  };

  const submitSelectHandler = value => {
    setState({
      searchKey: value
    });
  };
  const submitInputHandler = event => {
    setState({
      searchValue: event.target.value
    });
  };

  //搜尋檔名
  const submitPublicResourcesInputHandler = displayName => {
    setPublicResourcesState({
      displayName,
    });
  };

  const submitSearchHandler = async () => {
    setState({
      searchIsLoading: true
    });
    if (!searchKey) {
      alert('請先選擇搜尋目標');
      return;
    }

    const params = {
      currentGroupHostOnly: currentGroupHostOnly,
      [searchKey]: searchValue
    };

    await getOrganizationStaffs(params);
    setState({
      searchIsLoading: false
    });
  };

  const onSwitchChange = (val) => {
    const currentGroupHostOnly = val === 'currentGroupHostOnly' ? true : false;
    const params = {
      currentGroupHostOnly: currentGroupHostOnly,
      [searchKey]: searchValue
    };
    getOrganizationStaffs(params);
    setState({
      currentGroupHostOnly: currentGroupHostOnly
    });
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const tabHandleChange = (event, newValue) => {
    setState({
      targetTab:newValue
    });
  };

  const selectChangeHandler = key => value => {
    setSelectedOption({ [key]: value });
  };

  const handleChangePage = (event, newPage) => {
    setState({
      privateResourcesPage:newPage
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setState({
      privateResourcesPage:0,
      privateResourcesRows:parseInt(event.target.value, 10),
    });
  };

  const searchTools = tabIndex => {
    switch(tabIndex){
      case 1:
        return (
          <>
            <Select
              label="選擇學年度"
              options={schoolYearOptions}
              value={selectedOption.schoolYear}
              submitHandler={selectChangeHandler('schoolYear')}
            />
            <Select
              label="選擇學制"
              options={educationalSystemOptions}
              value={selectedOption.educationalSystem}
              submitHandler={selectChangeHandler('educationalSystem')}
            />
            <Select
              label="選擇年級"
              options={gradeOptions}
              value={selectedOption.grade}
              submitHandler={selectChangeHandler('grade')}
            />
            <Select
              label="選擇科目"
              options={subjectOptions}
              value={selectedOption.subject}
              submitHandler={selectChangeHandler('subject')}
            />
            <IconInput placeholder="搜尋檔案名稱" onChange={submitPublicResourcesInputHandler} />
          </>
        );
      case 2:
        return (
          <TablePagination
            count={privateResourcesTotal}
            page={privateResourcesPage}
            onChangePage={handleChangePage}
            rowsPerPage={privateResourcesRows}
            labelRowsPerPage={'每頁顯示'}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        );
      default:
        return null;
    }
  };

  return (
    <UiSessionForm>
      <Form
        title={title}
        schema={!isLoading ? nextData : {}}
        onFormChange={onFormChangeHandler}
        onSubmit={onSubmitHandler}
        buttons={buttons}
        isLoading={isLoading}
        resetTrigger={reset}
        needHide={hideTime}//永久課程用的
      >
        {
          !hideTime &&
          <DurationSelect
            name="duration"
            dateTime={startAt}
            duration={duration}
          />
        }
        {
          isOwner && <TransferListModal
            name='hostId'
            error={error}
            helperText={teacherHelpText}
            data={formatTeacherListData(organizationStaffs?.data)}
            errorMsg='無符合條件教師，請重新設定搜尋條件'
          >
            <UiFilter>
              <Select label="搜尋目標" options={selectFilterTypeOptions}
                value={searchKey} submitHandler={submitSelectHandler} />
              <UiSearchInput>
                <TextField placeholder={searchPlaceholderMap[searchKey]}
                  defaultValue={searchValue}
                  onChange={event => submitInputHandler(event)}
                  fullWidth={true}
                  type="search" variant="outlined" label='搜尋' InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}></TextField>
              </UiSearchInput>
              <Button onClick={submitSearchHandler} loading={searchIsLoading}>搜尋</Button>
              {isHome && (isOwner || orgISOwner) &&
                <Checkbox label="顯示本班教師" size='small' checkedValue="currentGroupHostOnly" onChange={onSwitchChange} />}
            </UiFilter>
          </TransferListModal>
        }
        <TransferListModal
          name='resourceId'
          isPreson={false}
          tagContent={
            <UiPublicFilterBox>
              {searchTools(targetTab)}
            </UiPublicFilterBox>
          }
          tabs={
            <MatAppBar position="static" className={styles.root}>
              <MatTabs value={targetTab} onChange={tabHandleChange}>
                <MatTab label="機構資源" {...a11yProps(0)}/>
                <MatTab
                  label="公開資源" {...a11yProps(1)}
                  disabled={
                    educationName === OTHER ||
                    orgEducationName === OTHER  ||
                    organization && organization.accessToPublicResourceSetting === ORGANIZATION_SETTINGS_VALUE.DISALLOW
                  }
                />
                <MatTab
                  label="我的資源" {...a11yProps(2)}
                  disabled={
                    hostId !== profile.id
                  }
                />
              </MatTabs>
            </MatAppBar>
          }
          error={error}
          helperText={resourceHelpText}
          data={chooseResources(targetTab)}
          isReset={isReset}
          search={targetTab === 0 && { placeholder: '搜尋資源名稱', onSearchHandler: searchHandler('searchResourceValue') }}
        />
      </Form>
    </UiSessionForm>
  );
};
