import React from 'react';
import PropTypes from 'prop-types';
import SessionCard from '../SessionCard';
import { Loading } from 'components';
import {
  UiClassroomWrapper,
  UiClassroomTitleWrapper,
  UiClassroomTitle,
  UiEmptyImgContainer,
  UiEmptyImg,
  UiEmptyImgFigcaption,
  UiSessionsWrapper,
  UiTag,
} from './Classroom.style';

const emptyText = '目前尚無開放教室';

export const Classroom = (props) => {
  const { background, isLoading, onClickCard, sessions, tag, title } = props;

  return (
    <UiClassroomWrapper background={background}>
      <UiClassroomTitleWrapper>
        <UiClassroomTitle>{title}</UiClassroomTitle>
        <UiTag>{tag}</UiTag>
      </UiClassroomTitleWrapper>
      <UiSessionsWrapper isEmpty={!sessions?.length}>
        {
          isLoading
            ? <Loading />
            : !sessions.length
                ? <UiEmptyImgContainer>
                    <UiEmptyImg />
                    <UiEmptyImgFigcaption>{emptyText}</UiEmptyImgFigcaption>
                  </UiEmptyImgContainer>
                : sessions.map(session => 
                    <SessionCard
                      key={session.id}
                      onClick={onClickCard}
                      session={session}
                    />
                  )
        }
      </UiSessionsWrapper>
    </UiClassroomWrapper>
  );
};

Classroom.defaultProps = {
  background: '#FFF',
  tag: null,
};

Classroom.propTypes = {
  background: PropTypes.string,
  isLoading: PropTypes.bool,
  onClickCard: PropTypes.func,
  sessions: PropTypes.object,
  tag: PropTypes.string,
  title: PropTypes.string,
};
