import React from 'react';
import PropTypes from 'prop-types';
import SVG from './svg';
import Tooltip from '@material-ui/core/Tooltip';
import { UiSvgIcon } from './Icon.style';


export const IconSvg = ({ className, name, color, onClick, title, size }) => {
  return (
      title ?
      <Tooltip title={title}>
        <UiSvgIcon onClick={onClick} className={className} component={SVG[name]} icolor={color} fontSize={size}/>
      </Tooltip>:
      <UiSvgIcon onClick={onClick} className={className} component={SVG[name]} icolor={color} fontSize={size}/>
  );
};

IconSvg.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
  size: PropTypes.string,
};
