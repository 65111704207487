import React, { useState } from 'react';
import AppRoutes from 'routes';
import { useLocationChange } from 'utils/hooks/useLocationChange';
import { Modal } from 'components';


const App = () => {

  useLocationChange();

  const [isModalOpen, setModalState] = useState(!localStorage.getItem('is_hide_version_news'));
  const buttons = [
    {
      text: '確認',
      func: () => { localStorage.setItem('is_hide_version_news', true); }
    }
  ];

    const getModalStateHandler = state => {
      setModalState(state);
    };

  return (
    <AppRoutes />
  );
};

export default App;
