import React, { useEffect, useMemo, useState } from 'react';
import CalendarModal from './CalendarModal';
import Classroom from './Classroom';
import SessionModal from './SessionModal';
import { Button } from 'components';
import { getPublished as getPublishedApi } from 'services/api/courses';
import { useClass } from 'store/class';
import { useUser } from 'store/user';
import { useAlert } from 'utils/hooks/useAlert';
import { useServerTime } from 'utils/hooks/useServerTime';
import {
  UiHomeOpenCourse,
  UiPageTitleWrapper,
  UiPageTitle,
  UiPageManContainer,
  UiPageMan,
} from './HomeOpenCourse.style';

/**
 * 會員公開課 內容
 */

const pageTitle = '會員公開課';
const selfStudySessionText = '自習教室';
const selfStudyBackground = '#FAFAFC';
const problemSolvingSessionText = '解題教室';
const btnTextCalendar = '課程行事曆';
const tag = 'new';

export const HomeOpenCourse = () => {
  const [openCoursesOri, setOpenCoursesOri] = useState([]);   // 會員公開課 API 資料
  const [selectedSession, setSelectedSession] = useState({}); // 選擇的教室資料
  const [selfStudySessions, setSelfStudySessions] = useState([]);           // 自習教室列表
  const [problemSolvingSessions, setProblemSolvingSessions] = useState([]); // 解題教室列表
  const [isLoadingSelfStudySessions, setIsLoadingSelfStudySessions] = useState(true);
  const [isLoadingProblemSolvingSessions, setIsLoadingProblemSolvingSessions] = useState(true);
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  const [isOpenEnterClassroomModal, setIsOpenEnterClassroomModal] = useState(false);  // 進入教室彈窗
  const { setAlert } = useAlert();
  const [{ timestamp: serverTimestamp }] = useServerTime();
  const [{ profile }] = useUser();
  const [{ myClasses }] = useClass();
  const { grades } = myClasses?.dataInfo;
  const classGrade = useMemo(() => grades && grades.map(grade => grade.padStart(3, 'G0')), [grades]);

  /**
   * 點擊教室卡片
   * @param {object} session 
   */
  const clickSessionCard = async (session) => {
    setSelectedSession(session);
    setIsOpenEnterClassroomModal(true);
  };

  /**
   * 取得可進入之自習教室
   */
  const getSelfStudySessions = () => {
    const sessions = openCoursesOri.reduce((prev, curr) => {
      const { classification, grades } = curr;
      const isIncludesClassGrade = grades.some(grade => classGrade.includes(grade));
      const isSelfStudy = classification === selfStudySessionText;
      (isIncludesClassGrade && isSelfStudy) && prev.push(curr);
      return prev;
    }, []);
    setSelfStudySessions(sessions);
    setIsLoadingSelfStudySessions(false);
  };

  /**
   * 取得可進入之解題教室
   */
  const getProblemSolvingSessions = () => {
    const sessions = openCoursesOri.reduce((prev, curr) => {
      const { classification, grades } = curr;
      const isIncludesClassGrade = grades.some(grade => classGrade.includes(grade));
      const isProblemSolving = classification === problemSolvingSessionText;
      (isIncludesClassGrade && isProblemSolving) && prev.push(curr);
      return prev;
    }, []);
    setProblemSolvingSessions(sessions);
    setIsLoadingProblemSolvingSessions(false);
  };

  /**
   * 打 API，取得公開課資料
   */
  const fetchOpenCourse = async () => {
    setIsLoadingSelfStudySessions(true);
    setIsLoadingProblemSolvingSessions(true);
    const { id, nickname } = profile;
    const startAt = serverTimestamp;
    const params = { startAt, userId: id, userName: nickname };

    try {
      const response = await getPublishedApi(params);
      const { isSuccess } = response;
      isSuccess
        ? setOpenCoursesOri(response.data)
        : setAlert('取得公開課資料失敗', 'error');
    } catch (error) {
      error.toString().includes('TypeError: Failed to fetch')
        ? setAlert(error, 'error', 502)
        : setAlert(error, 'error');
    }
  };

  useEffect(() => {
    if(!profile || !serverTimestamp) return;
    fetchOpenCourse();
  }, [profile, serverTimestamp]);

  useEffect(() => {
    if(!openCoursesOri || !classGrade || !serverTimestamp) return;
    getSelfStudySessions();
    getProblemSolvingSessions();
  }, [openCoursesOri, classGrade, serverTimestamp]);

  return (
    <UiHomeOpenCourse>
      {/* 行事曆彈窗 */}
      <CalendarModal
        onClose={()=>setIsOpenCalendar(false)}
        open={isOpenCalendar}
      />

      {/* 進入教室彈窗 */}
      <SessionModal
        isOpen={isOpenEnterClassroomModal}
        onClose={()=>setIsOpenEnterClassroomModal(false)}
        session={selectedSession}
      />

      {/* 頁面標題列 */}
      <UiPageTitleWrapper>
        <UiPageTitle>{pageTitle}</UiPageTitle>
        <Button
          buttonColor='standardSecondary'
          iconSvg='CalendarCheck'
          onClick={()=>setIsOpenCalendar(true)}
          textColor='#3A4052'
        >{btnTextCalendar}</Button>
        <UiPageManContainer>
          <UiPageMan />
        </UiPageManContainer>
      </UiPageTitleWrapper>

      {/* 自習教室列 */}
      <Classroom
        background={selfStudyBackground}
        isLoading={isLoadingSelfStudySessions}
        onClickCard={clickSessionCard}
        sessions={selfStudySessions}
        title={selfStudySessionText}
      />

      {/* 解題教室列 */}
      <Classroom
        isLoading={isLoadingProblemSolvingSessions}
        onClickCard={clickSessionCard}
        sessions={problemSolvingSessions}
        tag={tag}
        title={problemSolvingSessionText}
      />
    </UiHomeOpenCourse>
  );
};

